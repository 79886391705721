import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormCloseReason, FormIdentifier, RecordDetails, RecordState } from 'types/record';
import { captureUIEvent } from 'utils/eventCapture';
import { dispatch } from '../index';

const initialState: RecordState = {
  editingStack: []
};

const slice = createSlice({
  name: 'dealViewRecord',
  initialState,
  reducers: {
    // Start Editing
    startEditing(state: RecordState, action: PayloadAction<RecordDetails>) {
      // Check if the stack is not empty and if the type of the record on top of the stack is the same as the new record
      if (
        state.editingStack.length > 0 &&
        state.editingStack[state.editingStack.length - 1].formIdentifier === action.payload.formIdentifier
      ) {
        // If the type is the same, do not push the new record onto the stack
        return;
      }
      captureUIEvent('form_opened', {
        recordId: action.payload?.id,
        formName: action.payload?.formIdentifier
      });
      // If the stack is empty or the types are different, push the new record onto the stack
      // Thanks to Immer, you can push directly onto the array
      state.editingStack.push(action.payload);
    },
    // Stop Editing
    stopEditing(state: RecordState, action: PayloadAction<FormCloseReason>) {
      const closedRecord = state.editingStack.pop();
      captureUIEvent('form_closed', {
        recordId: closedRecord?.id,
        formName: closedRecord?.formIdentifier,
        reason: action.payload
      });
    },
    // Clear editing stack
    clearEditingStack(state: RecordState) {
      state.editingStack = [];
    },
    modifyRecordDetails(state: RecordState, action: PayloadAction<RecordDetails>) {
      if (state.editingStack.length > 0) {
        // Replace the top of the stack
        state.editingStack[state.editingStack.length - 1] = action.payload;
      } else {
        // Add to the stack if it's empty
        state.editingStack.push(action.payload);
      }
    }
  }
});

// Reducer
export default slice.reducer;

export function startEditingRecord(
  id: number | null,
  formIdentifier: FormIdentifier,
  incomingChanges: object = {},
  isQuickCreate: boolean = false,
  callbackFunction: (record?: object) => void = () => {}
) {
  dispatch(slice.actions.startEditing({ id, formIdentifier, incomingChanges, isQuickCreate, callbackFunction }));
}

export function stopEditingRecord(closeReason: FormCloseReason) {
  dispatch(slice.actions.stopEditing(closeReason));
}

export function modifyEditingRecordDetails(
  id: number | null,
  formIdentifier: FormIdentifier,
  incomingChanges: object = {},
  isQuickCreate: boolean = false,
  callbackFunction: (record?: object) => void = () => {}
) {
  dispatch(slice.actions.modifyRecordDetails({ id, formIdentifier, incomingChanges, isQuickCreate, callbackFunction }));
}

export function clearEditingRecordStack() {
  dispatch(slice.actions.clearEditingStack());
}
