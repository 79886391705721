import _ from 'lodash';
import { isFilterEmpty } from 'pages/deal/utils/dealFilters';
import { SortOrderEnum } from 'types/api/deal/filters/common';
import { filterEmpty } from 'types/navigation/common';

export function convertReactTableSortByToFilter(sortBy: any) {
  const sortIds = _.size(sortBy) > 0 ? _.map(sortBy, 'id') : filterEmpty;
  const sortOrders = _.size(sortBy) > 0 ? _.map(sortBy, (x) => (x.desc ? SortOrderEnum.desc : SortOrderEnum.asc)) : filterEmpty;
  return { sortIds, sortOrders };
}

export function convertFilterSortByToReactTable(
  sort: string[],
  sort_order: SortOrderEnum[] | typeof filterEmpty
): {
  id: string;
  desc: boolean;
}[] {
  const sortBy = !isFilterEmpty(sort) ? _.map(sort, (x, idx) => ({ id: x, desc: sort_order[idx] === SortOrderEnum.desc })) : [];

  return sortBy;
}
