import { RecordEditModal } from 'modals/RecordEditModal';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { FormIdentifier, RecordDetails } from 'types/record';

export const DealViewFormPage = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const incomingChanges = location.state as object;

  const recordDetails: RecordDetails = {
    id: params.id ?? null,
    formIdentifier: FormIdentifier.DealViewProForm,
    incomingChanges: incomingChanges,
    isQuickCreate: false,
    callbackFunction: () => {}
  };

  return (
    <RecordEditModal
      key={'test'}
      record={recordDetails}
      handleClose={() => {
        navigate(-1);
      }}
      isDialog={false}
    />
  );
};
