import { BaseFilter } from 'types/api/deal/filters/common';
import { BaseNavigation } from 'types/navigation/common';

export enum DateRangeFilterType {
  close_date = 'close_date',
  commission_due_date = 'commission_due_date',
  lease_expiration = 'lease_expiration',
  hire_date = 'hire_date'
}

export const DateRangeFilterTypeDisplay = {
  close_date: 'Close Date',
  commission_due_date: 'Commission Payment Date',
  lease_expiration: 'Lease Expiration',
  hire_date: 'Hire Date'
};

export interface DealFilter extends BaseFilter {
  // Corresponds to the deal_type field, filtering with Deal.deal_type IN (...) logic
  deal_type: string[];

  // Corresponds to the status field, filtering with Deal.status IN (...) logic
  status: string[];

  // Corresponds to the contact_id field, filtering with Deal.contact_id IN (...) logic
  contact_id: Array<number | string>;

  // Corresponds to the asset_type_id field, filtering with Deal.asset_type_id IN (...) logic
  asset_type_id: Array<number | string>;

  // Corresponds to the Commission.user_id field, filtering with Commission.user_id IN (...) logic
  broker: Array<number | string>;

  // Corresponds to the Commission.broker_role field, filtering with Commission.broker_role IN (...) logic
  broker_role: Array<string | null>;

  // Corresponds to the tenant_name_id field, filtering with Deal.tenant_name_id IN (...) logic
  tenant_name_id: Array<number | string>;

  // Corresponds to the property_name_id field, filtering with Deal.property_name_id IN (...) logic
  property_name_id: Array<number | string>;

  // Corresponds to the stage_id field, filtering with Deal.stage_id IN (...) logic
  stage_id: Array<number | string>;

  // Corresponds to the lost_reason_id field, filtering with Deal.lost_reason_id IN (...) logic
  lost_reason_id?: Array<number | null>;

  // Corresponds to the source_type_id field, filtering with Deal.source_type_id IN (...) logic
  source_type_id: Array<number | string>;

  // Corresponds to the client_industry_id field, filtering with Deal.client_industry_id IN (...) logic
  client_industry_id: Array<number | string>;

  // Corresponds to the submarket_id field, filtering with Deal.submarket_id IN (...) logic
  submarket_id: Array<number | string>;

  /**
   * Specialty filters
   */
  // A multipurpose date range that is selected by the user
  date_range: string[] | null;

  // When true, filter to deals which have outstanding commission (commission_due_date_amount > 0 and commission_due_date_paid = false)
  has_outstanding_commission: string[];

  // When true, filter to deals where stage_id is not null
  has_stage_id?: boolean;

  // When true, commission and transaction value are returned as weighted values if the Org has them enabled
  weighted?: boolean;

  // A filter for which hire_to_close_category the deal belongs to
  hire_to_close_category?: string | null;

  // Corresponds to shared_deal boolean field
  shared_deal: string[];

  // The field that the selected date range applies to. This ensures the user only has to manipulate one date range, and the meaning of which field the range applies to is determined by additional logic, both explicit and implicit
  date_range_type: DateRangeFilterType;
}

export interface DealNavigation extends BaseNavigation {
  // Additional navigation state that is specific to Deal View navigation
  year: number | null;
}
