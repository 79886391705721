// third-party
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { emptyContactQuickAddRow } from 'pages/onboarding/steps/ContactImportStep';
import { emptyDealQuickAddRow } from 'pages/onboarding/steps/DealImportStep';
import { DealType } from 'types/deal';
import { ContactQuickAddRecord, DealQuickAddRecord, OnboardingData } from 'types/onboarding';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState: OnboardingData = {
  dealTypes: [
    DealType.acquisition,
    DealType.disposition,
    DealType.landlord_agency,
    DealType.tenant_representation,
    DealType.referral,
    DealType.consulting
  ],
  dealQuickAddData: [emptyDealQuickAddRow, emptyDealQuickAddRow],
  contactQuickAddData: [emptyContactQuickAddRow, emptyContactQuickAddRow, emptyContactQuickAddRow],
  stepNumber: 0,
  isComplete: false
};

const slice = createSlice({
  name: 'onboardingData',
  initialState,
  reducers: {
    setDealTypesData(state, action: PayloadAction<DealType[]>) {
      state.dealTypes = action.payload;
    },
    setDealQuickAddData(state, action: PayloadAction<DealQuickAddRecord[]>) {
      state.dealQuickAddData = action.payload;
    },
    setContactQuickAddData(state, action: PayloadAction<ContactQuickAddRecord[]>) {
      state.contactQuickAddData = action.payload;
    },
    setStepNumber(state, action: PayloadAction<number>) {
      state.stepNumber = action.payload;
    },
    setIsComplete(state) {
      state.dealTypes = initialState.dealTypes;
      state.dealQuickAddData = initialState.dealQuickAddData;
      state.contactQuickAddData = initialState.contactQuickAddData;
      state.isComplete = true;
    }
  }
});

// Reducer
export default slice.reducer;

export const setDealTypesData = (dealTypes: DealType[]) => {
  dispatch(slice.actions.setDealTypesData(dealTypes));
};

export const setDealQuickAddData = (dealQuickAddData: DealQuickAddRecord[]) => {
  dispatch(slice.actions.setDealQuickAddData(dealQuickAddData));
};

export const setContactQuickAddData = (contactQuickAddData: ContactQuickAddRecord[]) => {
  dispatch(slice.actions.setContactQuickAddData(contactQuickAddData));
};

export const setOnboardingStepNumber = (stepNumber: number) => {
  dispatch(slice.actions.setStepNumber(stepNumber));
};

export const setOnboardingComplete = () => {
  dispatch(slice.actions.setIsComplete());
};
