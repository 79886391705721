import { Container, DialogContent, DialogTitle, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import * as Sentry from '@sentry/react';
import { ProspectDetailsSection } from 'components/form/forms/sections/ProspectDetailsSection';
import { StandardForm } from 'components/form/standard/StandardForm';
import MainCard from 'components/MainCard';
import { ActivityFollowupMetadataSubset } from 'constants/objectMetadata/activityFollowupMetadata';
import { ActivityMetadata } from 'constants/objectMetadata/activityMetadata';
import { ProspectMetadataSubset } from 'constants/objectMetadata/contactMetadata';
import _ from 'lodash';
import { CloseButton } from 'pages/deal/components/CloseButton';
import React from 'react';
import { ContactRead, ContactUpdate, ContactWithFollowup } from 'types/api/deal/contact';
import { ActivityTypeEnum } from 'types/api/deal/enum';
import { StandardFormProps } from 'types/record';
import { updateContactAsync } from 'utils/contact';
import DynamicVisibilitySection from '../standard/DynamicVisibilitySection';
import { ActivityFollowupFields } from './sections/ActivityFollowupFields';

const FormMetadata = { ...ActivityFollowupMetadataSubset, ...ProspectMetadataSubset, owner: ActivityMetadata.owner };

export const ConvertToProspectForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
  showAddAnother = true
}: StandardFormProps<ContactRead & ContactWithFollowup>) => {
  const updateFnAsync = async (id: number | string, contact: Partial<ContactWithFollowup>) => {
    // Move the individual followup fields into a sub-object
    const contactForPost: Partial<ContactUpdate> = {
      ...contact,
      followup: {
        owner: _.get(contact, 'owner'),
        activity_type_v2: _.get(contact, 'followup_activity_type_v2') as ActivityTypeEnum,
        date: _.get(contact, 'followup_date') as Date,
        subject: _.get(contact, 'followup_subject') as string,
        is_complete: false
      }
    };

    try {
      const record = await updateContactAsync(id, contactForPost);
      if (typeof successCallback === 'function' && _.isArray(record)) successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to update Contact.');
    }
  };

  return (
    <StandardForm<ContactWithFollowup>
      metadata={FormMetadata}
      record={record}
      updateFn={updateFnAsync}
      displayName={'Activity'}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      showAddAnother={showAddAnother}
      open={open}
      saveText={'Convert'}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <DialogTitle>Convert to Prospect</DialogTitle>
            </Grid>
            <Grid item sx={{ mr: 1.5 }}>
              <CloseButton onClose={onCancel} />
            </Grid>
          </Grid>
          <DialogContent>
            <Container>
              <Grid container spacing={3}>
                <Grid container item xs={12} spacing={3}>
                  <DynamicVisibilitySection visibilityFunction={(values) => _.get(values, 'is_followup', false)}>
                    <Grid item xs={12}>
                      <MainCard title={'Next Activity'}>
                        <Grid container columnSpacing={2} rowSpacing={1}>
                          <ActivityFollowupFields />
                        </Grid>
                      </MainCard>
                    </Grid>
                  </DynamicVisibilitySection>
                  <Grid item xs={12}>
                    <MainCard title={'Prospect Details'}>
                      <Grid container columnSpacing={2} rowSpacing={1}>
                        <ProspectDetailsSection />
                      </Grid>
                    </MainCard>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};
