import { dispatch } from 'store';
import { openErrorNotification } from 'store/reducers/common';
import { ContactCreate, ContactUpdate } from 'types/api/deal/contact';

// Action Creators
import { dealService } from 'utils/axios';
import { handleRecordActionResponseV4 } from 'utils/record';

export async function createContactAsync(contact: ContactCreate) {
  try {
    const response = await dealService.post('/contact/', contact);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification('Error creating contact.'));
    throw error;
  }
}

export async function createSimpleContactAsync(contact: ContactCreate) {
  try {
    const response = await dealService.post('/contact/simple/', contact);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification('Error creating contact.'));
    throw error;
  }
}

export async function updateContactAsync(id: number | string, contact: Partial<ContactUpdate>) {
  try {
    const response = await dealService.put(`/contact/${id}`, contact);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification('Error updating contact.'));
    throw error;
  }
}

export async function deleteContactAsync(id: number | string) {
  try {
    const response = await dealService.delete(`/contact/${id}`);
    handleRecordActionResponseV4(response);
  } catch (error) {
    dispatch(openErrorNotification('Error deleting contact.'));
    throw error;
  }
}
