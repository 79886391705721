import _ from 'lodash';
import { createBaseSlice } from 'store/reducers/common';
import { BaseState, Keyed } from 'types/common';
import { CommissionGoal } from 'types/dealCommissionGoal';
import { dealService, handleAxiosError } from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState: BaseState<Keyed<CommissionGoal>> = {
  loading: false,
  editing: false,
  saving: false,
  deleting: false,
  viewing: false,
  loadError: null,
  saveError: null,
  deleteError: null,
  data: {},
  current: null
};

const slice = createBaseSlice({
  name: 'dealCommissionGoal',
  initialState,
  reducers: {}
});

// Reducer
export default slice.reducer;

// Action Creators
export function getCommissionGoals() {
  dispatch(slice.actions.startLoad());
  dealService
    .get('/commission_goal/')
    .then(function (response) {
      if (_.isObject(response.data.data)) {
        dispatch(slice.actions.loadSuccess(response.data.data));
      }
    })
    .catch(function (error) {
      dispatch(slice.actions.loadFailure(handleAxiosError(error)));
    });
}
