import { Grid, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import _ from 'lodash';
import { getBaseQueryOptionsDealService } from 'pages/deal/utils/api';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CompanyRead } from 'types/company';

const CompanyAddressQuickView = () => {
  const [companyField] = useField('company');
  const companyId = useMemo(() => _.get(companyField.value, 'key'), [companyField]);

  const queryOptions = useMemo(() => getBaseQueryOptionsDealService<CompanyRead>(`/company/${companyId}`), [companyId]);
  const { data } = useQuery<CompanyRead>({ ...queryOptions, enabled: !!companyId });

  // Returns true if all address lines are empty
  const addressEmpty: boolean = useMemo(() => {
    // Pick the specific properties from data
    const pickedData = _.pick(data, ['line_1', 'line_2', 'city', 'state', 'postal_code']);
    // Check if every picked property is nil
    return _.every(pickedData, _.isNil);
  }, [data]);

  return (
    <Grid item xs={12}>
      <Stack spacing={0.5}>
        <Typography color="secondary">Address</Typography>
        <Typography>
          {!addressEmpty ? (
            <>
              {_.get(data, 'line_1')}
              {'\u00A0'}
              {_.get(data, 'line_2')}
              <br />
              {_.get(data, 'city')}
              {_.get(data, 'city') && _.get(data, 'state') ? ',' : ''} {_.get(data, 'state')}
              <br />
              {_.get(data, 'postal_code')}
            </>
          ) : (
            '--'
          )}
        </Typography>
      </Stack>
    </Grid>
  );
};
export default CompanyAddressQuickView;
