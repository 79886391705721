import { Grid, Stack, Typography } from '@mui/material';
import { formatStringForDisplay } from 'components/form/standard/utils/formatting';
import MainCard from 'components/MainCard';
import { ContactMetadata } from 'constants/objectMetadata/contactMetadata';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { getBaseQueryOptionsDealService } from 'pages/deal/utils/api';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DealViewRecord } from 'types/entity';
import { FieldComponentProps } from 'types/standardForm';

const ContactQuickView = (props: FieldComponentProps) => {
  const { getFieldProps } = useFormikContext();

  const { value } = getFieldProps('contact');

  const contactId = _.get(value, 'key');
  const recordUrl = `/contact/${contactId}`;

  const queryOptions = useMemo(() => getBaseQueryOptionsDealService<DealViewRecord>(recordUrl), [recordUrl]);
  const { data: contactRecord = null, isLoading } = useQuery<DealViewRecord>({
    ...queryOptions,
    enabled: !!contactId
  });

  return (
    <MainCard title={'Contact Details'} sx={{ minHeight: 288.63 }}>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Typography color="secondary">{ContactMetadata.telephone1.displayName}</Typography>
            <Typography sx={{ wordBreak: 'break-all' }}>{formatStringForDisplay(_.get(contactRecord, 'telephone1'))}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Typography color="secondary">{ContactMetadata.telephone2.displayName}</Typography>
            <Typography sx={{ wordBreak: 'break-all' }}>{formatStringForDisplay(_.get(contactRecord, 'telephone2'))}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Typography color="secondary">{ContactMetadata.email_address.displayName}</Typography>
            <Typography sx={{ wordBreak: 'break-all' }}>{formatStringForDisplay(_.get(contactRecord, 'email_address'))}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ContactQuickView;
