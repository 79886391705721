import { AxiosInstance } from 'axios';
import _ from 'lodash';
import { UseQueryOptions } from 'react-query';
import { axiosUserServices, dealService } from 'utils/axios';

// Get a dictionary of default Query Options for react-query calls
function getBaseQueryOptions<T>(): Partial<UseQueryOptions<T>> {
  return {
    retry: 1,
    staleTime: 15 * 1000 // 15 seconds
  };
}

export function getBaseQueryOptionsDealService<T>(url: string, queryParams?: URLSearchParams): UseQueryOptions<T> {
  return getBaseQueryOptionsService<T>(dealService, url, queryParams);
}

export function getBaseQueryOptionsUserService<T>(url: string, queryParams?: URLSearchParams): UseQueryOptions<T> {
  return getBaseQueryOptionsService<T>(axiosUserServices, url, queryParams);
}

// Get a dictionary that includes additional Query Options
// Includes logic to interact with deal service endpoints and check the response
export function getBaseQueryOptionsService<T>(service: AxiosInstance, url: string, queryParams?: URLSearchParams): UseQueryOptions<T> {
  return {
    ...getBaseQueryOptions<T>(),
    queryKey: _.isNil(queryParams) ? [url] : [url, queryParams.toString()],
    queryFn: () =>
      service.get(url, { params: queryParams }).then(function (response) {
        if (_.isObject(response.data.data) || _.isNumber(response.data.data) || _.isArray(response.data.data)) {
          return response.data.data;
        }
      })
  };
}

export function getBaseQueryOptionsDealServiceV2<T>(url: string, queryParams?: URLSearchParams): UseQueryOptions<T> {
  return getBaseQueryOptionsServiceV2<T>(dealService, url, queryParams);
}

export function getBaseQueryOptionsUserServiceV2<T>(url: string, queryParams?: URLSearchParams): UseQueryOptions<T> {
  return getBaseQueryOptionsServiceV2<T>(axiosUserServices, url, queryParams);
}

// Get a dictionary that includes additional Query Options
// Includes logic to interact with deal service endpoints and check the response
export function getBaseQueryOptionsServiceV2<T>(service: AxiosInstance, url: string, queryParams?: URLSearchParams): UseQueryOptions<T> {
  return {
    ...getBaseQueryOptions<T>(),
    queryKey: _.isNil(queryParams) ? [url] : [url, queryParams.toString()],
    // @ts-ignore
    queryFn: () =>
      service.get(url, { params: queryParams }).then(function (response) {
        if (_.isObject(response.data) || _.isNumber(response.data) || _.isArray(response.data)) {
          return response.data;
        }
      })
  };
}
