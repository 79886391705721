// third-party
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { emptyTeamMemberRow } from 'pages/auth/sections/auth-forms/register-wizard/TeamMembersForm';
import { dispatch } from 'store/index';
import {
  RegisterAccountData,
  RegistrationData,
  SSODetailsData,
  SubscriptionData,
  SubscriptionInterval,
  TeamMemberInviteData,
  UserDetailsData
} from 'types/authRegister';

// project imports

// ----------------------------------------------------------------------

export const initialState: RegistrationData = {
  userDetailsData: {
    first_name: '',
    last_name: '',
    username: '',
    company: ''
  },
  ssoDetailsData: {
    first_name: '',
    last_name: '',
    username: '',
    code: '',
    client_info: '',
    state: '',
    session_state: ''
  },
  password: '',
  teamMembersData: [emptyTeamMemberRow, emptyTeamMemberRow, emptyTeamMemberRow],
  subscriptionData: {
    isYearly: true, // Deprecated
    interval: SubscriptionInterval.two_years
  },
  sso: false,
  product: null,
  trial: false,
  accountData: {
    tokenHeader: null,
    orgSize: null
  },
  version: 'v1'
};

const slice = createSlice({
  name: 'registrationData',
  initialState,
  reducers: {
    // Set registration data
    setRegistrationData(state, action: PayloadAction<RegistrationData>) {
      return action.payload;
    },
    // Set user details data
    setUserDetailsData(state, action: PayloadAction<UserDetailsData>) {
      state.userDetailsData = action.payload;
    },
    // Set sso details data
    setSSODetailsData(state, action: PayloadAction<SSODetailsData>) {
      state.ssoDetailsData = action.payload;
    },
    // Set Password
    setUserDetailsPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    // Set team members data
    setTeamMembersData(state, action: PayloadAction<TeamMemberInviteData[]>) {
      state.teamMembersData = action.payload;
    },
    // Set subscription data
    setSubscriptionData(state, action: PayloadAction<SubscriptionData>) {
      state.subscriptionData = action.payload;
    },
    // Set SSO flag
    setSSOFlag(state, action: PayloadAction<boolean>) {
      state.sso = action.payload;
    },
    // Set product name
    setProduct(state, action: PayloadAction<string>) {
      state.product = action.payload;
    },
    // Set trial value
    setTrial(state, action: PayloadAction<boolean>) {
      state.trial = action.payload;
    },
    // Set version value
    setVersion(state, action: PayloadAction<'v1' | 'v2'>) {
      state.version = action.payload;
    },
    setAccountData(state, action: PayloadAction<RegisterAccountData>) {
      state.accountData = action.payload;
    },
    // Set registration data
    clearRegistrationData(state) {
      state = initialState;
    }
  }
});

// Reducer
export default slice.reducer;

// Set registration data
export const setRegistrationData = (registrationData: RegistrationData) => {
  dispatch(slice.actions.setRegistrationData(registrationData));
};

// Set user details data
export const setUserDetailsData = (userDetailsData: UserDetailsData) => {
  // Ensure username is lowercase
  dispatch(slice.actions.setUserDetailsData({ ...userDetailsData, username: userDetailsData.username.toLowerCase().trim() }));
};

// Set SSO details data
export const setSSODetailsData = (ssoDetailsData: SSODetailsData) => {
  // Ensure username is lowercase
  dispatch(slice.actions.setSSODetailsData({ ...ssoDetailsData, username: ssoDetailsData.username.toLowerCase().trim() }));
};

// Set password
export const setUserDetailsPassword = (password: string) => {
  dispatch(slice.actions.setUserDetailsPassword(password));
};

// Set team members data
export const setTeamMembersData = (teamMemberInviteData: TeamMemberInviteData[]) => {
  // Ensure usernames are lowercase
  dispatch(slice.actions.setTeamMembersData(_.map(teamMemberInviteData, (x) => ({ ...x, username: x.username.toLowerCase().trim() }))));
};

// Set subscription data
export const setSubscriptionData = (subscriptionData: SubscriptionData) => {
  dispatch(slice.actions.setSubscriptionData(subscriptionData));
};

// Set SSO flag
export const setSSOFlag = (sso: boolean) => {
  dispatch(slice.actions.setSSOFlag(sso));
};

// Set registration product
export const setRegistrationProduct = (product: string) => {
  dispatch(slice.actions.setProduct(product));
};

// Set registration trial
export const setRegistrationTrial = (trial: boolean) => {
  dispatch(slice.actions.setTrial(trial));
};

// Set registration version
export const setRegistrationVersion = (version: 'v1' | 'v2') => {
  dispatch(slice.actions.setVersion(version));
};

// Set registration account data
export const setRegisterAccountData = (accountData: RegisterAccountData) => {
  dispatch(slice.actions.setAccountData(accountData));
};

export const clearRegistrationData = () => {
  dispatch(slice.actions.clearRegistrationData());
};
