import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { getActivityPreviewFormat } from 'pages/prospects/utils/activity';
import { useUsersDisplay } from 'contexts/UserDisplayContext';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ActivityRead } from 'types/api/deal/activity';

export function ActivityTimelinePrintView(props: { record: ActivityRead }) {
  const { record } = props;
  const usersDisplay = useUsersDisplay();

  const { datetime, ownerName, avatarContents, title, activityBody } = getActivityPreviewFormat(record, usersDisplay);

  return (
    <Grid container spacing={0} mt={1}>
      <Grid item xs={6}>
        <Typography fontSize={12} color={'textSecondary'}>
          {_.size(ownerName) > 0 ? ownerName : 'No Owner'}
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign={'right'}>
        <Typography fontSize={12} color={'textSecondary'}>
          {dayjs(datetime).format('M/D/YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1} direction="row" alignItems={'center'}>
          {avatarContents}
          <Typography fontWeight={500}>{title}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize={12}>{activityBody}</Typography>
      </Grid>
    </Grid>
  );
}
