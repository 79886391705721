import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { FieldComponentProps } from 'types/standardForm';
import { CreoneField } from './basic/creone_field';

interface TextFieldStringProps extends FieldComponentProps {
  multiline?: boolean;
  showLabel?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  disabled?: boolean;
  isPhoneNumber?: boolean;
}

export const PHONE_NUMBER_REGEX = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?[\d]{3}?\)?[\s.-]?[\d]{3}[\s.-]?[\d]{4}$/;

export function TextFieldString(props: TextFieldStringProps) {
  const variant = props.variant === undefined ? 'outlined' : props.variant;
  const { getFieldProps } = useFormikContext();

  return (
    <CreoneField {...props}>
      <TextField
        size={'medium'}
        fullWidth
        multiline={props.multiline}
        minRows={3}
        id={props.fieldName}
        placeholder={props.displayName}
        variant={variant}
        disabled={props.disabled}
        {...getFieldProps(props.fieldName)}
      />
    </CreoneField>
  );
}

export const TextFieldStringMultiline = (props: FieldComponentProps) => {
  return <TextFieldString {...props} multiline={true} />;
};
