export const registrationSteps = [
  {
    title: 'Your account',
    subtitle: 'Please provide your name and email'
  },
  {
    title: 'Add your team',
    subtitle: 'Start collaborating with your team'
  },
  {
    title: 'Subscribe',
    subtitle: 'Choose a subscription plan'
  }
];
