import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { AxiosInstance } from 'axios';
import { CreoneField } from 'components/form/basic/creone_field';
import { FormikValues, useField, useFormikContext } from 'formik';
import _ from 'lodash';
import { getBaseQueryOptionsService } from 'pages/deal/utils/api';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { SimpleOption } from 'types/api/deal/form';
import { FieldComponentProps } from 'types/standardForm';
import { dealService } from 'utils/axios';

export const PlaintextAutocomplete = (
  props: FieldComponentProps & {
    dataOptions: string[];
    dataIsLoading: any;
    showLabel?: boolean;
  }
) => {
  const [field, , helpers] = useField(props.fieldName);
  const { setFieldValue, values, setFieldTouched } = useFormikContext<FormikValues>();

  // Debounce field value setter
  const debouncedSetFieldValue = useMemo(
    () =>
      _.debounce(
        (value: string) => {
          // Execute additional change handler if exists
          if (typeof props.additionalChangeHandler === 'function') {
            props.additionalChangeHandler(value, setFieldValue, null, values);
          }
          helpers.setValue(value);
        },
        400,
        { leading: false, trailing: true }
      ),
    [helpers]
  );

  return (
    <CreoneField {...props}>
      <Autocomplete
        id={props.fieldName}
        freeSolo
        autoHighlight
        options={props.dataOptions}
        renderInput={(params) => (
          <TextField {...params} size={'medium'} fullWidth placeholder={props.displayName} disabled={props.disabled} />
        )}
        onKeyDown={(event) => {
          if (event.key === 'Tab') {
            event.key = 'Enter'; // will call `onChange`
          }
        }}
        onChange={() => {}} // Ignore onChange
        onInputChange={(_, inputValue) => {
          debouncedSetFieldValue(inputValue);
        }}
        disableClearable
        value={field.value}
        onBlur={() => {
          setFieldTouched(props.fieldName, true);
        }}
      />
    </CreoneField>
  );
};

const AsyncPlaintextAutocomplete = (
  props: FieldComponentProps & {
    showLabel?: boolean;
    service: AxiosInstance;
    loadUrl: string;
  }
) => {
  // Locate the option field name, even if nested
  const queryOptions = useMemo(() => getBaseQueryOptionsService<Array<SimpleOption>>(props.service, props.loadUrl), []);

  const { data = [], isLoading } = useQuery<Array<SimpleOption>>(queryOptions);

  const stringOptions = useMemo(() => _.map(data, 'label'), [data]);

  return <PlaintextAutocomplete {...props} dataOptions={stringOptions} dataIsLoading={isLoading} />;
};

export const DealOptionAutocompleteNew = (props: FieldComponentProps & { showLabel?: boolean }) => {
  // Locate the option field name, even if nested
  const optionFieldName = useMemo(
    // TODO: Refactor to remove need for followup_ replacement
    () => _.replace(_.last(_.split(props.fieldName, '.')) ?? props.fieldName, 'followup_', ''),
    [props.fieldName]
  );

  return <AsyncPlaintextAutocomplete {...props} service={dealService} loadUrl={`/form/lookup/option/${optionFieldName}`} />;
};
