import { Grid, Link, Stack, Typography } from '@mui/material';
import { ActivityFollowupFields } from 'components/form/forms/sections/ActivityFollowupFields';
import { ActivityTimeline } from 'components/form/forms/sections/ActivityTimeline/ActivityTimeline';
import { EditableActivityTimelineEntry } from 'components/form/forms/sections/ActivityTimeline/EditableActivityTimelineEntry';
import { OutlookTimelineEntry } from 'components/form/forms/sections/ActivityTimeline/OutlookTimelineEntry';
import { PendingActivityTimelineEntry } from 'components/form/forms/sections/ActivityTimeline/PendingActivityTimelineEntry';
import { TimelineEntry } from 'components/form/forms/sections/ActivityTimeline/TimelineEntry';
import MainCard from 'components/MainCard';
import { useCounter } from 'contexts/CounterContext';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { getBaseQueryOptionsUserService } from 'pages/deal/utils/api';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Activity, ActivityRead } from 'types/api/deal/activity';
import { ContactRead } from 'types/api/deal/contact';
import { OutlookEmailObject } from 'types/integration/microsoft/outlook';
import { v4 as uuidv4 } from 'uuid';

interface TimelineEntryDetails {
  id: number | string | null;
  datetime: Date;
  component: ReactNode;
}

const convertActivityIntoTimelineRecord = (
  activity: ActivityRead | Partial<Activity>,
  defaultExpanded: boolean = false
): TimelineEntryDetails => {
  return {
    id: activity?.id ?? null,
    datetime: dayjs(activity.date).toDate(),
    component: <EditableActivityTimelineEntry record={activity} key={`activity-${activity?.id}`} defaultExpanded={defaultExpanded} />
  };
};
const convertOutlookEmailIntoTimelineRecord = (x: OutlookEmailObject): TimelineEntryDetails => {
  return {
    id: x.id,
    datetime: dayjs(_.get(x, 'sentDateTime', '')).toDate(),
    component: <OutlookTimelineEntry emailObject={x} key={`outlook-email-${x.id}`} />
  };
};

function ContactFollowupSection() {
  return (
    <TimelineEntry
      datetime={null}
      color={'primary'}
      avatarContents={null}
      title={null}
      activityBody={
        <MainCard>
          <Grid container columnSpacing={2} rowSpacing={1}>
            <ActivityFollowupFields />
          </Grid>
        </MainCard>
      }
      activityBodyPreview={''}
      expanded={true}
      setExpanded={() => {}}
    />
  );
}

export const ActivityTimelineSection = ({
  children,
  contact_record,
  upcoming,
  completed,
  activityTimelineRefetch
}: {
  children: ReactNode;
  contact_record: ContactRead | null;
  upcoming: ActivityRead[];
  completed: ActivityRead[];
  activityTimelineRefetch: () => void;
}) => {
  const [pendingUpcomingActivities, setPendingUpcomingActivities] = useState<Array<Record<string, any>>>([]);
  const [pendingCompletedActivities, setPendingCompletedActivities] = useState<Array<Record<string, any>>>([]);

  const { values: contactValues } = useFormikContext();
  const { increment: incrementOpenActivityCount } = useCounter();

  // Prepare functions in component scope
  const convertToUpcomingTimelineRecord = (x: ActivityRead | Partial<Activity>) =>
    convertActivityIntoTimelineRecord(x, _.get(contactValues, '_view_activity') === x.id);
  const convertToCompletedTimelineRecord = (x: ActivityRead | Partial<Activity>) =>
    convertActivityIntoTimelineRecord(x, _.get(contactValues, '_view_activity') === x.id);

  const upcomingActivities = useMemo(() => _.map(upcoming, (x) => convertToUpcomingTimelineRecord(x)), [upcoming]);
  const completedActivities = useMemo(() => _.map(completed, (x) => convertToCompletedTimelineRecord(x)), [completed]);

  const addNewActivity = (is_complete: boolean) => {
    const newActivityIncomingChanges = {
      id: uuidv4(),
      contact_id: contact_record?.id,
      contact: { key: contact_record?.id, label: contact_record?.full_name },
      is_complete,
      date: is_complete ? new Date() : null,
      _pending: true
    };

    incrementOpenActivityCount();

    if (is_complete) {
      setPendingCompletedActivities((x) => [...x, newActivityIncomingChanges]);
    } else {
      setPendingUpcomingActivities((x) => [...x, newActivityIncomingChanges]);
    }
  };

  const readEmailsQueryOptions = useMemo(
    () => getBaseQueryOptionsUserService<OutlookEmailObject[]>(`/integration/microsoft/read-emails/${contact_record?.email_address}`),
    [contact_record?.email_address]
  );
  const { data: outlookEmails = [] } = useQuery<OutlookEmailObject[]>({
    ...readEmailsQueryOptions,
    enabled: !!contact_record?.id && !!contact_record?.email_address,
    retry: 0
  });

  const outlookEmailTimelineRecords = useMemo(() => _.map(outlookEmails, convertOutlookEmailIntoTimelineRecord), [outlookEmails]);

  const combinedCompletedRecords = useMemo(
    () => _.orderBy(_.concat(completedActivities, outlookEmailTimelineRecords), 'datetime', 'desc'),
    [completedActivities, outlookEmailTimelineRecords]
  );

  const isUpcomingSectionEmpty =
    !_.get(contactValues, 'is_followup') && _.isEmpty(pendingUpcomingActivities) && _.isEmpty(upcomingActivities);
  const isCompletedSectionEmpty = _.isEmpty(pendingCompletedActivities) && _.isEmpty(combinedCompletedRecords);

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <MainCard
        title={'Upcoming Activities'}
        sx={
          !isUpcomingSectionEmpty
            ? {
                backgroundColor: '#CFE0D1',
                '.MuiCardHeader-root': {
                  backgroundColor: '#B3CBB6'
                }
              }
            : {}
        }
        secondary={
          <>
            {!_.isEmpty(upcomingActivities) ? (
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  addNewActivity(false);
                }}
              >
                <Typography fontWeight={700}>+ Add</Typography>
              </Link>
            ) : (
              <></>
            )}
          </>
        }
      >
        <ActivityTimeline addNewActivity={() => addNewActivity(false)}>
          {!isUpcomingSectionEmpty && (
            <Fragment>
              {_.get(contactValues, 'is_followup') && (
                <Grid item xs={12} key={'contact-followup-section'}>
                  <ContactFollowupSection />
                </Grid>
              )}
              {_.map(pendingUpcomingActivities, (activity) => (
                <Grid item xs={12} key={`grid-item-pending-activity-${activity?.id}`}>
                  <PendingActivityTimelineEntry
                    record={activity}
                    setPendingActivities={setPendingUpcomingActivities}
                    timelineRefetch={activityTimelineRefetch}
                  />
                </Grid>
              ))}
              {_.map(upcomingActivities, (timelineRecord) => (
                <Grid item xs={12} key={`grid-item-activity-${timelineRecord?.id}`}>
                  {timelineRecord.component}
                </Grid>
              ))}
            </Fragment>
          )}
        </ActivityTimeline>
      </MainCard>
      {children}
      {!!contact_record?.id && (
        <MainCard
          title={'Completed Activities'}
          secondary={
            <>
              {!_.isEmpty(combinedCompletedRecords) ? (
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    addNewActivity(true);
                  }}
                >
                  + Add
                </Link>
              ) : (
                <></>
              )}
            </>
          }
        >
          <ActivityTimeline addNewActivity={() => addNewActivity(true)}>
            {!isCompletedSectionEmpty && (
              <Fragment>
                {_.map(pendingCompletedActivities, (activity) => (
                  <Grid item xs={12} key={`grid-item-pending-activity-${activity?.id}`}>
                    <PendingActivityTimelineEntry
                      record={activity}
                      setPendingActivities={setPendingCompletedActivities}
                      timelineRefetch={activityTimelineRefetch}
                    />
                  </Grid>
                ))}
                {_.map(combinedCompletedRecords, (timelineRecord) => (
                  <Grid item xs={12} key={`grid-item-activity-${timelineRecord.id}`}>
                    {timelineRecord.component}
                  </Grid>
                ))}
              </Fragment>
            )}
          </ActivityTimeline>
        </MainCard>
      )}
    </Stack>
  );
};
