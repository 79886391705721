// assets
import { LogoutOutlined, ProfileOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PermissionSet } from 'types/permission';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  handleClose: () => void;
}

const SettingsTab = ({ handleLogout, handleClose }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();

  const { checkPermission } = useAuth();
  // Check user permissions
  const isOrgSettingsEditor = useMemo(() => checkPermission(PermissionSet.organization_settings_editor), []);

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedIndex(index);
    let selectedTab;
    switch (index) {
      case 2:
        selectedTab = '/settings/team/users';
        break;
      case 3:
        selectedTab = '/settings/account/billing';
        break;
      case 1:
      default:
        selectedTab = '/settings/user/profile';
        break;
    }
    handleClose();
    navigate(selectedTab, { replace: true });
  };

  return (
    <List sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {/* @ts-ignore */}
      <ListItemButton selected={selectedIndex === 1} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="User Settings" />
      </ListItemButton>
      {/* @ts-ignore */}
      {isOrgSettingsEditor && (
        <ListItemButton selected={selectedIndex === 2} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 2)}>
          <ListItemIcon>
            <ProfileOutlined />
          </ListItemIcon>
          <ListItemText primary="Organization Settings" />
        </ListItemButton>
      )}
      {/* @ts-ignore */}
      {isOrgSettingsEditor && (
        <ListItemButton selected={selectedIndex === 3} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 3)}>
          <ListItemIcon>
            <WalletOutlined />
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItemButton>
      )}
      <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default SettingsTab;
