// types
import { PaletteThemeProps } from 'types/theme';
import { PalettesProps } from '@ant-design/colors';
import { PaletteColorOptions } from '@mui/material/styles';
import { ThemeMode } from 'types/config';

// ==============================|| PRESET THEME - DEFAULT ||============================== //

const Theme1 = (colors: PalettesProps, mode: ThemeMode): PaletteThemeProps => {
  const { grey } = colors;
  const greyColors: PaletteColorOptions = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  let primaryColors = ['#F2FBFA', '#CBF0E7', '#9CE5C9', '#6DD3A3', '#3ЕС27D', '#2A8B53', '#1C5F3D', '#135132', '#0D3D27', '#06261C'];
  // let secondaryColors = [
  //   '#DBE2E6',
  //   '#BEC5CB',
  //   '#A0A7B0',
  //   '#828A94',
  //   '#666E7A',
  //   '#4A5161',
  //   '#2D3343',
  //   '#222636',
  //   '#1C1F2A',
  //   '#15171F',
  //   '#0F1015',
  //   '#08080A',
  //   '#010101'
  // ];
  let errorColors = ['#FEBEB8', '#FF8A80', '#F44336', '#C62828', '#8E1C1C'];
  let warningColors = ['#FFF0B3', '#FFDD80', '#FFC107', '#C79100', '#8E6700'];
  let infoColors = ['#BCDFFB', '#79C0F8', '#2196F3', '#ЗА78AB', '#25557D'];
  let successColors = ['#4c5469', '#383f53', '#2d3343', '#1b202e', '#000011'];

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5],
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText
    },
    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: greyColors[500]!,
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      contrastText
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText: greyColors[100]
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      contrastText
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      contrastText
    },
    grey: greyColors
  };
};

export default Theme1;
