// project import
import useAuth from 'hooks/useAuth';
import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// types
import { GuardProps } from 'types/auth';
import { getDefaultRoute } from 'utils/getDefaultRoute';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      // Check if they should be routed to the Onboarding Flow
      if (_.get(user, 'is_primary_user', false) && !_.get(user, 'setup_flow_complete', true)) {
        navigate('/setup', { replace: true });
      } else {
        navigate(getDefaultRoute(user), { replace: true });
      }
    }
  }, [isLoggedIn, navigate, user]);

  return children;
};

export default GuestGuard;
