import { useDealOrgPreferences } from 'contexts/DealOrgPreferencesContext';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { FieldMetadata } from 'types/standardForm';

function useFieldVisibility(field: FieldMetadata): boolean {
  const { values }: any = useFormikContext();
  const { data: dealOrgPreferences } = useDealOrgPreferences();
  const { visibility = () => true } = field;

  const isVisible = useMemo(() => visibility(values, dealOrgPreferences), [values, visibility, dealOrgPreferences]);
  return isVisible;
}

export default useFieldVisibility;
