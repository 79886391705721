import { InfoCircleFilled } from '@ant-design/icons';
import { Alert, Box, Grid, Link, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CreoneField, FieldLabel } from 'components/form/basic/creone_field';
import { DatePicker } from 'components/form/DatePicker';
import { TextFieldCurrency } from 'components/form/TextFieldCurrency';
import { TextFieldPercent } from 'components/form/TextFieldPercent';
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import { FormikValues, useField, useFormikContext } from 'formik';
import _ from 'lodash';
import { formatCurrency } from 'pages/deal/utils/reporting';
import * as React from 'react';
import { useMemo } from 'react';
import { CommissionPaymentUpdate } from 'types/api/deal/commissionPayments';
import { FieldComponentProps } from 'types/standardForm';

function PaidToggle(props: FieldComponentProps) {
  const [field, , helpers] = useField(props.fieldName);

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
    if (value !== null) {
      helpers.setValue(value);
    }
  };

  return (
    <CreoneField {...props}>
      <ToggleButtonGroup color="primary" id={props.fieldName} {...field} exclusive onChange={handleAlignment} aria-label={props.fieldName}>
        <ToggleButton id={`${props.fieldName}-no`} value={false} aria-label="left aligned">
          No
        </ToggleButton>
        <ToggleButton id={`${props.fieldName}-yes`} value={true} aria-label="centered">
          Yes
        </ToggleButton>
      </ToggleButtonGroup>
    </CreoneField>
  );
}

interface SplitMessageProps {
  onClick: () => void;
  commissionVal: number;
  amountSum: number;
}

const SplitMessage: React.FC<SplitMessageProps> = ({ commissionVal, amountSum, onClick }: SplitMessageProps) => {
  const remainder = commissionVal - amountSum;
  const isUnallocated = remainder > 0;
  const action = isUnallocated ? 'allocate this amount to' : 'subtract this amount from';

  return (
    <Alert color="error" icon={<InfoCircleFilled />}>
      Actual Commission is {formatCurrency(commissionVal)}. The Amounts Due above add up to {formatCurrency(amountSum)}.{' '}
      {formatCurrency(isUnallocated ? remainder : -remainder)} is <b>{isUnallocated ? 'unallocated' : 'over-allocated'}</b>.{' '}
      <Link onClick={onClick} sx={{ cursor: 'pointer' }}>
        Click here{'\u00A0'}
      </Link>
      to {action} the last Amount Due.
    </Alert>
  );
};

export function CommissionPaymentSection(props: FieldComponentProps) {
  const [field, meta, helpers] = useField(props.fieldName);
  const [percentToggleField, , percentToggleHelpers] = useField(DealMetadata.commission_payments_percent_toggle.fieldName);
  const [commissionField, ,] = useField(DealMetadata.commission.fieldName);
  const { values, setFieldValue, validateField } = useFormikContext<FormikValues>();

  const { submitCount } = useFormikContext();

  const isPercent = useMemo(() => percentToggleField.value, [percentToggleField.value]);

  const amountSum = useMemo(() => _.sumBy(field.value, 'amount') ?? 0, [field.value]);
  const percentSum = useMemo(() => _.sumBy(field.value, 'percent') ?? 0, [field.value]);
  const commissionVal = useMemo(() => commissionField.value ?? 0, [commissionField.value]);

  const handleSplitRemainder = (targetSum: number, currentSum: number, fieldName: 'amount' | 'percent') => {
    const remainder = targetSum - currentSum;
    const addToIndex = _.findLastIndex(field.value, (x: CommissionPaymentUpdate) => !_.isNil(x.date));

    if (_.size(field.value)) {
      const fullFieldName = `commission_payments.${addToIndex}.${fieldName}`;
      const currentValue = _.get(values, fullFieldName);
      setFieldValue(fullFieldName, currentValue + remainder).then(() => {
        validateField('commission_payments');
      });
    }
  };

  const handlePercentToggleAlignment = async (event: React.MouseEvent<HTMLElement>, value: boolean) => {
    if (!_.isNil(value)) {
      // Cache previously set value
      const prevValue = percentToggleField.value;
      // Set new value
      await percentToggleHelpers.setValue(value);
      // Sync amount or percent if necessary
      if (!_.isNil(prevValue)) {
        if (prevValue) {
          // Previous value was Percent. Sync Amount from Percent.
          await _.forEach(field.value, async (x, idx) => {
            // Update amount
            if (!_.isNil(commissionField.value) && !_.isNil(x.percent)) {
              await setFieldValue(`commission_payments.${idx}.amount`, x.percent * commissionField.value);
            }
          });
        } else {
          // Previous value was Amount. Sync Percent from Amount.
          await _.forEach(field.value, async (x, idx) => {
            // Update percent
            if (!_.isNil(commissionField.value) && !_.isNil(x.amount) && commissionField.value !== 0) {
              await setFieldValue(`commission_payments.${idx}.percent`, x.amount / commissionField.value);
            }
          });
        }
      }
      // Re-validate the commission_payments field whenever the percent toggle changes
      await validateField('commission_payments');
    }
  };

  return (
    <Box>
      <Stack spacing={2} sx={{ my: 1 }}>
        <CreoneField fieldName={'commission_payments_percent_toggle'} displayName={'Commission Payments'} isVerticalLabel={true}>
          <ToggleButtonGroup
            color="primary"
            id={'commission_payments_percent_toggle'}
            {...percentToggleField}
            exclusive
            onChange={handlePercentToggleAlignment}
            aria-label={'commission_payments_percent_toggle'}
          >
            <ToggleButton id={`${'commission_payments_percent_toggle'}-dollar`} size="small" value={false} aria-label="left aligned">
              $
            </ToggleButton>
            <ToggleButton id={`${'commission_payments_percent_toggle'}-percent`} size="small" value={true} aria-label="centered">
              %
            </ToggleButton>
          </ToggleButtonGroup>
        </CreoneField>
      </Stack>
      <Grid container columnSpacing={2} rowSpacing={1} alignItems={'stretch'}>
        <Grid item xs={4}>
          <FieldLabel required={true} displayName={'Date Due'} fieldName={'Date Due'} />
        </Grid>
        <Grid item xs={5}>
          <FieldLabel required={true} displayName={'Amount Due'} fieldName={'Amount Due'} />
        </Grid>
        <Grid item xs={true} />
        <Grid item xs={2}>
          <FieldLabel required={false} displayName={'Paid?'} fieldName={'Paid?'} />
        </Grid>
        {field.value.length > 0 &&
          field.value.map((row: CommissionPaymentUpdate, index: number) => {
            return (
              <>
                <Grid item xs={4}>
                  <DatePicker showLabel={false} fieldName={`commission_payments.${index}.date`} />
                </Grid>
                <Grid item xs={5}>
                  {isPercent ? (
                    <TextFieldPercent fieldName={`commission_payments.${index}.percent`} showLabel={false} />
                  ) : (
                    <TextFieldCurrency fieldName={`commission_payments.${index}.amount`} showLabel={false} />
                  )}
                </Grid>
                <Grid item xs={true} />
                <Grid item xs={2}>
                  <PaidToggle fieldName={`commission_payments.${index}.paid`} showLabel={false} />
                </Grid>
              </>
            );
          })}
      </Grid>
      {!!meta.error && meta.touched && submitCount > 0 && typeof meta.error === 'string' && (
        <Grid container sx={{ pt: 2 }}>
          {meta.error === 'amount-total-100' ? (
            isPercent ? (
              <SplitMessage
                onClick={() => handleSplitRemainder(1, percentSum, 'percent')}
                amountSum={percentSum * commissionVal}
                commissionVal={commissionVal}
              />
            ) : (
              <SplitMessage
                onClick={() => handleSplitRemainder(commissionVal, amountSum, 'amount')}
                amountSum={amountSum}
                commissionVal={commissionVal}
              />
            )
          ) : (
            <Alert color="error" icon={<InfoCircleFilled />}>
              {meta.error}
            </Alert>
          )}
        </Grid>
      )}
    </Box>
  );
}
