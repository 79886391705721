import { useEffect, useRef } from 'react';

export const useEffectUpdateOnly = (callback: (x: any) => void, dependencies: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      callback(dependencies);
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
