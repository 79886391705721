import { ActivityFollowupOption, getDateForFollowupOption } from 'pages/prospects/components/form/SelectFollowupOption';
import { ActivityWithFollowup } from 'types/api/deal/activity';

export const followupIncomingChanges: Partial<ActivityWithFollowup> = {
  is_followup: true,
  followup_option: ActivityFollowupOption.oneWeek,
  followup_date: getDateForFollowupOption(ActivityFollowupOption.oneWeek)
};

export const followupCompletedActivityIncomingChanges: Partial<ActivityWithFollowup> = {
  ...followupIncomingChanges,
  is_complete: true,
  date: new Date(),
  completed_date: new Date()
};
