export type FeatureFlag = {
  // true value means that it is seen and available for all
  flag: boolean;
  // users that override the feature flag value. Users take precedence over orgs and flag values
  user?: {
    [key: string]: boolean;
  };
  // organizations that override the feature flag value. Orgs take precedence over flags
  organization?: {
    [key: string]: boolean;
  };
};

export enum FeatureFlagName {
  LICENSE_MANAGEMENT_FLAG = 'license_management_flag'
}

export type FeatureFlagsType = {
  [key in FeatureFlagName]: FeatureFlag;
};

export type FeatureFlagProps = {
  getFlag: (flagName: FeatureFlagName) => any;
};
