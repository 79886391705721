import { RecordEditModal } from 'modals/RecordEditModal';
import { Fragment } from 'react';
import { useSelector } from 'store';
import { stopEditingRecord } from 'store/reducers/record';

export const RecordEditModalStack = () => {
  const { editingStack } = useSelector((state) => state.record);

  if (editingStack.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {editingStack.map((record, index) => (
        <RecordEditModal key={index} record={record} handleClose={stopEditingRecord} />
      ))}
    </Fragment>
  );
};
