import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import React from 'react';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} placement={'top'} classes={{ popper: className }} enterDelay={300} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 350
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      boxShadow: theme.shadows[1]
    },
    '&::after': {
      boxShadow: theme.shadows[1]
    }
  }
}));

export const InfoTooltip = (props: { title: string }) => {
  const { title } = props;
  return (
    <LightTooltip title={title}>
      <IconButton size="small">
        <InfoOutlinedIcon fontSize="small" />
      </IconButton>
    </LightTooltip>
  );
};
