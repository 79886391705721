export const defaultCompanyTableColumns = ['name', 'address_composite'];

export const companyTableColumns = () => {
  return [
    {
      Header: 'Company Name',
      accessor: 'name'
    },
    {
      Header: 'Address',
      accessor: 'address_composite'
    }
  ];
};
