import React from 'react';
import ConfirmationDialog from './ConfirmationDialog';

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  displayName: string;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({ open, onClose, onConfirm, displayName }) => (
  <ConfirmationDialog
    open={open}
    onClose={onClose}
    onConfirm={onConfirm}
    title="Delete Record"
    description={`Are you sure you want to delete this ${displayName}?`}
    confirmButtonText="Delete"
    cancelButtonText="Cancel"
  />
);

export default DeleteConfirmationDialog;
