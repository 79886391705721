import { ReactNode } from 'react';

// material-ui
import { Button, InputLabel, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import { useTheme } from '@mui/material/styles';
import { parseDealStageDict } from 'pages/deal/utils/deal';
import { useQuery } from 'react-query';
import { dealService } from 'utils/axios';

// ==============================|| FORMS WIZARD - VALIDATION ||============================== //
const StageStepper = () => {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const deal_type = _.get(values, DealMetadata.deal_type.fieldName);
  const stage = _.get(values, DealMetadata.stage.fieldName);
  const { data } = useQuery({
    retry: 3,
    staleTime: Infinity,
    refetchOnMount: 'always',
    queryFn: () =>
      dealService.get('/stage/').then((res) => {
        return parseDealStageDict(res.data.data, deal_type);
      }),
    queryKey: ['stages', deal_type]
  });
  const CustomButton = ({
    children,
    stage,
    ...props
  }: {
    stage: {
      id: number | string | null;
      value: string;
      order: number | null;
    };
    children: any;
  }) => (
    <Button
      style={{ cursor: 'pointer' }}
      {...props}
      onClick={() => {
        setFieldValue('stage', stage);
      }}
    >
      {children}
    </Button>
  );

  return (
    <Stack sx={{ bgcolor: theme.palette.grey[100], p: 2 }} spacing={theme.spacing(1)}>
      <InputLabel required={false} sx={{ minWidth: '60px' }}>
        Select your deal stage:
      </InputLabel>
      <Stepper activeStep={_.findIndex(data, { id: stage?.id })}>
        {data?.map((_stage) => {
          const labelProps: { error?: boolean; optional?: ReactNode } = {};
          const { id, value } = _stage;
          return (
            <Step
              key={id}
              sx={{
                [`& .MuiStepIcon-root.${stepConnectorClasses.active}`]: {
                  color: theme.palette.primary.main
                },
                [`& .MuiStepIcon-root.${stepConnectorClasses.completed}`]: {
                  color: theme.palette.success.main
                },
                cursor: 'pointer'
              }}
            >
              <CustomButton stage={_stage}>
                <StepLabel {...labelProps}>{value}</StepLabel>
              </CustomButton>
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

export default StageStepper;
