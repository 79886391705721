import { ActivityForm } from 'components/form/forms/ActivityForm';
import { TimelineEntry } from 'components/form/forms/sections/ActivityTimeline/TimelineEntry';
import MainCard from 'components/MainCard';
import { useCounter } from 'contexts/CounterContext';
import _ from 'lodash';
import { getIconNodeFromActivityType } from 'pages/prospects/utils/activity';
import React from 'react';
import { ActivityType } from 'types/activity';

import { Activity, ActivityRead } from 'types/api/deal/activity';
interface PendingActivityTimelineEntryProps {
  record: ActivityRead | Partial<Activity>;
  setPendingActivities: React.Dispatch<React.SetStateAction<Partial<Activity>[]>>;
  timelineRefetch: () => void;
}

export const PendingActivityTimelineEntry = ({ record, setPendingActivities, timelineRefetch }: PendingActivityTimelineEntryProps) => {
  const { decrement: decrementOpenActivityCount } = useCounter();
  const avatarContents = getIconNodeFromActivityType(ActivityType.other);

  const handleClosePending = () => {
    setPendingActivities((x) => _.reject(x, { id: record?.id }));
    decrementOpenActivityCount();
  };
  const successCallbackPending = (updatedRecord: object = {}) => {
    // A record was updated - refetch just the timeline data
    timelineRefetch();
    // Remove the pending record from the list
    handleClosePending();
  };

  return (
    <TimelineEntry
      datetime={null}
      color={'primary'}
      avatarContents={avatarContents}
      title={null}
      activityBody={
        <MainCard>
          <ActivityForm
            record={null}
            onCancel={handleClosePending}
            open={true}
            successCallback={successCallbackPending}
            showAddAnother={false}
            incomingChanges={record}
            isDialog={false}
          />
        </MainCard>
      }
      expanded={true}
      setExpanded={() => {}}
    />
  );
};
