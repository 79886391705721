// material-ui
// assets
import { Button, DialogContent, DialogTitle, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import * as Sentry from '@sentry/react';
import { StandardGridField } from 'components/form/forms/deal/sections/DealFormSection';
import DynamicVisibilitySection from 'components/form/standard/DynamicVisibilitySection';
// material-ui
import StandardField from 'components/form/standard/StandardField';
import { StandardForm } from 'components/form/standard/StandardForm';

// project import
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import { EditingProvider } from 'contexts/EditingContext';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import IconButton from 'components/@extended/IconButton';

// project import
import { Deal, DealStatus } from 'types/deal';
import { StandardFormProps } from 'types/record';
import { updateDealAsync } from 'utils/deal';
import { AutoFixHigh } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { useState } from 'react';

const DealClosedFormHeader = () => {
  const [showButton, setShowButton] = useState(true);
  const { setValues } = useFormikContext();

  const handleAutoFill = () => {
    setValues((values: any) => {
      const updatedValues = {
        sale_price: _.get(values, DealMetadata.sale_price_est.fieldName),
        total_consideration: _.get(values, DealMetadata.total_consideration_est.fieldName),
        commission: _.get(values, DealMetadata.commission_est.fieldName),
        size: _.get(values, DealMetadata.size_est.fieldName),
        broker_payment_commission: _.get(values, DealMetadata.broker_payment_commission.fieldName).map((commission: any) => ({
          ...commission,
          actual_percent: commission.estimate_percent
        }))
      };
      return { ...values, ...updatedValues };
    });
    setShowButton(false);
  };

  return (
    <Grid container justifyContent="space-between">
      <DialogTitle>Closed Deal</DialogTitle>
      {showButton && (
        <Button size="small" onClick={handleAutoFill}>
          <IconButton>
            <AutoFixHigh />
          </IconButton>
          Auto-fill from estimates
        </Button>
      )}
    </Grid>
  );
};

export const DealClosedForm = ({ record, incomingChanges, onCancel, successCallback, open }: StandardFormProps<Deal>) => {
  const navigate = useNavigate();

  const updateFn = async (id: number | string, deal: Partial<Deal>) => {
    try {
      const record = await updateDealAsync(id, deal, navigate);
      if (typeof successCallback === 'function') successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to update a Deal actuals.');
    }
  };

  return (
    <StandardForm<Partial<Deal>>
      metadata={DealMetadata}
      record={record}
      updateFn={updateFn}
      displayName={'Notes'}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      open={open}
      fullWidth={true}
      maxWidth={'md'}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <DealClosedFormHeader />
          <DialogContent dividers>
            <Grid container columnSpacing={5} rowSpacing={4} columns={24}>
              <EditingProvider>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.close_date_est} />
                </Grid>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.sale_price_est} />
                  <StandardField field={DealMetadata.total_consideration_est} />
                </Grid>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.commission_est} />
                </Grid>
                <Grid item xs={24} sm={3}>
                  <StandardField field={DealMetadata.size_est} />
                </Grid>
                <Grid item xs={24} sm={3}>
                  <StandardField field={DealMetadata.size_type} />
                </Grid>
              </EditingProvider>
              <DynamicVisibilitySection visibilityFunction={(x) => _.get(x, 'status') === DealStatus.closed}>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.close_date} />
                </Grid>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.sale_price} />
                  <StandardField field={DealMetadata.total_consideration} />
                </Grid>
                <Grid item xs={24} sm={6}>
                  <StandardField field={DealMetadata.commission} />
                </Grid>
                <StandardGridField item xs={24} sm={3} field={DealMetadata.size} />
                <StandardGridField item xs={24} sm={3} field={DealMetadata.size_type} />
                <StandardGridField item xs={24} sm={6} field={DealMetadata.lease_expiration} />
              </DynamicVisibilitySection>
            </Grid>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <StandardGridField item xs={12} field={DealMetadata.broker_payment_commission} estimateReadOnly />
              <StandardGridField item xs={12} field={DealMetadata.commission_payments} />
            </Grid>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};
