import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import Loader from 'components/Loader';
import { Navigate } from 'react-router';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, isInitialized, refresh } = useAuth();
  const location = useLocation();

  useEffect(() => {
    refresh();
    // @ts-ignore
  }, [location]);

  return !isInitialized ? <Loader /> : isLoggedIn ? children : <Navigate to={'/login'} replace={true} />;
};

export default AuthGuard;
