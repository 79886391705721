export const getNewCompanyImportColumns = () => {
  return [
    {
      column_name: 'external_id',
      display_label: 'External ID',
      type: 'text',
      required: true,
      matching_keywords: 'Id'
    },
    {
      column_name: 'name',
      display_label: 'Name',
      type: 'text',
      required: false,
      matching_keywords: 'Company'
    },
    {
      column_name: 'description',
      display_label: 'Description',
      type: 'text',
      required: false
    },
    {
      column_name: 'telephone1',
      display_label: 'Phone',
      type: 'text',
      required: false,
      matching_keywords: 'Business Phone'
    },
    {
      column_name: 'website_url',
      display_label: 'Website',
      type: 'text',
      required: false,
      matching_keywords: 'Website'
    },
    {
      column_name: 'line_1',
      display_label: 'Address Line 1',
      type: 'text',
      required: false,
      matching_keywords: 'Business Street, Address'
    },
    {
      column_name: 'line_2',
      display_label: 'Address Line 2',
      type: 'text',
      required: false,
      matching_keywords: 'Business Street 2'
    },
    {
      column_name: 'city',
      display_label: 'Address City',
      type: 'text',
      required: false,
      matching_keywords: 'Business City, City'
    },
    {
      column_name: 'state',
      display_label: 'Address State',
      type: 'text',
      required: false,
      matching_keywords: 'Business State, State'
    },
    {
      column_name: 'postal_code',
      display_label: 'Address Postal Code',
      type: 'text',
      required: false,
      matching_keywords: 'Business Postal Code, Postal Code, Zip Code'
    },
    {
      column_name: 'country',
      display_label: 'Address Country',
      type: 'text',
      required: false,
      matching_keywords: 'Business Country/Region, Country'
    }
  ];
};
