import { SelectOption } from 'components/form/SelectOption';
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import { getBaseQueryOptionsDealService } from 'pages/deal/utils/api';
import { parseDealStageDict } from 'pages/deal/utils/deal';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DealStage } from 'types/deal';
import { AdditionalChangeHandlerType, FieldComponentProps } from 'types/standardForm';

export function DealTypeSelectOption(props: FieldComponentProps) {
  // When deal type changes, update the status
  const queryOptions = useMemo(() => getBaseQueryOptionsDealService<Record<number, DealStage>>('/stage/'), []);
  const { data: dealStages = [], isLoading: dealStagesLoading } = useQuery<Record<number, DealStage>>(queryOptions);

  const updateStageOnDealTypeChange: AdditionalChangeHandlerType = (value, setFieldValue) => {
    const dealStagesForDealType = parseDealStageDict(dealStages, value);
    // Set deal stage to the first stage option
    setFieldValue(DealMetadata.stage.fieldName, dealStagesForDealType[0]);
  };

  // Destructure additionalChangeHandler from props to avoid it being spread to SelectOption
  const { additionalChangeHandler, ...restProps } = props;

  return <SelectOption {...restProps} additionalChangeHandler={updateStageOnDealTypeChange} />;
}
