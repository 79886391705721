// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TOGGLE BUTTON ||============================== //
export default function ToggleButton(theme: Theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textWrap: 'nowrap',
          height: '44px',
          fontWeight: 600,
          borderRadius: '8px',
          padding: '12px 16px',
          borderColor: theme.palette.text.primary,
          color: theme.palette.text.primary,
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark
            }
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.divider,
            color: theme.palette.text.disabled
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          },
          '&.MuiToggleButton-fullWidth': {
            padding: '12px 4px'
          }
        }
      }
    }
  };
}
