// assets
import { Apartment, HandshakeOutlined, Person, Settings } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, Grid, Select, Stack, Switch, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

// material-ui
import { CreoneField } from 'components/form/basic/creone_field';
import { PERMISSIONS_DESCRIPTION_MAP } from 'constants/permission';
import { useFormikContext } from 'formik';
import { useCheckLicense } from 'hooks/useCheckLicense';
import _ from 'lodash';

// project import
import React, { useMemo } from 'react';
import { Product } from 'types/license';
import { FieldComponentProps } from 'types/standardForm';

function RolePermissions(props: FieldComponentProps) {
  const theme = useTheme();
  const hasDealViewLicense = useCheckLicense([Product.deal_view, Product.deal_view_pro]);
  const hasProspectsLicense = useCheckLicense([Product.prospects]);

  const { fieldName } = props;

  const { getFieldProps, setFieldValue } = useFormikContext();

  const { value: permissions } = getFieldProps(props.fieldName);

  const RecordActionsPermissions = useMemo(
    () =>
      ({
        title,
        property,
        recordName
      }: {
        title: string;
        property: 'viewer' | 'editor' | 'deleter';
        recordName: 'deal' | 'contact' | 'company';
      }) => {
        return (
          <Stack direction={'row'} alignItems="baseline" spacing={1}>
            <Typography fontWeight={600} width={250}>
              {title}
            </Typography>
            <FormControl variant="standard">
              <Select
                id={`select-${property}-permissions`}
                value={_.get(permissions, [recordName, property])}
                onChange={(event) =>
                  setFieldValue(fieldName, { ...permissions, [recordName]: { ...permissions[recordName], [property]: event.target.value } })
                }
                disableUnderline
                sx={{ minWidth: 120 }}
              >
                <MenuItem value={'all'}>{PERMISSIONS_DESCRIPTION_MAP[recordName]['all']}</MenuItem>
                <MenuItem value={'team'}>{PERMISSIONS_DESCRIPTION_MAP[recordName]['team']}</MenuItem>
                <MenuItem value={'individual'}>{PERMISSIONS_DESCRIPTION_MAP[recordName]['individual']}</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        );
      },
    [fieldName, permissions, setFieldValue]
  );

  const RecordPermissionsList = useMemo(
    () =>
      ({ recordName }: { recordName: 'deal' | 'contact' | 'company' }) => {
        return (
          <Grid container spacing={1} columns={4}>
            <Grid item xs={4}>
              <RecordActionsPermissions title={'View'} property={'viewer'} recordName={recordName} />
            </Grid>
            <Grid item xs={4}>
              <RecordActionsPermissions title={'Edit'} property={'editor'} recordName={recordName} />
            </Grid>
            <Grid item xs={4}>
              <RecordActionsPermissions title={'Delete'} property={'deleter'} recordName={recordName} />
            </Grid>
          </Grid>
        );
      },
    [RecordActionsPermissions]
  );

  return (
    <CreoneField {...props}>
      <Box
        sx={{
          '& .MuiAccordion-root': {
            borderColor: theme.palette.divider,
            '& .MuiAccordionSummary-root': {
              bgcolor: 'transparent',
              flexDirection: 'row',
              '&:focus-visible': {
                bgcolor: 'primary.lighter'
              }
            },
            '& .MuiAccordionDetails-root': {
              borderColor: theme.palette.divider
            },
            '& .Mui-expanded': {
              color: theme.palette.primary.main
            }
          }
        }}
      >
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Settings />
              <Typography variant="h6">Organization</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} columns={4}>
              <Grid item xs={4}>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                  <Typography fontWeight={600} width={250}>
                    Modify Organization Settings
                  </Typography>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Switch
                      checked={_.get(permissions, 'organization.settings')}
                      onChange={(event) =>
                        setFieldValue(fieldName, {
                          ...permissions,
                          organization: { ...permissions['organization'], settings: event.target.checked }
                        })
                      }
                    ></Switch>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                  <Typography fontWeight={600} width={250}>
                    Export Data
                  </Typography>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Switch
                      checked={_.get(permissions, 'organization.export')}
                      onChange={(event) =>
                        setFieldValue(fieldName, {
                          ...permissions,
                          organization: { ...permissions['organization'], export: event.target.checked }
                        })
                      }
                    ></Switch>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {hasDealViewLicense && (
          <Accordion>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Stack direction="row" spacing={1.5} alignItems="center">
                <HandshakeOutlined />
                <Typography variant="h6">Deals</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <RecordPermissionsList recordName={'deal'} />
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Person />
              <Typography variant="h6">Contacts</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <RecordPermissionsList recordName={'contact'} />
          </AccordionDetails>
        </Accordion>
        {hasProspectsLicense && (
          <Accordion>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Apartment />
                <Typography variant="h6">Companies</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <RecordPermissionsList recordName={'company'} />
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </CreoneField>
  );
}

export default RolePermissions;
