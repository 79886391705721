import { InputAdornment } from '@mui/material';
import { NumberFormatTextField, PercentTextField } from 'components/form/NumberFormatTextField';
import { useField, useFormikContext } from 'formik';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { FieldComponentProps } from 'types/standardForm';
import { CreoneField } from './basic/creone_field';

interface TextFieldPercentProps extends FieldComponentProps {
  size?: any;
}

function GetTextFieldPercent({ size, fieldName, disabled, additionalChangeHandler }: TextFieldPercentProps) {
  const [field, , helpers] = useField(fieldName);
  const { value } = field;
  const { setValue } = helpers;

  const [localFieldValue, setLocalFieldValue] = useState(value);

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  useEffect(() => {
    setLocalFieldValue(value);
  }, [value]);

  // Debounce field value setter
  const debouncedSetValue = useMemo(
    () =>
      _.debounce(
        (val: number | null | undefined) => {
          setValue(val);
        },
        400,
        { leading: false, trailing: true }
      ),
    [setValue]
  );

  return (
    <NumberFormat
      size={size}
      fullWidth
      id={fieldName}
      thousandSeparator={false}
      fixedDecimalScale={false}
      decimalScale={2}
      placeholder="0"
      disabled={disabled}
      value={_.isNumber(localFieldValue) ? localFieldValue * 100 : localFieldValue}
      onValueChange={(val, sourceInfo) => {
        if (sourceInfo.source === 'event') {
          const floatVal = _.isNumber(val.floatValue) ? val.floatValue / 100 : val.floatValue;
          setLocalFieldValue(floatVal);
          debouncedSetValue(floatVal);
        }
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>
      }}
      onBlur={handleBlur}
      customInput={PercentTextField}
    />
  );
}

export function TextFieldPercent(props: TextFieldPercentProps) {
  return (
    <CreoneField {...props}>
      <GetTextFieldPercent
        displayName={props.displayName}
        size={'medium'}
        fieldName={props.fieldName}
        disabled={props.disabled}
        additionalChangeHandler={props.additionalChangeHandler}
      />
    </CreoneField>
  );
}
