import _ from 'lodash';
import { dateFormats, regexExpressions } from 'pages/data_import/constants/common';
import { getUserFullName } from 'pages/deal/utils/deal';
import { SimpleTeamMember } from 'types/auth';

const prospectStatusValues = [
  {
    display_label: 'Open',
    value: 'open'
  },
  {
    display_label: 'Converted',
    value: 'converted'
  },
  {
    display_label: 'Lost',
    value: 'lost'
  }
];

export function getActitivtyTypeValidators() {
  return {
    values: [
      {
        display_label: 'Call',
        value: 'call'
      },
      {
        display_label: 'Email',
        value: 'email'
      },
      {
        display_label: 'Meeting',
        value: 'meeting'
      },
      {
        display_label: 'Task',
        value: 'task'
      }
    ],
    case_sensitive: false
  };
}

export const getNewContactImportColumns = (
  brokerValues: Array<{ value: string; display_label: string }>,
  clientUser?: SimpleTeamMember
): any[] => {
  return _.filter(getAllContactImportColumns(brokerValues, clientUser), (x) => !['id'].includes(x.column_name));
};

export const getAllContactImportColumns = (
  brokerValues: Array<{ value: string; display_label: string }>,
  clientUser?: SimpleTeamMember
) => {
  return [
    {
      column_name: 'id',
      display_label: 'ID',
      type: 'text',
      required: true,
      matching_keywords: 'Id'
    },
    {
      column_name: 'external_id',
      display_label: 'External ID (Optional)',
      type: 'text',
      required: false,
      matching_keywords: 'Id'
    },
    {
      column_name: 'owner',
      display_label: 'Contact Owner',
      type: 'list',
      validators: {
        values: brokerValues,
        case_sensitive: false
      },
      required: false,
      default_value: getUserFullName(clientUser),
      matching_keywords: 'Contact Owner, Owner, Contact Owner*'
    },
    {
      column_name: 'first_name',
      _updates_field: 'first_name',
      display_label: 'First Name',
      type: 'text',
      required: false,
      matching_keywords: 'First Name, First Name*'
    },
    {
      column_name: 'last_name',
      _updates_field: 'last_name',
      display_label: 'Last Name',
      type: 'text',
      required: false
    },
    {
      column_name: 'title',
      _updates_field: 'title',
      display_label: 'Job Title',
      type: 'text',
      required: false,
      matching_keywords: 'Job Title'
    },
    {
      column_name: 'email_address',
      display_label: 'Email Address',
      type: 'text',
      required: false,
      matching_keywords: 'E-mail Address, Email, E-mail',
      _updates_field: 'email_address'
    },
    {
      column_name: 'telephone1',
      _updates_field: 'telephone1',
      display_label: 'Phone',
      type: 'text',
      required: false,
      matching_keywords: 'Business Phone'
    },
    {
      column_name: 'telephone1_ext',
      _updates_field: 'telephone1_ext',
      display_label: 'Phone Ext.',
      type: 'text',
      required: false
    },
    {
      column_name: 'telephone2',
      _updates_field: 'telephone2',
      display_label: 'Mobile Phone',
      type: 'text',
      required: false
    },
    {
      column_name: 'notes_plaintext',
      _updates_field: 'notes_plaintext',
      display_label: 'Notes',
      type: 'text',
      required: false,
      matching_keywords: 'Contact Notes'
    },
    {
      column_name: 'contact_type_str',
      display_label: 'Contact Type',
      type: 'text',
      required: false
    },
    {
      column_name: 'group_str',
      display_label: 'Group',
      type: 'text',
      required: false,
      matching_keywords: 'Group, Group(s)',
      _updates_field: 'groups'
    },
    {
      column_name: 'company_external_id',
      display_label: 'Company External ID (Optional)',
      type: 'text',
      required: false,
      matching_keywords: 'Company Id'
    },
    {
      column_name: 'company_name',
      display_label: 'Company Name',
      type: 'text',
      required: false,
      matching_keywords: 'Company',
      _updates_field: 'company_id'
    },
    {
      column_name: 'company_address_line_1',
      display_label: 'Company Address Line 1',
      type: 'text',
      required: false,
      matching_keywords: 'Business Street, Address'
    },
    {
      column_name: 'company_address_line_2',
      display_label: 'Company Address Line 2',
      type: 'text',
      required: false,
      matching_keywords: 'Business Street 2'
    },
    {
      column_name: 'company_address_city',
      display_label: 'Company Address City',
      type: 'text',
      required: false,
      matching_keywords: 'Business City, City'
    },
    {
      column_name: 'company_address_state',
      display_label: 'Company Address State',
      type: 'text',
      required: false,
      matching_keywords: 'Business State, State'
    },
    {
      column_name: 'company_address_postal_code',
      display_label: 'Company Address Postal Code',
      type: 'text',
      required: false,
      matching_keywords: 'Business Postal Code, Postal Code, Zip Code'
    },
    {
      column_name: 'company_address_country',
      display_label: 'Company Address Country',
      type: 'text',
      required: false,
      matching_keywords: 'Business Country/Region, Country'
    },
    {
      column_name: 'next_activity_subject',
      display_label: 'Next Activity Subject',
      type: 'text',
      required: false
    },
    {
      column_name: 'next_activity_date',
      display_label: 'Next Activity Date',
      type: 'date',
      validators: {
        format: dateFormats
      },
      required: false,
      matching_keywords: 'Next Activity Date'
    },
    {
      column_name: 'next_activity_type_v2',
      display_label: 'Next Activity Type',
      type: 'list',
      validators: getActitivtyTypeValidators(),
      default_value: 'Call',
      required: false
    },
    {
      column_name: 'is_prospect',
      display_label: 'Is Prospect?',
      type: 'boolean',
      required: false
    },
    {
      column_name: 'status',
      display_label: 'Prospect Status',
      type: 'list',
      validators: {
        values: prospectStatusValues,
        case_sensitive: false
      },
      required: true,
      matching_keywords: 'Status',
      default_value: 'Open'
    },
    {
      column_name: 'prospect_types',
      display_label: 'Prospect Type',
      type: 'multiselect_list',
      validators: {
        values: ['Buyer', 'Seller', 'Landlord', 'Tenant', 'Other'],
        delimiter: ';',
        case_sensitive: false
      },
      required: false
    },
    {
      column_name: 'asset_type_str',
      display_label: 'Asset Type',
      type: 'text',
      required: false,
      _updates_field: 'asset_types'
    },
    {
      column_name: 'submarket_str',
      display_label: 'Submarket',
      type: 'text',
      required: false,
      _updates_field: 'submarkets'
    },
    {
      column_name: 'source_type_str',
      display_label: 'Prospect Source',
      type: 'text',
      required: false
    },
    {
      column_name: 'size_est',
      display_label: 'Estimated Size',
      type: 'regex',
      validators: {
        expression: regexExpressions.number
      },
      required: false
    },
    {
      column_name: 'size_type',
      display_label: 'Size Type',
      type: 'list',
      validators: {
        values: [
          {
            display_label: 'Square Feet',
            value: 'square_feet'
          },
          {
            display_label: 'Acres',
            value: 'acres'
          },
          {
            display_label: 'Units',
            value: 'units'
          }
        ],
        case_sensitive: false
      },
      default_value: 'Square Feet',
      required: false
    },
    {
      column_name: 'client_industry_str',
      display_label: 'Client Industry',
      type: 'text',
      required: false,
      matching_keywords: 'Client Industry, Industry'
    },
    {
      column_name: 'property_name_str',
      display_label: 'Property Name',
      type: 'text',
      required: false,
      matching_keywords: 'Property Name',
      _updates_field: 'property_names'
    },
    {
      column_name: 'lease_expiration',
      display_label: 'Lease Expiration',
      type: 'date',
      validators: {
        format: dateFormats
      },
      required: false,
      matching_keywords: 'Current Lease Expiration'
    },
    {
      column_name: 'lost_reason_str',
      display_label: 'Lost Reason',
      type: 'text',
      required: false
    },
    {
      column_name: 'last_activity_subject',
      display_label: 'Last Activity Subject',
      type: 'text',
      required: false
    },
    {
      column_name: 'last_activity_date',
      display_label: 'Last Activity Date',
      type: 'date',
      validators: {
        format: dateFormats
      },
      required: false,
      matching_keywords: 'Last Activity Date'
    },
    {
      column_name: 'last_activity_type_v2',
      display_label: 'Last Activity Type',
      type: 'list',
      validators: getActitivtyTypeValidators(),
      default_value: 'Call',
      required: false
    }
  ];
};
