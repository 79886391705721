import { useFormikContext } from 'formik';
import { TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import { FieldComponentProps } from 'types/standardForm';
import { CreoneField } from './basic/creone_field';

interface TextFieldWholeNumberProps extends FieldComponentProps {
  size?: any;
  decimalScale?: any;
}

export function GetNumberFormat({
  size,
  fieldName,
  decimalScale,
  disabled
}: {
  size: any;
  fieldName: string;
  decimalScale: any;
  disabled: boolean | undefined;
}) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const handleBlur = () => {
    setFieldTouched(fieldName, true);
  };
  return (
    <NumberFormat
      size={size}
      fullWidth
      id={fieldName}
      thousandSeparator
      decimalScale={decimalScale}
      placeholder="0"
      disabled={disabled}
      value={_.get(values, fieldName)}
      onValueChange={(val) => setFieldValue(fieldName, _.isUndefined(val.floatValue) ? null : val.floatValue)}
      customInput={TextField}
      onBlur={handleBlur}
    />
  );
}

export function TextFieldWholeNumber(props: TextFieldWholeNumberProps) {
  const decimalScale = props.decimalScale === undefined ? 0 : props.decimalScale;
  return (
    <CreoneField {...props}>
      <GetNumberFormat size={'medium'} fieldName={props.fieldName} decimalScale={decimalScale} disabled={props.disabled} />
    </CreoneField>
  );
}

export function TextFieldTwoDecimal(props: TextFieldWholeNumberProps) {
  return <TextFieldWholeNumber {...props} decimalScale={2} />;
}
