import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import IntegrationRoutes from 'routes/integrationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  let routes = [LoginRoutes, MainRoutes, IntegrationRoutes];
  return useRoutes(routes);
}
