import { filterEmpty } from 'types/navigation/common';

export enum SortOrderEnum {
  asc = 'asc',
  desc = 'desc'
}

export interface BaseFilter {
  search?: string;
  skip?: number;
  limit?: number;
  sort?: string[];
  sort_order?: SortOrderEnum[] | typeof filterEmpty;
}
