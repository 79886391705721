import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Box, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import LoadingButton from 'components/@extended/LoadingButton';
import { useFormik } from 'formik';
import { registrationSteps } from 'pages/auth/constants/registration';
import React, { SyntheticEvent, useState } from 'react';
import { RootState, useSelector } from 'store';
import { setUserDetailsPassword } from 'store/reducers/registrationData';
import { RegistrationStepProps } from 'types/authRegister';
import { StringColorProps } from 'types/password';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  password: Yup.string().max(255).required('Password is required'),
  passwordConfirm: Yup.string()
    .nullable()
    .max(255)
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match')
});

export const UserPasswordForm = ({ handleNext, handleBack, setErrorIndex }: RegistrationStepProps) => {
  const userDetailsPassword = useSelector((state: RootState) => state.registrationData.password);

  const [level, setLevel] = useState<StringColorProps>();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  const changePassword = (value: string) => {
    setLevel(strengthColor(strengthIndicator(value)));
  };

  const formik = useFormik({
    initialValues: {
      password: userDetailsPassword,
      passwordConfirm: userDetailsPassword
    },
    validationSchema,
    onSubmit: (values) => {
      setUserDetailsPassword(values.password);
      handleNext();
    }
  });

  const { values, touched, errors, handleBlur, handleChange } = formik;

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ mb: 2 }}>
        {registrationSteps[0].title}
      </Typography>
      <Stack spacing={2}>
        <form onSubmit={formik.handleSubmit} id="validation-forms">
          <Grid container>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-signup">Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id="password-signup"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      changePassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="secondary"
                        >
                          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="******"
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText error id="helper-text-password-signup">
                      {errors.password}
                    </FormHelperText>
                  )}
                </Stack>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Box sx={{ bgcolor: level?.color, width: 85, height: 8, borderRadius: '7px' }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontSize="0.75rem">
                        {level?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-confirm-signup">Confirm Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                    id="password-confirm-signup"
                    type={showPassword ? 'text' : 'password'}
                    value={values.passwordConfirm}
                    name="passwordConfirm"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="secondary"
                        >
                          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="******"
                    inputProps={{}}
                  />
                  {touched.passwordConfirm && errors.passwordConfirm && (
                    <FormHelperText error id="helper-text-password-signup">
                      {errors.passwordConfirm}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid container item xs={12} alignItems={'center'}>
                <Grid item xs={2}>
                  <LoadingButton disableElevation fullWidth size="large" variant="text" color="primary" onClick={() => handleBack()}>
                    Back
                  </LoadingButton>
                </Grid>
                <Grid item xs={true}></Grid>
                <Grid item xs={3}>
                  <LoadingButton disableElevation fullWidth size="large" type="submit" variant="contained" color="primary">
                    Continue
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </>
  );
};
