import _ from 'lodash';
import { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Typography } from '@mui/material';

// project imports
import MainCard from '../MainCard';

// assets
import { ApartmentOutlined } from '@ant-design/icons';

// types
import { NavItemType, OverrideIcon } from 'types/menu';

// ==============================|| BREADCRUMBS ||============================== //

export interface BreadCrumbSxProps extends CSSProperties {
  mb?: string;
  bgcolor?: string;
}

interface Props {
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  navigation?: { items: NavItemType[] };
  rightAlign?: boolean;
  separator?: OverrideIcon;
  title?: boolean;
  titleBottom?: boolean;
  sx?: BreadCrumbSxProps;
}

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}: Props) => {
  const theme = useTheme();
  const location = useLocation();
  const [item, setItem] = useState<NavItemType | null>(null);

  useEffect(() => {
    const findSubItem = (menu: NavItemType): NavItemType | null => {
      if (menu.type && ['group', 'hidden'].includes(menu.type) && menu.children) {
        for (const child of menu.children) {
          const found = findSubItem(child);
          if (found) return found;
        }
      } else if (menu.type === 'collapse' && menu.children) {
        for (const child of menu.children) {
          const found = findSubItem(child);
          if (found) return found;
        }
      } else if (menu.type === 'item' && _.includes(location.pathname, menu.url)) {
        return menu;
      }
      return null;
    };

    const findItem = (navigation?: { items: NavItemType[] }) => {
      if (navigation?.items) {
        for (const menu of navigation.items) {
          const found = findSubItem(menu);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    setItem(findItem(navigation));
  }, [navigation, location.pathname]);

  let breadcrumbContent: ReactElement = <Typography />;
  // items
  if (item && item.type === 'item') {
    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          border={card}
          sx={card === false ? { mb: 3, bgcolor: 'transparent', ...sx } : { mb: 3, ...sx }}
          {...others}
          content={card}
          shadow="none"
        >
          <Grid
            container
            direction={rightAlign ? 'row' : 'column'}
            justifyContent={rightAlign ? 'space-between' : 'flex-start'}
            alignItems={rightAlign ? 'center' : 'flex-start'}
            spacing={1}
          >
            {title && !titleBottom && (
              <Grid item>
                <Typography variant="h2">{item.title}</Typography>
              </Grid>
            )}
            {title && titleBottom && (
              <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
                <Typography variant="h2">{item.title}</Typography>
              </Grid>
            )}
          </Grid>
          {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
        </MainCard>
      );
    }
  }

  return breadcrumbContent;
};

export default Breadcrumbs;
