import useAuth from 'hooks/useAuth';
import _ from 'lodash';
import { showDevLicenseToggle } from 'pages/deal/utils/deal';
import { AccessTokenRead } from 'types/api/user_management/access_token';
import { Product } from 'types/license';

const APP_PRODUCT_MAP = {
  lend_db: ['lend_db'],
  deal_view: ['deal_view', 'onesource_suite'],
  deal_view_pro: ['deal_view_pro', 'onesource_suite'],
  prospects: ['prospects', 'onesource_suite']
};

export const checkLicense = (user: AccessTokenRead, products: Product[]) => {
  const product_ids = _.flatMap(products, (productName) => _.get(APP_PRODUCT_MAP, productName));

  if (showDevLicenseToggle(user?.user_id)) {
    // Path for dev
    const storedValue = typeof window !== 'undefined' ? localStorage.getItem('license-type-dev') : null;
    if (storedValue) {
      return _.intersection(JSON.parse(storedValue) ?? [], products).length > 0;
    } else {
      // If not set, default to Deal View Pro
      return _.intersection([Product.deal_view_pro], products).length > 0;
    }
  }
  return _.intersection(user?.products ?? [], product_ids).length > 0;
};

export const useCheckLicense = (products: Product[] | Product) => {
  const { user } = useAuth();

  if (!_.isArray(products)) return checkLicense(user, [products]);
  else return checkLicense(user, products);
};

/**
 * WARNING: Only call the below function from inside a React component
 * See Rules of Hooks for details
 * https://reactjs.org/docs/hooks-rules.html
 */
// TODO: Remove this function, move user products and permissions to context
export const cachedLicense = _.memoize(useCheckLicense);
