import { OutlookLogoIcon } from '@fluentui/react-icons-mdl2-branded';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { TimelineEntry } from 'components/form/forms/sections/ActivityTimeline/TimelineEntry';
import dayjs from 'dayjs';
import _ from 'lodash';
import { LightTooltip } from 'pages/deal/components/InfoTooltip';
import { formatDateString, formatDateTimeString } from 'pages/deal/utils/reporting';
import React, { useState } from 'react';
import { OutlookContactInfo, OutlookEmailObject } from 'types/integration/microsoft/outlook';

interface OutlookTimelineEntryProps {
  emailObject: OutlookEmailObject;
}

function GridItem({ label, content }: { label: string; content: string }) {
  return (
    <>
      <Grid item xs={5} style={{ textAlign: 'right' }}>
        <Typography variant="body1" fontWeight={600}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={19}>
        <Typography variant="body1">{content}</Typography>
      </Grid>
    </>
  );
}

export const OutlookTimelineEntry = ({ emailObject }: OutlookTimelineEntryProps) => {
  const [expanded, setExpanded] = useState(false);

  const datetime = dayjs(_.get(emailObject, 'sentDateTime', '')).toDate();
  const title = (
    <>
      <LightTooltip
        title={
          <Grid container columnSpacing={0.75} sx={{ p: 1 }} columns={24} onClick={(event) => event.stopPropagation()}>
            <GridItem label="Subject:" content={_.get(emailObject, 'subject', '')} />
            <GridItem label="From:" content={formatEmailList([_.get(emailObject, 'sender')], true)} />
            <GridItem label="To:" content={formatEmailList(_.get(emailObject, 'toRecipients', []))} />
            <GridItem label="Cc:" content={formatEmailList(_.get(emailObject, 'ccRecipients', []))} />
            {_.size(_.get(emailObject, 'bccRecipients', [])) > 0 && (
              <GridItem label="Bcc:" content={formatEmailList(_.get(emailObject, 'bccRecipients', []))} />
            )}
          </Grid>
        }
        arrow
      >
        <Typography style={{ fontWeight: 600, cursor: 'pointer' }}>
          {_.get(emailObject, 'subject', '')}
          <Typography style={{ color: 'grey' }} component={'span'}>
            {' '}
            from {_.get(emailObject, 'sender.emailAddress.name', 'Unknown')}
          </Typography>
        </Typography>
      </LightTooltip>
    </>
  );
  const subtitle = `to ${formatEmailList(_.get(emailObject, 'toRecipients', []), true, !expanded)}`; // Show the full email list when expanded
  const activityBodyPreview = _.get(emailObject, 'bodyPreview', '');
  const activityBody =
    _.get(emailObject, 'body.contentType', '') === 'html' ? (
      <div dangerouslySetInnerHTML={{ __html: _.get(emailObject, 'body.content', '') }} />
    ) : (
      _.get(emailObject, 'body.content', '')
    );
  const link = _.get(emailObject, 'webLink');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <TimelineEntry
      datetime={
        <LightTooltip title={<div onClick={(event) => event.stopPropagation()}>{formatDateTimeString(datetime)}</div>}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5} onClick={handleExpandClick} sx={{ cursor: 'pointer' }}>
            <Box sx={{ pt: 0.25 }}>{formatDateString(datetime)}</Box>
          </Stack>
        </LightTooltip>
      }
      color={'primary'}
      avatarContents={
        <LightTooltip title={'Outlook Email'}>
          <Box>
            <OutlookLogoIcon />
          </Box>
        </LightTooltip>
      }
      title={
        <Box onClick={handleExpandClick} sx={{ cursor: 'pointer' }}>
          {title}
        </Box>
      }
      subtitle={subtitle}
      activityBody={activityBody}
      activityBodyPreview={activityBodyPreview}
      link={link}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  );
};

const formatEmailList = (contactInfo: OutlookContactInfo[], namesOnly: boolean = false, truncate: boolean = false) => {
  const MAX_LENGTH = 3;

  if (_.isArray(contactInfo) && _.size(contactInfo) > 0) {
    let formattedList = _.map(contactInfo, (obj) =>
      !namesOnly ? `${obj?.emailAddress?.name} <${obj?.emailAddress?.address}>` : obj?.emailAddress?.name
    );

    if (truncate && formattedList.length > MAX_LENGTH) {
      const extraCount = formattedList.length - MAX_LENGTH;
      formattedList = _.take(formattedList, MAX_LENGTH);
      formattedList.push(`and ${extraCount} more`);
    }

    return formattedList.join(', ');
  } else {
    return '--';
  }
};
