import { formatSimpleOptionForDisplayMultiple } from 'components/form/standard/utils/formatting';
import { TextFieldString } from 'components/form/TextFieldString';
import _ from 'lodash';
import { ProductLookupField, RoleLookupField, TeamLookupField } from 'pages/deal/components/LookupField';
import {
  parseAppProductAsSimpleOptionMultiple,
  parseRecordAsSimpleOption,
  simpleOptionSubmitHandler,
  simpleOptionSubmitHandlerMultiple
} from 'pages/deal/utils/deal_form';
import { HierarchyRead, RoleRead } from 'types/api/user_management/dbRead';
import { ObjectMetadata } from 'types/standardForm';
import * as Yup from 'yup';

export const ProfileMetadata: ObjectMetadata = {
  first_name: {
    fieldName: 'first_name',
    displayName: 'First Name',
    initialValue: '',
    _schema: Yup.string().required(`First Name is required.`).max(255, 'First Name must be less than 256 characters.'),
    component: TextFieldString
  },
  last_name: {
    fieldName: 'last_name',
    displayName: 'Last Name',
    initialValue: '',
    _schema: Yup.string().max(255, 'Last Name must be less than 256 characters.'),
    component: TextFieldString
  }
};

export const UserMetadata: ObjectMetadata = {
  ...ProfileMetadata,
  username: {
    fieldName: 'username',
    displayName: 'Email',
    initialValue: '',
    _schema: Yup.string().email().required(`Email is required.`).max(255, 'Email must be less than 256 characters.'),
    component: TextFieldString,
    submitHandler: (value: string) => _.toLower(value)
  },
  hierarchy: {
    fieldName: 'hierarchy',
    displayName: 'Team',
    initialValue: null,
    _schema: Yup.object().nullable().required('Team is required.'),
    component: TeamLookupField,
    submitHandler: simpleOptionSubmitHandler,
    fieldCorrespondence: 'hierarchy_id',
    loadHandler: (x: HierarchyRead | null) => parseRecordAsSimpleOption<HierarchyRead>(x, 'name')
  },
  role: {
    fieldName: 'role',
    displayName: 'Role',
    initialValue: null,
    _schema: Yup.object().nullable().required('Role is required.'),
    component: RoleLookupField,
    submitHandler: simpleOptionSubmitHandler,
    fieldCorrespondence: 'role_id',
    loadHandler: (x: RoleRead | null) => parseRecordAsSimpleOption<RoleRead>(x, 'name')
  },
  licenses: {
    fieldName: 'licenses',
    displayName: 'License',
    initialValue: [],
    _schema: Yup.array().min(1, 'At least one license is required').required('This field is required'),
    component: ProductLookupField,
    submitHandler: simpleOptionSubmitHandlerMultiple,
    loadHandler: parseAppProductAsSimpleOptionMultiple,
    fieldCorrespondence: 'license_ids',
    formatForDisplay: formatSimpleOptionForDisplayMultiple
  }
};
