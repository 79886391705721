import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

export const DealFormTooltip = ({
  active,
  message,
  children
}: {
  // boolean to allow the tooltip to be open if set to true
  active: boolean;
  message: string | undefined;
  // Children nodes
  children?: any;
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  return (
    <Tooltip
      title={message ?? 'Field is unavailable.'}
      placement={'bottom-end'}
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(active)}
      onClose={() => setTooltipOpen(false)}
      arrow
    >
      {/* Box is added to prevent some listeners from erroring out*/}
      <Box>{children}</Box>
    </Tooltip>
  );
};
