// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import contactNavigationReducer from 'store/reducers/contactNavigation';
import dealNavigationReducer from 'store/reducers/dealNavigation';
import calendar from './calendar';
import cartReducer from './cart';

// project import
import dealReducer from './deal';
import dealCommissionFiltersReducer from './dealCommissionFilters';
import dealCommissionGoalReducer from './dealCommissionGoal';
import dealCommissionGoalV2Reducer from './dealCommissionGoalV2';
import dealOptionV2Reducer from './dealOptionV2';
import dealStageReducer from './dealStage';
// Table configs
import dealReducerV2 from './dealV2';
import dealWidgetsConfigReducer from './dealWidgetsConfig';
import kanbanReducer from './kanban';
import lenderReducer from './lender';
import lenderContactReducer from './lenderContact';
import lenderDealReducer from './lenderDeal';
import lenderDealFilterReducer from './lenderDealFilters';
import lenderMetricsReducer from './lenderMetrics';
import lenderNotificationReducer from './lenderNotification';
import menu from './menu';
import productReducer from './product';
import quoteContactFiltersReducer from './quoteContactFilter';
import recordReducer from './record';
import registrationDataReducer from './registrationData';
import onboardingDataReducer from './onboardingData';
import snackbar from './snackbar';
import stripeReducer from './stripe';
import tableViewReducer from './tableView';

// ==============================|| COMBINE REDUCERS ||============================== //
const DEFAULT_PERSIST_PREFIX = 'cre-onesource-';

const reducers = combineReducers({
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'mantis-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  lender: lenderReducer,
  lenderDeal: lenderDealReducer,
  record: recordReducer,
  tableView: tableViewReducer,
  deal: dealReducer,
  dealV2: dealReducerV2,
  kanban: kanbanReducer,
  dealCommissionGoal: dealCommissionGoalReducer,
  dealCommissionGoalV2: dealCommissionGoalV2Reducer,
  dealCommissionFilters: dealCommissionFiltersReducer,
  dealOptionV2: dealOptionV2Reducer,
  dealNavigation: dealNavigationReducer,
  contactNavigation: contactNavigationReducer,
  dealWidgetsConfig: persistReducer(
    {
      key: 'deal-widgets-config',
      storage,
      keyPrefix: DEFAULT_PERSIST_PREFIX
    },
    dealWidgetsConfigReducer
  ),
  quoteContactFilters: quoteContactFiltersReducer,
  dealStage: dealStageReducer,
  stripe: stripeReducer,
  lenderContact: lenderContactReducer,
  lenderNotification: lenderNotificationReducer,
  lenderMetrics: lenderMetricsReducer,
  lenderDealFilters: lenderDealFilterReducer,
  registrationData: persistReducer(
    {
      key: 'registration-data',
      storage,
      keyPrefix: DEFAULT_PERSIST_PREFIX,
      blacklist: ['password']
    },
    registrationDataReducer
  ),
  onboardingData: persistReducer(
    {
      key: 'onboarding-data-v2',
      storage,
      keyPrefix: DEFAULT_PERSIST_PREFIX
    },
    onboardingDataReducer
  )
});

export default reducers;
