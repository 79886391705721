import { CampaignOutlined } from '@mui/icons-material';

// material-ui
import { Box, Stack, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { checkLicense } from 'hooks/useCheckLicense';
import { feedbackInstance } from 'index';
import menuItem from 'menu-items';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

// project import
import NavGroup from './NavGroup';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { user } = useAuth();

  const handleReportABugClick = async () => {
    const form = await feedbackInstance.createForm({
      onFormClose: () => {
        form.close();
      },
      onFormSubmitted: () => {
        form.removeFromDom;
      }
    });
    form.appendToDom();
    form.open();
  };

  const navGroups = useMemo(() => {
    return menuItem.items.map((item) => {
      if (!item.products?.length || checkLicense(user, item.products)) {
        switch (item.type) {
          case 'group':
            return <NavGroup key={item.id} item={item} />;
          case 'hidden':
            return null;
          default:
            return (
              <Typography key={item.id} variant="h6" color="error" align="center">
                Fix - Navigation Group
              </Typography>
            );
        }
      } else {
        return null;
      }
    });
  }, [user]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      {navGroups} <Box sx={{ flexGrow: 1 }} />
      <Stack direction={'row'} justifyContent={'center'}>
        <NavGroup
          item={{
            type: 'group',
            children: [
              {
                id: 'report-a-bug',
                title: <FormattedMessage id="Report a Bug" />,
                type: 'item',
                icon: CampaignOutlined,
                onClick: handleReportABugClick
              }
            ]
          }}
        />
      </Stack>
    </Box>
  );
};

export default Navigation;
