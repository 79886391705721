import { DialogTitle, Grid } from '@mui/material';
import { CloseButton } from 'pages/deal/components/CloseButton';
import React from 'react';

export function StandardRecordFormTitle({
  recordName,
  isEdit = true,
  onClose
}: {
  recordName: string;
  isEdit?: boolean;
  onClose: () => void;
}) {
  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item>
        <DialogTitle>
          {isEdit ? 'Edit' : 'Add'} {recordName}
        </DialogTitle>
      </Grid>
      <Grid item sx={{ mr: 1.5 }}>
        <CloseButton onClose={onClose} />
      </Grid>
    </Grid>
  );
}
