import { Grid } from '@mui/material';
import LoadingButton from 'components/@extended/LoadingButton';
import { RootState, useSelector } from 'store';

export const StepActions = ({ handleNext, handleBack, loading, nextButtonText = 'Next' }: any) => {
  const { stepNumber } = useSelector((state: RootState) => state.onboardingData);

  return (
    <Grid container spacing={1} sx={{ borderTop: '1px solid rgba(13,13,13, 0.05)', p: { xs: 2, lg: '16px 0 0' }, mt: '40px !important' }}>
      <Grid container item xs={12} columnSpacing={2} alignItems={'center'}>
        <Grid item xs={4} sm={8}></Grid>
        <Grid item xs={4} sm={2}>
          {handleBack && stepNumber > 0 && (
            <LoadingButton disableElevation fullWidth size="large" variant="text" color="primary" onClick={handleBack}>
              Back
            </LoadingButton>
          )}
        </Grid>

        <Grid item xs={4} sm={2}>
          <LoadingButton
            disableElevation
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            loading={loading || false}
            onClick={handleNext}
          >
            {nextButtonText}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
