// third-party
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

// project imports
import { axiosLenderContactsServices } from 'utils/axios';
import { dispatch } from '../index';

// types
import { LenderContactsStateProps, LenderContact } from 'types/lenderContact';

// ----------------------------------------------------------------------

const initialState: LenderContactsStateProps = {
  error: null,
  count: 0,
  lenderContacts: []
};

const lenderContact = createSlice({
  name: 'lenderContact',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getLenderContactsSuccess(state, action) {
      state.lenderContacts = action.payload;
      state.count = action.payload.count;
    },

    addLenderContactSuccess(state, action) {
      state.lenderContacts.push(action.payload);
    },

    deleteLenderSuccess(state, action) {
      const id = _.get(action.payload, 'id');
      state.lenderContacts = _.remove(state.lenderContacts, (contact) => contact.id != id);
    },

    updateLenderContactSuccess(state, action) {
      const id = _.get(action.payload, 'id');
      const idx = _.findIndex(state.lenderContacts, { id: id });
      state.lenderContacts[idx] = _.merge(state.lenderContacts[idx], action.payload);
    }
  }
});

export default lenderContact.reducer;

// ----------------------------------------------------------------------

export function getLenderContacts(lenderId: number) {
  axiosLenderContactsServices
    .get(`/`, { params: { lender_id: lenderId } })
    .then((response) => {
      dispatch(lenderContact.actions.getLenderContactsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderContact.actions.hasError(error));
    });
}

export function createLenderContact(newLenderContact: LenderContact) {
  axiosLenderContactsServices
    .post('/', newLenderContact)
    .then((response) => {
      dispatch(lenderContact.actions.addLenderContactSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderContact.actions.hasError(error));
    });
}

export function deleteLenderContact(contactId: number) {
  axiosLenderContactsServices
    .delete(`/${contactId}`)
    .then((response) => {
      dispatch(lenderContact.actions.deleteLenderSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderContact.actions.hasError(error));
    });
}

export function updateLenderContact(updateLenderContact: any) {
  axiosLenderContactsServices
    .patch(`/${updateLenderContact.id}`, updateLenderContact)
    .then((response) => {
      dispatch(lenderContact.actions.updateLenderContactSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderContact.actions.hasError(error));
    });
}
