import { SelectOption } from 'components/form/SelectOption';
import { addBusinessDays } from 'date-fns';
import dayjs from 'dayjs';
import { FieldComponentProps } from 'types/standardForm';

export enum ActivityFollowupOption {
  oneBusinessDay = 'oneBusinessDay',
  twoBusinessDays = 'twoBusinessDays',
  oneWeek = 'oneWeek',
  twoWeeks = 'twoWeeks',
  oneMonth = 'oneMonth',
  threeMonths = 'threeMonths',
  sixMonths = 'sixMonths',
  oneYear = 'oneYear',
  custom = 'custom'
}

// Function to get the Date object for a given option
export const getDateForFollowupOption = (option: ActivityFollowupOption): Date => {
  switch (option) {
    case ActivityFollowupOption.oneBusinessDay:
      return addBusinessDays(new Date(), 1);
    case ActivityFollowupOption.twoBusinessDays:
      return addBusinessDays(new Date(), 2);
    case ActivityFollowupOption.oneWeek:
      return dayjs().add(7, 'day').toDate();
    case ActivityFollowupOption.twoWeeks:
      return dayjs().add(14, 'day').toDate();
    case ActivityFollowupOption.oneMonth:
      return dayjs().add(1, 'month').toDate();
    case ActivityFollowupOption.threeMonths:
      return dayjs().add(3, 'month').toDate();
    case ActivityFollowupOption.sixMonths:
      return dayjs().add(6, 'month').toDate();
    case ActivityFollowupOption.oneYear:
      return dayjs().add(1, 'year').toDate();
    default:
      return new Date(); // Default case can be adjusted as needed
  }
};

// Function to format the date for the label
const formatDate = (date: Date) => {
  const now = dayjs();
  const inputDate = dayjs(date);

  // Compare the year of the input date with the current year
  if (inputDate.year() === now.year()) {
    return inputDate.format('MMMM D');
  } else {
    return inputDate.format('MMMM D, YYYY');
  }
};

// Using the function to define options
const options = [
  {
    key: ActivityFollowupOption.oneBusinessDay,
    label: `In 1 Business Day (${formatDate(getDateForFollowupOption(ActivityFollowupOption.oneBusinessDay))})`
  },
  {
    key: ActivityFollowupOption.twoBusinessDays,
    label: `In 2 Business Days (${formatDate(getDateForFollowupOption(ActivityFollowupOption.twoBusinessDays))})`
  },
  { key: ActivityFollowupOption.oneWeek, label: `In 1 Week (${formatDate(getDateForFollowupOption(ActivityFollowupOption.oneWeek))})` },
  { key: ActivityFollowupOption.twoWeeks, label: `In 2 Weeks (${formatDate(getDateForFollowupOption(ActivityFollowupOption.twoWeeks))})` },
  { key: ActivityFollowupOption.oneMonth, label: `In 1 Month (${formatDate(getDateForFollowupOption(ActivityFollowupOption.oneMonth))})` },
  {
    key: ActivityFollowupOption.threeMonths,
    label: `In 3 Months (${formatDate(getDateForFollowupOption(ActivityFollowupOption.threeMonths))})`
  },
  {
    key: ActivityFollowupOption.sixMonths,
    label: `In 6 Months (${formatDate(getDateForFollowupOption(ActivityFollowupOption.sixMonths))})`
  },
  {
    key: ActivityFollowupOption.oneYear,
    label: `In 1 Year (${formatDate(getDateForFollowupOption(ActivityFollowupOption.oneYear))})`
  },
  { key: ActivityFollowupOption.custom, label: 'Custom Date' }
];

export function SelectFollowupOption(props: FieldComponentProps) {
  const additionalChangeHandler = (value: ActivityFollowupOption, setFieldValue: any) => {
    setFieldValue('followup_date', getDateForFollowupOption(value));
  };

  return <SelectOption {...props} options={options} additionalChangeHandler={additionalChangeHandler} />;
}
