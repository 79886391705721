import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import Button from '@mui/material/Button';
import { CreoneField } from 'components/form/basic/creone_field';

import { useFormikContext } from 'formik';
import _ from 'lodash';
import { LightTooltip } from 'pages/deal/components/InfoTooltip';
import { followupIncomingChanges } from 'pages/prospects/constants/activity';
import React, { useMemo } from 'react';
import { startEditingRecord } from 'store/reducers/record';
import { ProspectStatusEnum } from 'types/api/deal/enum';
import { FormIdentifier } from 'types/record';
import { FieldComponentProps } from 'types/standardForm';

export function ProspectButton(props: FieldComponentProps) {
  const { getFieldProps, values, setFieldValue } = useFormikContext();

  const { value } = getFieldProps(props.fieldName);
  const isNewForm = useMemo(() => !_.get(values, 'id'), [values]);

  const shouldRequireFollowUpOnConversion = useMemo(() => _.get(values, 'upcoming_activity_count', 0) === 0, [values]);
  const convertToProspectIncomingChanges = useMemo(
    () =>
      shouldRequireFollowUpOnConversion
        ? {
            ...followupIncomingChanges,
            is_prospect: true,
            status: ProspectStatusEnum.open
          }
        : {
            is_prospect: true,
            status: ProspectStatusEnum.open
          },
    [shouldRequireFollowUpOnConversion]
  );

  return (
    <CreoneField {...props}>
      {!!value ? (
        <LightTooltip title={isNewForm ? '' : 'Remove from Prospects'}>
          <Button
            variant="outlined"
            color={'primary'}
            startIcon={<StarIcon style={{ fontSize: 24, color: 'gold' }} />}
            {...getFieldProps(props.fieldName)}
            onClick={() => !isNewForm && setFieldValue(props.fieldName, false)}
            size={'small'}
            sx={{ height: '34.25px' }}
          >
            Prospect
          </Button>
        </LightTooltip>
      ) : (
        <LightTooltip title={isNewForm ? 'Save the Contact before converting to a Prospect' : 'Convert to Prospect'}>
          <Button
            variant="outlined"
            color={'primary'}
            startIcon={<StarBorderOutlinedIcon style={{ fontSize: 24 }} />}
            {...getFieldProps(props.fieldName)}
            onClick={() => {
              // Open edit dialog
              startEditingRecord(_.get(values, 'id', null), FormIdentifier.ConvertToProspectForm, convertToProspectIncomingChanges);
            }}
            disabled={isNewForm}
            size={'small'}
            sx={{ height: '34.25px' }}
          >
            Convert to Prospect
          </Button>
        </LightTooltip>
      )}
    </CreoneField>
  );
}
