import _ from 'lodash';
import { DealQueryKeys } from 'pages/deal/constants/dealQueryKeys';
import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { UserPreferences } from 'types/api/deal/user_preferences';
import { dealService } from 'utils/axios';

export enum UserPreferencesKey {
  contact_table_columns = 'contact_table_columns',
  prospect_table_columns = 'prospect_table_columns',
  deal_table_columns = 'deal_table_columns',
  prospect_dashboard_scope = 'prospect_dashboard_scope',
  deal_dashboard_scope = 'deal_dashboard_scope',
  deal_data_weighted = 'deal_data_weighted'
}

// Initialize context
const DealUserPreferencesContext = createContext<UserPreferences | null>(null);

// Create context provider
export function DealUserPreferencesProvider({ children }: { children: React.ReactNode }) {
  // Get org preferences
  const { data: dealUserPreferences = null } = useQuery<UserPreferences>({
    queryKey: [DealQueryKeys.dealUserPreferences],
    queryFn: () => {
      return dealService.get('/user_preferences/').then((res) => {
        if (res.data) {
          return res.data;
        }
      });
    },
    retry: 3,
    staleTime: Infinity
  });

  return <DealUserPreferencesContext.Provider value={dealUserPreferences}>{children}</DealUserPreferencesContext.Provider>;
}

// Custom hook to use this context
export function useDealUserPreferences(key: UserPreferencesKey) {
  const context = useContext(DealUserPreferencesContext);
  return _.get(context, key, null);
}

// Helper functions
export const updateUserPreferencesAPI = (data: object) => {
  // Replace this with your actual API endpoint
  return dealService.put(`/user_preferences/`, data).then((res) => {
    return res.data;
  });
};
