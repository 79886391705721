import React, { createContext, useContext, useState, ReactNode, FC } from 'react';

interface EditingContextType {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditingContext = createContext<EditingContextType | undefined>(undefined);

interface EditingProviderProps {
  children: ReactNode;
}

export const EditingProvider: FC<EditingProviderProps> = ({ children }) => {
  const [isEditing, setIsEditing] = useState(false);

  return <EditingContext.Provider value={{ isEditing, setIsEditing }}>{children}</EditingContext.Provider>;
};

export const useEditing = (): EditingContextType => {
  const context = useContext(EditingContext);
  if (!context) {
    // Provide a default value if context is not available
    return {
      isEditing: true,
      setIsEditing: () => {} // Empty function as a default
    };
  }
  return context;
};
