import _ from 'lodash';
import { BaseState, Keyed } from 'types/common';
import { createSlice, PayloadAction, Slice, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { DealFilter } from 'types/navigation/dealNavigation';
import { PermissionsCreate } from 'types/permissions';
import { axiosUserServices } from 'utils/axios';
import { openNotification } from 'utils/notistack';
import { dispatch } from '../index';

export const createBaseSlice = <T, Reducers extends SliceCaseReducers<BaseState<T>>>({
  name = '',
  initialState,
  reducers
}: {
  name: string;
  initialState: BaseState<T>;
  reducers: ValidateSliceCaseReducers<BaseState<T>, Reducers>;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      /**
       * Note from Redux Toolkit:
       * If you want to write to values of the state that depend on the generic
       * (in this case: `state.data`, which is T), you might need to specify the
       * State type manually here, as it defaults to `Draft<BaseState<T>>`,
       * which can sometimes be problematic with yet-unresolved generics.
       * This is a general problem when working with immer's Draft type and generics.
       */
      // Start Load
      startLoad(state) {
        state.loading = true;
        state.loadError = null;
      },
      // Load Success
      loadSuccess: {
        reducer(state: BaseState<T>, action: PayloadAction<T, string, { replace: boolean }>) {
          state.loading = false;
          state.data = action.meta.replace ? action.payload : _.merge({}, state.data, action.payload);
        },
        prepare(payload: Keyed<T>, replace: boolean = true) {
          return { payload, meta: { replace } };
        }
      },
      // Load Failure
      loadFailure(state: BaseState<T>, action: PayloadAction<string>) {
        state.loading = false;
        state.loadError = action.payload;
      },
      // Select
      select(state: BaseState<T>, action: PayloadAction<number>) {
        state.current = action.payload;
      },
      // Unselect
      unselect(state: BaseState<T>, action: PayloadAction<string>) {
        state.current = _.omit(state.current, action.payload);
      },
      // Start Edit
      startEdit(state: BaseState<T>, action: PayloadAction<number | null>) {
        state.editing = true;
        state.current = action.payload;
      },
      // Stop Edit
      stopEdit(state: BaseState<T>) {
        state.editing = false;
        state.current = {};
      },
      // Stop Edit
      stopModalEdit(state: BaseState<T>) {
        state.editing = false;
      },
      // Start View
      startView(state: BaseState<T>, action: PayloadAction<DealFilter>) {
        state.viewing = true;
        state.current = action.payload;
      },
      // Stop View
      stopView(state: BaseState<T>) {
        state.viewing = false;
        state.current = {};
      },
      // Start Save
      startSave(state: BaseState<T>) {
        state.saving = true;
        state.saveError = null;
      },
      // Save Success
      saveSuccess: {
        reducer(state: BaseState<T>, action: PayloadAction<T, string, { replace: boolean }>) {
          state.saving = false;
          state.data = action.meta.replace ? action.payload : _.merge({}, state.data, action.payload);
        },
        prepare: (payload: any, replace = false) => {
          return { payload, meta: { replace } };
        }
      },
      saveIdSuccess: {
        reducer(state: BaseState<T>, action: PayloadAction<{ path: string[]; data: any }, string, { replace: boolean }>) {
          state.saving = false;
          // @ts-ignore
          state.data = _.setWith(state.data, action.payload.path, action.payload.data, Object);
          // state.data = action.meta.replace ? action.payload : _.merge({}, state.data, action.payload);
        },
        prepare: (payload: any, replace = false) => {
          return { payload, meta: { replace } };
        }
      },
      // Save Failure
      saveFailure(state: BaseState<T>, action: PayloadAction<string>) {
        state.saving = false;
        state.saveError = action.payload;
      },
      // Start Delete
      startDelete(state: BaseState<T>) {
        state.deleting = true;
        state.deleteError = null;
      },
      // Delete Success
      deleteSuccess(state: BaseState<T>, action: PayloadAction<number | string>) {
        state.deleting = false;
        _.unset(state.data, action.payload);
      },
      // Delete Failure
      deleteFailure(state: BaseState<T>, action: PayloadAction<string>) {
        state.deleting = false;
        state.deleteError = action.payload;
      },
      ...reducers
    }
  });
};

export interface BaseAPIOptions {
  select: (item: any) => void;
  unselect: (item: any) => void;
  startEdit: () => void;
  stopEdit: () => void;
  get: () => void;
  getById: (id: number) => void;
  create: (data: PermissionsCreate) => void;
  update: (id: string, data: PermissionsCreate) => void;
  remove: (id: number) => void;
}

export const base_api = <T,>(
  slice: Slice,
  base_path: string,
  replace: boolean = true,
  object_name: string,
  path: string[] = [],
  additional_functions: T = {} as T
): T & BaseAPIOptions => ({
  select: (item: any) => {
    dispatch(slice.actions.select(item));
  },
  unselect: (item: any) => {
    // @ts-ignore
    dispatch(slice.actions.unselect(item));
  },
  startEdit: () => {
    dispatch(slice.actions.startEdit(null));
  },
  stopEdit: () => {
    // @ts-ignore
    dispatch(slice.actions.stopEdit());
  },
  get: () => {
    axiosUserServices
      .get(`${base_path}/`)
      .then((response) => {
        if (response.data.error) throw response.data.error;
        const data = !_.isEmpty(path) ? _.set({}, path, response.data.data) : response.data.data;
        // @ts-ignore
        dispatch(slice.actions.loadSuccess(data, replace));
      })
      .catch((error) => {
        dispatch(slice.actions.loadFailure(error));
      });
  },
  getById: (id: number) => {
    axiosUserServices
      .get(`${base_path}/${id}`)
      .then((response) => {
        if (response.data.error) throw response.data.error;
        const data = _.set({}, path, response.data.data);
        // @ts-ignore
        dispatch(slice.actions.loadSuccess(data, replace));
      })
      .catch((error) => {
        dispatch(slice.actions.loadFailure(error));
      });
  },
  create: (data: PermissionsCreate) => {
    axiosUserServices
      .post(`${base_path}/`, data)
      .then((response) => {
        if (response.data.error) throw response.data.error;
        dispatch(slice.actions.saveSuccess(_.setWith({}, path, response.data.data, Object)));
        // @ts-ignore
        dispatch(slice.actions.stopEdit());
        dispatch(openSuccessNotification(`${_.capitalize(object_name)} created.`));
      })
      .catch((error) => {
        dispatch(slice.actions.saveFailure(error));
        dispatch(openErrorNotification(`Error creating ${object_name}.`));
      });
  },
  update: (id: string, data: PermissionsCreate) => {
    axiosUserServices
      .put(`${base_path}/${id}/`, data)
      .then((response) => {
        if (response.data.error) throw response.data.error;
        dispatch(slice.actions.saveIdSuccess({ path: _.concat(path, [id]), data: response.data.data }));
        // @ts-ignore
        dispatch(slice.actions.stopEdit());
        dispatch(openSuccessNotification(`${_.capitalize(object_name)} updated.`));
      })
      .catch((error) => {
        dispatch(slice.actions.saveFailure(error));
        dispatch(openErrorNotification(`Error updating ${object_name}.`));
      });
  },
  remove: (id: number) => {
    axiosUserServices
      .delete(`${base_path}/${id}/`)
      .then((response) => {
        if (response.data.error) throw response.data.error;
        dispatch(slice.actions.deleteSuccess(response.data.data));
        // @ts-ignore
        dispatch(slice.actions.stopEdit());
        dispatch(openSuccessNotification(`${_.capitalize(object_name)} deleted.`));
      })
      .catch((error) => {
        dispatch(slice.actions.deleteFailure(error));
        dispatch(openErrorNotification(`Error deleting ${object_name}.`));
      });
  },
  ...additional_functions
});

export function openSuccessNotification(message: string) {
  openNotification(message, 'success');

  return () => {};
}

export function openErrorNotification(message: string) {
  openNotification(message, 'error');

  return () => {};
}

export function openInfoNotification(message: string) {
  openNotification(message, 'info');

  return () => {};
}
