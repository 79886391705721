import { InputAdornment } from '@mui/material';
import { CurrencyTextField, PercentTextField } from 'components/form/NumberFormatTextField';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { FieldComponentProps } from 'types/standardForm';

interface CurrencyPercentInputProps extends FieldComponentProps {
  showLabel?: boolean;
  percentValue: number | null;
  amountValue: number | null;
  setPercentValue: (x: number | undefined) => void;
  setAmountValue: (x: number | undefined) => void;
  disabled: boolean;
  showPercentage: boolean;
  allowNegative?: boolean;
}

export function CurrencyPercentInputV2({
  fieldName,
  disabled = false,
  percentValue,
  amountValue,
  setPercentValue,
  setAmountValue,
  showPercentage,
  allowNegative = false
}: CurrencyPercentInputProps) {
  const { setFieldTouched } = useFormikContext();

  return (
    <Fragment>
      {showPercentage ? (
        <NumberFormat
          name={`${fieldName}_percent`}
          key={`${fieldName}_percent`}
          id={`${fieldName}_percent`}
          disabled={disabled}
          size={'medium'}
          fullWidth
          thousandSeparator={false}
          decimalScale={2}
          fixedDecimalScale={false}
          placeholder={'0'}
          value={_.isNumber(percentValue) ? percentValue * 100 : percentValue}
          onValueChange={(val, sourceInfo) => {
            if (sourceInfo.source === 'event') {
              setPercentValue(_.isNumber(val.floatValue) ? val.floatValue / 100 : val.floatValue);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment id={`${fieldName}-percent`} position="end">
                %
              </InputAdornment>
            ),
            onBlur: () => setFieldTouched(`${fieldName}_percent`, true)
          }}
          customInput={PercentTextField}
          allowNegative={allowNegative}
        />
      ) : (
        <NumberFormat
          name={`${fieldName}_amount`}
          key={`${fieldName}_amount`}
          id={`${fieldName}_amount`}
          disabled={disabled}
          size={'medium'}
          fullWidth
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          placeholder={'0.00'}
          value={amountValue}
          onValueChange={(val, sourceInfo) => {
            setAmountValue(val.floatValue);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment id={`${fieldName}-dollar`} position="start">
                $
              </InputAdornment>
            ),
            onBlur: () => setFieldTouched(`${fieldName}_amount`, true)
          }}
          customInput={CurrencyTextField}
          allowNegative={allowNegative}
        />
      )}
    </Fragment>
  );
}
