import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { dispatch } from 'store';
import { DealCommissionFilters } from 'types/dealCommissionGoal';

const initialState: { initial: DealCommissionFilters; current: DealCommissionFilters } = {
  initial: {
    team: '',
    broker: '',
    year: dayjs().year()
  },
  current: {
    team: '',
    broker: '',
    year: dayjs().year()
  }
};

const dealCommissionFilters = createSlice({
  name: 'dealCommissionFilters',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.current = action.payload;
    },
    setInitialFilters(state, action) {
      state.initial = action.payload;
      state.current = action.payload;
    },
    resetFilters(state) {
      state.current = state.initial;
    }
  }
});

export default dealCommissionFilters.reducer;

export const setFilters = (filters: DealCommissionFilters) => dispatch(dealCommissionFilters.actions.setFilters(filters));
export const setInitialFilters = (filters: DealCommissionFilters) => dispatch(dealCommissionFilters.actions.setInitialFilters(filters));
export const resetFilters = () => dispatch(dealCommissionFilters.actions.resetFilters());
