//assets
import { CloseOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';

//material
// project import
// third-party
import { enqueueSnackbar, SnackbarKey, useSnackbar, VariantType } from 'notistack';

const CloseSnackbarButton = (props: { key: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const handleClose = () => closeSnackbar(props.key);

  return (
    <Button color="secondary" sx={{ fontWeight: 700, color: 'white', minWidth: 0 }} variant="text" onClick={handleClose}>
      <CloseOutlined />
    </Button>
  );
};

export const openNotification = (
  message: string,
  variant: VariantType = 'success',
  actionText?: string,
  actionFunction?: (key?: SnackbarKey) => void
) => {
  // Enqueue notification with action
  enqueueSnackbar(message, {
    action: (key) => (
      <>
        {!!actionText && typeof actionFunction === 'function' && (
          <Button color="secondary" sx={{ fontWeight: 600, color: 'white' }} variant="text" onClick={(e) => actionFunction(key)}>
            {actionText}
          </Button>
        )}
        <CloseSnackbarButton key={key} />
      </>
    ),
    variant: variant,
    hideIconVariant: true
  });
};
