// third-party
import { AppstoreOutlined, StarFilled, UserOutlined, LineChartOutlined } from '@ant-design/icons';
// assets
import { FormattedMessage } from 'react-intl';
import { Product } from 'types/license';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
const prospects: NavItemType = {
  id: 'group-prospects',
  title: <FormattedMessage id="prospects" />,
  type: 'group',
  products: [Product.prospects],
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dv-dashboard" />,
      type: 'item',
      url: '/prospects/dashboard',
      icon: AppstoreOutlined
    },
    {
      id: 'insights',
      title: <FormattedMessage id="insights" />,
      type: 'item',
      url: '/prospects/insights',
      icon: LineChartOutlined
    },
    {
      id: 'prospects',
      title: <FormattedMessage id="prospects" />,
      type: 'item',
      url: '/prospects/overview',
      icon: StarFilled
    },
    {
      id: 'contacts',
      title: <FormattedMessage id="contacts" />,
      type: 'item',
      url: '/prospects/contacts',
      icon: UserOutlined
    }
  ]
};

export default prospects;
