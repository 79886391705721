// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';
import { To } from 'history';
import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getDefaultRoute } from 'utils/getDefaultRoute';
import LogoIcon from './LogoIcon';

// project import
import Logo from './LogoMain';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const { user } = useAuth();
  const defaultPath = useMemo(() => getDefaultRoute(user), [user]);

  return (
    <ButtonBase disableRipple component={Link} to={!to ? defaultPath : to} sx={sx}>
      {isIcon ? <LogoIcon /> : <Logo />}
    </ButtonBase>
  );
};

export default LogoSection;
