// src/components/DealPreviewDrawer.tsx

// material-ui
// assets
import * as Sentry from '@sentry/react';
import DealPreviewFromContext from 'components/form/forms/sections/DealPreviewFromContext';
// material-ui
import { StandardForm } from 'components/form/standard/StandardForm';

// project import
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import { Deal } from 'types/deal';
import { StandardFormProps } from 'types/record';
import { updateDealAsync } from 'utils/deal';

// third party

export const DealPreviewForm = ({ record, incomingChanges, onCancel, successCallback, open }: StandardFormProps<Deal>) => {
  const navigate = useNavigate();

  const updateFn = async (id: number | string, deal: Partial<Deal>) => {
    try {
      const record = await updateDealAsync(id, deal, navigate);
      if (typeof successCallback === 'function') successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to update Deal notes.');
    }
  };

  const { notes_plaintext } = DealMetadata;
  const NotesModalMetadata = { notes_plaintext };

  return (
    <StandardForm<Partial<Deal>>
      metadata={NotesModalMetadata}
      record={record}
      updateFn={updateFn}
      displayName={'Notes'}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      isDialog={false}
    >
      {() => <DealPreviewFromContext />}
    </StandardForm>
  );
};
