import { TextFieldString } from 'components/form/TextFieldString';
import _ from 'lodash';
import { TeamLookupField } from 'pages/deal/components/LookupField';
import { parseRecordAsSimpleOption, simpleOptionSubmitHandler } from 'pages/deal/utils/deal_form';
import { HierarchyRead } from 'types/api/user_management/dbRead';
import { ObjectMetadata } from 'types/standardForm';
import * as Yup from 'yup';

export const TeamMetadata: ObjectMetadata = {
  name: {
    fieldName: 'name',
    displayName: 'Team Name',
    initialValue: '',
    _schema: Yup.string().required(`Team Name is required.`).max(255, 'Team Name must be less than 256 characters.'),
    component: TextFieldString
  },
  parent_team: {
    fieldName: 'parent_team',
    displayName: 'Parent Team',
    initialValue: null,
    _schema: Yup.object()
      .nullable()
      .when('is_root', { is: (x: boolean | undefined) => !x, then: (schema) => schema.required('Parent Team is required.') }),
    disabled: (values) => _.get(values, 'is_root', false),
    component: TeamLookupField,
    submitHandler: simpleOptionSubmitHandler,
    fieldCorrespondence: 'parent_team_id',
    loadHandler: (x: HierarchyRead | null) => parseRecordAsSimpleOption<HierarchyRead>(x, 'name')
  }
};
