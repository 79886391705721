import { axiosUserServices } from 'utils/axios';
import { createBaseSlice } from 'store/reducers/common';
import { dispatch } from '../index';
import { BaseState, Keyed } from 'types/common';
import _ from 'lodash';
import { Plan } from 'types/billing';

type SubscriptionItem = {
  id: string;
  price: {
    id: string;
    product: string;
  };
};

type Stripe = {
  customer_id: string;
  subscription_id: string;
  subscription_items: Keyed<SubscriptionItem>;
  plans: Plan[];
  discount: {
    start: number | null;
    end: number | null;
    coupon: {
      amount_off: number;
      duration_in_months: number;
      name: string;
      percent_off: number;
    };
  } | null;
};
const initialState: BaseState<Stripe> = {
  loading: false,
  editing: false,
  saving: false,
  deleting: false,
  viewing: false,
  loadError: null,
  saveError: null,
  deleteError: null,
  data: {
    customer_id: '',
    subscription_id: '',
    subscription_items: {},
    discount: null,
    plans: []
  },
  current: null
};

const slice = createBaseSlice({
  name: 'billing',
  initialState,
  reducers: {
    updateSubscription: (state, action) => {
      state.data = _.merge(state.data, action.payload);
    },
    getPlans: (state, action) => {
      state.data.plans = _.merge(state.data.plans, action.payload);
    }
  }
});

// Reducer
export default slice.reducer;

export const checkout = async (
  price_id: string,
  quantity: number = 1,
  token_header: string,
  trial: boolean = false,
  sso: boolean = false
) => {
  const res = await axiosUserServices.post(
    `/account/checkout`,
    { price_id, quantity, trial, sso },
    { headers: { Authorization: `BEARER ${token_header}` } }
  );
  window.location.href = res.data.url;
};

export const update_subscription = ({
  quantity,
  price_id,
  additional_quantity
}: {
  quantity?: number;
  additional_quantity?: number;
  price_id?: string;
}) => {
  return axiosUserServices.put(`/account/subscription`, { quantity, price_id, additional_quantity }).then((res) => {
    dispatch(slice.actions.updateSubscription(res.data.data));
  });
};

// use axiosUserServices to get /account/subscription
// update subscription data in redux stripe store
export const get_subscription = () => {
  axiosUserServices.get(`/account/subscription`).then((response) => {
    dispatch(slice.actions.updateSubscription(response.data.data));
  });
};

export const getPlans = (app_products: string[]) => {
  axiosUserServices.post(`/account/pricing`, { plans: app_products }).then((response) => {
    dispatch(slice.actions.getPlans(response.data.data));
  });
};

export const portalSuccess = (session_id: string) => {
  axiosUserServices.post(`/account/portal/success/`, {}, { params: { session_id } });
};
