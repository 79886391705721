import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { SizeType } from 'types/deal';
import { colorRaisinBlack, formatWholeNumber } from '../utils/reporting';

export const AggFooterSize = (props: { size_dict: Record<string, number> }) => {
  const { size_dict } = props;

  const squareFeet = _.get(size_dict, SizeType.square_feet);
  const units = _.get(size_dict, SizeType.units);
  const acres = _.get(size_dict, SizeType.acres);

  return (
    <Typography variant="subtitle1">
      <Stack color={colorRaisinBlack}>
        {!!squareFeet && (
          <Stack direction="row" spacing={1.5} alignItems="center" justifyContent="space-between">
            <Typography variant={'caption'}>Sq. Ft.</Typography> <Box>{formatWholeNumber(squareFeet)}</Box>
          </Stack>
        )}
        {!!units && (
          <Stack direction="row" spacing={1.5} alignItems="center" justifyContent="space-between">
            <Typography variant={'caption'}>Units</Typography> <Box>{formatWholeNumber(units)}</Box>
          </Stack>
        )}
        {!!acres && (
          <Stack direction="row" spacing={1.5} alignItems="center" justifyContent="space-between">
            <Typography variant={'caption'}>Acres</Typography> <Box>{formatWholeNumber(acres)}</Box>
          </Stack>
        )}
      </Stack>
    </Typography>
  );
};
