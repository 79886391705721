// third-party
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

// project imports
import { axiosLenderMetricsServices } from 'utils/axios';
import { dispatch } from '../index';

// types
import { LenderMetricsStateProps } from 'types/lenderMetric';

// ----------------------------------------------------------------------

const initialState: LenderMetricsStateProps = {
  error: null,
  lenderMetrics: {
    lenders: {
      total: null,
      monthly: []
    },
    notifications: {
      total: null,
      monthly: []
    },
    states: {
      total: null,
      monthly: []
    },
    states_map: [],
    lender_types: [
      {
        lender_type: null,
        count: null
      }
    ],
    loan_products: [
      {
        loan_product: null,
        count: null
      }
    ]
  },
  quoteMetrics: {
    quotes: {
      total: null,
      monthly: []
    },
    dollars_sent: {
      total: null,
      monthly: []
    },
    quotes_received: {
      total: null,
      monthly: []
    },
    avg_quote_amount_received: {
      total: null,
      monthly: []
    },
    avg_ltv: {
      total: null,
      monthly: []
    },
    dollars_received: {
      total: null,
      monthly: []
    },
    quoted_states: [
      {
        state: null,
        count: null
      }
    ],
    quoted_asset_types: [
      {
        asset_type: null,
        count: null
      }
    ],
    quoted_lender_types: [
      {
        lender_type: null,
        count: null
      }
    ],
    top_responding_lenders: [
      {
        lender_name: null,
        count: null
      }
    ]
  },
  loadingLenderMetrics: false,
  loadingQuoteMetrics: false
};

const lenderMetric = createSlice({
  name: 'lenderMetric',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getLenderMetricsSuccess(state, action) {
      state.lenderMetrics = action.payload;
      state.loadingLenderMetrics = true;
    },

    getQuoteMetricsSuccess(state, action) {
      state.quoteMetrics = action.payload;
      state.loadingQuoteMetrics = true;
    }
  }
});

export default lenderMetric.reducer;

// ----------------------------------------------------------------------

export function getLenderMetrics() {
  axiosLenderMetricsServices
    .get(`/lenders`)
    .then((response) => {
      dispatch(lenderMetric.actions.getLenderMetricsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderMetric.actions.hasError(error));
    });
}

export function getQuoteMetrics() {
  axiosLenderMetricsServices
    .get(`/quotes`)
    .then((response) => {
      dispatch(lenderMetric.actions.getQuoteMetricsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderMetric.actions.hasError(error));
    });
}
