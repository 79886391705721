import { ConvertToDealButton } from 'components/form/ConvertToDealButton';
import { DatePicker } from 'components/form/DatePicker';
import OwnerField from 'components/form/OwnerField';
import { ProspectButton } from 'components/form/ProspectButton';
import { SelectOption } from 'components/form/SelectOption';
import {
  formatDateForDisplay,
  formatSimpleOptionForDisplay,
  formatSimpleOptionForDisplayMultiple,
  formatStringForDisplay
} from 'components/form/standard/utils/formatting';
import { userOptionLoadHandler } from 'components/form/standard/utils/metadata';
import { TextFieldString, TextFieldStringMultiline } from 'components/form/TextFieldString';
import { TextFieldTwoDecimal } from 'components/form/TextFieldWholeNumber';
import { useGetCurrentUserAsOption } from 'hooks/useGetUserAsOption';
import _ from 'lodash';
import { CompanyLookupField, OptionLookupFieldMultiple, SimpleOptionLookupMultiple } from 'pages/deal/components/LookupField';
import OpenContactInGoogleButton from 'pages/deal/components/OpenContactInGoogleButton';
import { DealOptionAutocompleteNew } from 'pages/deal/components/PlaintextAutocomplete';
import { SIZE_TYPE_OPTIONS, sizeTypeDisplay } from 'pages/deal/constants/deal_enums';
import { PROSPECT_STATUS_DISPLAY_NAMES, PROSPECT_TYPE_OPTIONS } from 'pages/deal/constants/prospect';
import {
  dateSubmitHandler,
  parseCompanyAsSimpleOption,
  parseDealOptionAsSimpleOptionMultiple,
  parseDealOptionAsString,
  simpleOptionSubmitHandler,
  simpleOptionSubmitHandlerMultiple,
  submitTwoDecimal
} from 'pages/deal/utils/deal_form';
import { formatWholeNumber } from 'pages/deal/utils/reporting';
import { ProspectStatusEnum } from 'types/api/deal/enum';
import { SimpleOption } from 'types/api/deal/form';
import { DealType, SizeType } from 'types/deal';
import { ObjectMetadata } from 'types/standardForm';
import * as Yup from 'yup';

export const ProspectMetadataSubset: ObjectMetadata = {
  _convert_to_deal: {
    fieldName: '_convert_to_deal',
    displayName: '',
    initialValue: false,
    _schema: Yup.boolean(),
    virtual: true,
    component: ConvertToDealButton
  },
  _open_in_google: {
    fieldName: '_open_in_google',
    displayName: '',
    initialValue: false,
    _schema: Yup.boolean(),
    virtual: true,
    component: OpenContactInGoogleButton
  },
  source_type: {
    fieldName: 'source_type',
    displayName: 'Prospect Source',
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'source_type_name'
  },
  submarkets: {
    fieldName: 'submarkets',
    displayName: 'Submarket',
    initialValue: [],
    _schema: Yup.array(),
    component: OptionLookupFieldMultiple,
    submitHandler: simpleOptionSubmitHandlerMultiple,
    loadHandler: parseDealOptionAsSimpleOptionMultiple,
    fieldCorrespondence: 'submarket_ids',
    formatForDisplay: formatSimpleOptionForDisplayMultiple
  },
  client_industry: {
    fieldName: 'client_industry',
    displayName: 'Industry',
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'client_industry_name'
  },
  size_est: {
    fieldName: 'size_est',
    displayName: 'Estimated Size',
    initialValue: null,
    _schema: Yup.number().nullable().min(0, 'Estimated Size must be positive.'),
    component: TextFieldTwoDecimal,
    submitHandler: submitTwoDecimal,
    formatForDisplay: formatWholeNumber
  },
  size_type: {
    fieldName: 'size_type',
    displayName: 'Size Type',
    options: SIZE_TYPE_OPTIONS,
    initialValue: SizeType.square_feet,
    _schema: Yup.string().nullable(),
    component: SelectOption,
    formatForDisplay: (x) => _.get(sizeTypeDisplay, x, '')
  },
  lease_expiration: {
    fieldName: 'lease_expiration',
    displayName: 'Current Lease Expiration',
    initialValue: null,
    visibility: (values) => _.some(_.get(values, 'prospect_types', []), { key: DealType.tenant_representation }),
    _schema: Yup.date().nullable().typeError(`Lease Expiration must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay
  },
  is_prospect: {
    fieldName: 'is_prospect',
    displayName: '',
    initialValue: false,
    _schema: Yup.boolean(),
    component: ProspectButton
  },
  asset_types: {
    fieldName: 'asset_types',
    displayName: 'Asset Type',
    initialValue: [],
    _schema: Yup.array(),
    component: OptionLookupFieldMultiple,
    submitHandler: simpleOptionSubmitHandlerMultiple,
    loadHandler: parseDealOptionAsSimpleOptionMultiple,
    fieldCorrespondence: 'asset_type_ids',
    formatForDisplay: formatSimpleOptionForDisplayMultiple
  },
  prospect_types: {
    fieldName: 'prospect_types',
    displayName: 'Prospect Type',
    options: PROSPECT_TYPE_OPTIONS,
    initialValue: [],
    _schema: Yup.array(),
    component: SimpleOptionLookupMultiple,
    submitHandler: simpleOptionSubmitHandlerMultiple,
    loadHandler: (x: DealType[] | null) => (_.isNil(x) ? [] : _.map(x, (y) => _.find(PROSPECT_TYPE_OPTIONS, { key: y }))),
    formatForDisplay: formatSimpleOptionForDisplayMultiple
  },
  conversion_date: {
    fieldName: 'conversion_date',
    displayName: 'Conversion Date',
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Conversion Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler
  },
  property_names: {
    fieldName: 'property_names',
    displayName: 'Property Name',
    initialValue: [],
    _schema: Yup.array(),
    component: OptionLookupFieldMultiple,
    submitHandler: simpleOptionSubmitHandlerMultiple,
    loadHandler: parseDealOptionAsSimpleOptionMultiple,
    fieldCorrespondence: 'property_name_ids',
    formatForDisplay: formatSimpleOptionForDisplayMultiple
  }
};

export const ContactLimitedMetadata: ObjectMetadata = {
  owner: {
    fieldName: 'owner',
    displayName: 'Contact Owner',
    visibility: () => true,
    initialValue: useGetCurrentUserAsOption,
    _schema: Yup.object().required(`Contact Owner is required.`).typeError('Contact Owner is required.'),
    component: OwnerField,
    submitHandler: (x: SimpleOption) => x.key,
    loadHandler: userOptionLoadHandler,
    formatForDisplay: formatSimpleOptionForDisplay
  },
  first_name: {
    fieldName: 'first_name',
    displayName: 'First Name',
    initialValue: '',
    _schema: Yup.string().max(255, 'First Name must be less than 256 characters.').required(`First Name is required.`),
    component: TextFieldString,
    formatForDisplay: formatStringForDisplay
  },
  last_name: {
    fieldName: 'last_name',
    displayName: 'Last Name',
    initialValue: '',
    _schema: Yup.string().nullable().max(255, 'Last Name must be less than 256 characters.'),
    component: TextFieldString,
    formatForDisplay: formatStringForDisplay
  },
  status: {
    fieldName: 'status',
    displayName: 'Status',
    options: _.map(PROSPECT_STATUS_DISPLAY_NAMES, (val, idx) => ({ key: idx, label: val })),
    initialValue: ProspectStatusEnum.open,
    _schema: Yup.string().nullable(),
    component: SelectOption,
    additionalChangeHandler: (value, setFieldValue, prevValue, values) => {
      if (value === ProspectStatusEnum.converted) {
        setFieldValue('conversion_date', new Date());
      }
      if (!_.get(values, 'id') && _.get(values, 'is_followup') && [ProspectStatusEnum.converted, ProspectStatusEnum.lost].includes(value)) {
        setFieldValue('is_followup', null);
      }
    }
  }
};

export const ContactMetadata: ObjectMetadata = {
  ...ContactLimitedMetadata,
  ...ProspectMetadataSubset,
  title: {
    fieldName: 'title',
    displayName: 'Title',
    initialValue: '',
    _schema: Yup.string().max(255, 'Title must be less than 256 characters.').nullable(),
    component: TextFieldString,
    formatForDisplay: formatStringForDisplay
  },
  company_name: {
    fieldName: 'company_name',
    displayName: 'Name',
    initialValue: '',
    _schema: Yup.string().max(255, 'Company Name must be less than 256 characters.').nullable(),
    component: TextFieldString,
    formatForDisplay: formatStringForDisplay
  },
  telephone1_ext: {
    fieldName: 'telephone1_ext',
    displayName: 'Ext.',
    initialValue: '',
    _schema: Yup.string().max(10, 'Phone Ext. must not exceed 10 characters.').nullable(),
    component: TextFieldString,
    formatForDisplay: formatStringForDisplay
  },
  company: {
    fieldName: 'company',
    displayName: 'Company',
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: CompanyLookupField,
    formatForDisplay: formatSimpleOptionForDisplay,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseCompanyAsSimpleOption,
    fieldCorrespondence: 'company_id'
  },
  notes_plaintext: {
    fieldName: 'notes_plaintext',
    displayName: 'Notes',
    initialValue: '',
    _schema: Yup.string().max(10000, 'Notes must be less than 10,000 characters.').nullable(),
    component: TextFieldStringMultiline,
    formatForDisplay: formatStringForDisplay
  },
  telephone1: {
    fieldName: 'telephone1',
    displayName: 'Phone',
    initialValue: '',
    _schema: Yup.string()
      .nullable()
      .when(['email_address', 'telephone2'], {
        is: (email_address: string, telephone2: string) => !email_address && !telephone2,
        then: (schema) => schema.required(''),
        otherwise: (schema) => schema
      }),
    component: TextFieldString,
    formatForDisplay: formatStringForDisplay
  },
  telephone2: {
    fieldName: 'telephone2',
    displayName: 'Mobile Phone',
    initialValue: '',
    _schema: Yup.string()
      .nullable()
      .when(['telephone1', 'email_address'], {
        is: (telephone1: string, email_address: string) => !telephone1 && !email_address,
        then: (schema) => schema.required(''),
        otherwise: (schema) => schema
      }),
    component: TextFieldString,
    formatForDisplay: formatStringForDisplay
  },
  email_address: {
    fieldName: 'email_address',
    displayName: 'Email',
    initialValue: '',
    _schema: Yup.string()
      .max(255, 'Email must be less than 256 characters.')
      .nullable()
      .when(['telephone1', 'telephone2'], {
        is: (telephone1: string, telephone2: string) => !telephone1 && !telephone2,
        then: (schema) => schema.required('Either Phone, Mobile Phone, or Email is required.'),
        otherwise: (schema) => schema
      }),
    component: TextFieldString,
    formatForDisplay: formatStringForDisplay
  },
  contact_type: {
    fieldName: 'contact_type',
    displayName: 'Contact Type',
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: 'contact_type_name'
  },
  groups: {
    fieldName: 'groups',
    displayName: 'Group',
    initialValue: [],
    _schema: Yup.array(),
    component: OptionLookupFieldMultiple,
    submitHandler: simpleOptionSubmitHandlerMultiple,
    loadHandler: parseDealOptionAsSimpleOptionMultiple,
    fieldCorrespondence: 'group_ids',
    formatForDisplay: formatSimpleOptionForDisplayMultiple
  }
};
