import { useUsersDisplay } from 'contexts/UserDisplayContext';
import { FormikConfig, FormikValues } from 'formik';
import _ from 'lodash';
import { SimpleOption } from 'types/api/deal/form';
import { DealOrgPreferences } from 'types/deal';
import { FormSubmitAction, ObjectMetadata } from 'types/standardForm';
import * as Yup from 'yup';

// Custom method to generate validation schema based on metadata
export const deriveYupSchemaFromMetadata = (metadata: ObjectMetadata, dealOrgPreferences: DealOrgPreferences | null) => {
  return Yup.object().shape(
    _.reduce(
      metadata,
      (acc, value) => ({
        ...acc,
        [value?.fieldName]: typeof value?._schema === 'function' ? value._schema(dealOrgPreferences) : value?._schema
      }),
      {}
    ),
    [
      ['email_address', 'telephone1'],
      ['email_address', 'telephone2'],
      ['telephone1', 'telephone2']
    ]
  );
};

// Custom hook to generate initial values based on metadata
export const useInitialValues = <T extends Record<string, any>>(
  metadata: ObjectMetadata,
  record: T | null,
  incomingChanges: Partial<T> = {}
) => {
  const userOptionsDictionary = useUsersDisplay();

  // Create a new record with default/baseline values for fields
  const newRecord = _.reduce(
    metadata,
    (acc, value) => ({
      ...acc,
      [value?.fieldName]: typeof value?.initialValue === 'function' ? _.invoke(value, 'initialValue') : value?.initialValue
    }),
    {}
  );

  if (record) {
    const uiRecordObj = _.reduce(
      metadata,
      (acc, value) => {
        // Extract field name and load handler from metadata
        const { fieldName: fieldKey, loadHandler = (x: any) => x } = value;
        // Get field value via load handler. This allows any transformation needed between the api value and the UI value for the field.
        const fieldValue = loadHandler(_.get(record, value?.fieldName), userOptionsDictionary);
        return { ...acc, [fieldKey]: fieldValue };
      },
      {}
    );
    // Merge actual record values over top of defaults/baselines
    return { ...newRecord, ...record, ...uiRecordObj, ...incomingChanges };
  } else {
    return { ...newRecord, ...incomingChanges };
  }
};

export function createAsyncSubmitHandler<T extends Record<string, any>>(
  metadata: ObjectMetadata,
  record: T | null,
  submitAction: FormSubmitAction,
  updateFn: (id: number, record: Partial<T>, submitAction?: FormSubmitAction) => Promise<void>,
  createFn: (record: T, submitAction?: FormSubmitAction) => Promise<void>
) {
  return async (values: FormikValues) => {
    const newRecord: Partial<T> = _.reduce(
      _.values(metadata),
      (acc, value) => {
        const { virtual: isVirtual, fieldCorrespondence, fieldName } = value;
        // Skip fields flagged as virtual
        if (isVirtual) return acc;

        const fieldKey = fieldCorrespondence || fieldName;
        const fieldValue = _.get(value, 'submitHandler', (x: any) => x)(_.get(values, fieldName));
        return { ...acc, [fieldKey]: fieldValue };
      },
      {}
    );

    if (record?.id && !_.get(record, '_pending')) {
      await updateFn(record.id, newRecord, submitAction);
    } else {
      await createFn(newRecord as T, submitAction);
    }
  };
}

export function getSubmitHandler<T extends Record<string, any>>(
  metadata: ObjectMetadata,
  record: T | null,
  submitAction: FormSubmitAction,
  updateFn: (id: number, record: Partial<T>, submitAction?: FormSubmitAction) => Promise<void>,
  createFn: (record: T, submitAction?: FormSubmitAction) => Promise<void> = async () => {}
): FormikConfig<any>['onSubmit'] {
  const asyncSubmitHandler = createAsyncSubmitHandler(metadata, record, submitAction, updateFn, createFn);

  return async (values, { setSubmitting }) => {
    try {
      await asyncSubmitHandler(values);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };
}

export const userOptionLoadHandler = (x: number | null, userOptionsDictionary: Record<string, string>): SimpleOption | null =>
  _.isNil(x)
    ? null
    : {
        key: x,
        label: _.get(userOptionsDictionary, x, 'Unknown')
      };
