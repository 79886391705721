export const DEAL_TOOLTIP = {
  closedCommission: 'This shows the total commission for all closed deals.',
  openCommission: 'This shows the total commission for all open deals.',
  totalCommission: 'This shows the sum of closed and open deals.',
  projectedCommission:
    'The bars in this graph show a monthly breakdown of closed and open deals. The line graph shows cumulative commissions for open and closed deals across the time period. Pro tip: if there are prior months that show open deals, make sure to update so your graphs can be more accurate.',
  dealsBySourcingBroker:
    'This graph shows the count and commission amount of deals by the broker who sourced the deal. Hover over the bar to see the actual count and dollar amount.',
  dealsByExecutingBroker:
    'This graph shows the count and commission amount of deals by the broker who executed the deal. Hover over the bar to see the actual count and dollar amount.',
  dealsByTransactionType:
    'This graph shows the count and commission amount of deals transaction type. Hover over the bar to see the actual count and dollar amount.',
  dealsBySource:
    'This graph shows the count and commission amount of deals by the source of the deal. Hover over the bar to see the actual count and dollar amount.',
  downloadAsPng: 'Download as PNG',
  dealViewProFeature: 'Upgrade to Deal View Pro to access this feature',
  goalProgress: 'This chart shows the progress towards the Commission Goal, with a marker for the target value at this point in the year.',
  stageFunnel: 'This chart shows commission by Stage for a particular Deal Type.',
  outstandingCommission:
    'This chart lets you track your total commissions by paid vs. outstanding. You can use this chart to see which deals you still expect to be paid on.',
  dealsByHireToCloseDate: 'This chart shows your average hire to close date so you can gauge how long it takes to close your deals.',
  dealsByAssetType: 'This chart shows a breakdown of your deals by asset type.',
  dealsByShared: 'This chart shows a breakdown of deals that are shared with outside brokerages.',
  dealsByIndustry: 'This chart shows a breakdown of deals by industry.',
  dealsByLostReason: 'This chart shows a breakdown of lost deals by reason.',
  leaseExpirationTable: 'This table shows leases in ascending order of Lease Expiration date.'
};
