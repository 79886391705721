import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';

export type QuoteContactFilter = {
  asset_class: string | undefined;
  state_lending: string | undefined;
  lender_types: string | undefined;
  loan_amount: string | undefined;
  loan_products: string | undefined;
  search: string | undefined;
};

const initialState: { initial: QuoteContactFilter; current: QuoteContactFilter } = {
  initial: {
    asset_class: undefined,
    state_lending: undefined,
    lender_types: undefined,
    loan_amount: undefined,
    loan_products: undefined,
    search: undefined
  },
  current: {
    asset_class: undefined,
    state_lending: undefined,
    lender_types: undefined,
    loan_amount: undefined,
    loan_products: undefined,
    search: undefined
  }
};

const quoteContactFilter = createSlice({
  name: 'quoteContactFilter',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.current = action.payload;
    },
    setInitialFilters(state, action) {
      state.initial = action.payload;
      state.current = action.payload;
    },
    resetFilters(state) {
      state.current = state.initial;
    }
  }
});

export default quoteContactFilter.reducer;

export const setFilters = (filters: QuoteContactFilter) => dispatch(quoteContactFilter.actions.setFilters(filters));
export const setInitialFilters = (filters: QuoteContactFilter) => dispatch(quoteContactFilter.actions.setInitialFilters(filters));
export const resetFilters = () => dispatch(quoteContactFilter.actions.resetFilters());
