import { Box, Dialog, DialogTitle, Stack, useTheme } from '@mui/material';
import AggregateTableV2 from 'components/table/AggregateTableV2';
import { tableIdentifierMap } from 'constants/tables/tableView';
import { useUsersDisplay } from 'contexts/UserDisplayContext';
import _ from 'lodash';
import { CloseButton } from 'pages/deal/components/CloseButton';
import React, { useMemo } from 'react';
import { Row } from 'react-table';
import { useSelector } from 'store';
import { startEditingRecord } from 'store/reducers/record';
import { stopViewingRecords } from 'store/reducers/tableView';

export const TableViewModal = () => {
  const theme = useTheme();
  const { viewing, filter, tableIdentifier } = useSelector((state) => state.tableView);

  const { getColumns, loadUrl, editFormIdentifier, title } = tableIdentifierMap[tableIdentifier];

  // Get org members data
  const usersDisplay = useUsersDisplay();

  const tableColumns = useMemo(() => getColumns(theme, usersDisplay), [getColumns, theme, usersDisplay]);

  const handleClose = () => {
    stopViewingRecords();
  };

  return (
    <Dialog maxWidth="xl" fullWidth onClose={handleClose} open={viewing} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'}>
          {title}
          <CloseButton onClose={handleClose} />
        </Stack>
      </DialogTitle>
      <Box sx={{ pt: 1, pb: 4 }}>
        {viewing && (
          <AggregateTableV2<object>
            dataUrl={loadUrl}
            filterObj={filter}
            columns={tableColumns}
            initialHiddenColumns={[]}
            defaultPageSize={10}
            skipPageReset={true}
            startEditing={(row: Row<object> | null) => startEditingRecord(_.get(row, 'original.id', null), editFormIdentifier)}
            showAggregate={false}
            showHeader={false}
            dataLabel={'Activity'}
          />
        )}
      </Box>
    </Dialog>
  );
};
