import { Theme } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import { Deal } from 'types/deal';

dayjs.extend(relativeTime);

const DATE_SEPARATOR = '-';
const MONTHS_ABBR = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const formatDate = (date: Date) => {
  return `${date.toLocaleDateString('default', { year: 'numeric' })}${DATE_SEPARATOR}${date.toLocaleDateString('default', {
    month: '2-digit'
  })}`;
};

const formatChartDate = (dateStr: string) => {
  const [year, month] = _.split(dateStr, DATE_SEPARATOR);
  let returnStr = '';
  try {
    returnStr = `${MONTHS_ABBR[_.toNumber(month) - 1]} ${year.slice(-2)}`;
  } catch (e) {}

  return returnStr;
};

export const formatChartDateV2 = (year: number, month: number) => {
  let returnStr = '';
  try {
    returnStr = `${MONTHS_ABBR[_.toNumber(month) - 1]} ${`${year}`.slice(-2)}`;
  } catch (e) {}

  return returnStr;
};

export function getInflatedMaxForChart(max: number) {
  return _.ceil((max * 1.1) / 10) * 10;
}

const getOrdinalSuffix = (number: number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export function formatChartDay(year: number, month: number, day: number) {
  // Create a Date object using the provided year, month, and day
  const date = new Date(year, month - 1, day);

  // Format the date using the desired options
  const formattedDate = date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric'
  });

  // Return with English ordinal suffix
  return `${formattedDate}${getOrdinalSuffix(day)}`;
}

const generateMonthWindow = (startDate: Date, numMonths: number) => {
  let date = new Date(startDate.getTime());
  let months = [];

  for (let i = 0; i < numMonths; i++) {
    months.push(formatDate(date));
    date.setMonth(date.getMonth() + 1);
  }

  return months;
};

export function getChartWindowV2(groupedData: any, startDate: Date, monthRange: number) {
  const windowMonths = generateMonthWindow(startDate, monthRange);

  const emptyWindow = _.zipObject(
    windowMonths,
    _.map(windowMonths, () => 0)
  );

  const aggregatedWindowData = _.pickBy(groupedData, (val, key) => key in emptyWindow);
  const completeWindow = _.merge({}, emptyWindow, aggregatedWindowData);

  const unformattedKeys = Object.keys(completeWindow);
  const values = unformattedKeys.map((key) => completeWindow[key]);
  const keys = _.map(unformattedKeys, formatChartDate);

  return { keys, values };
}

export const calcCumulative = (data: number[]) => {
  return _.reduce(
    data,
    (agg: number[], n: number) => {
      return [...agg, agg.length ? agg[agg.length - 1] + n : n];
    },
    []
  );
};

export const formatWholeNumber = (val: number | null | undefined) => {
  let formatNumber = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 0
  });
  return _.isNil(val) ? '' : formatNumber.format(val);
};

export const formatTwoDecimal = (val: number | null | undefined) => {
  let formatNumber = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2
  });
  return _.isNil(val) ? '' : formatNumber.format(val);
};

export const formatWholeNumberCompact = (val: number) => {
  let formatNumber = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 0,
    notation: 'compact'
  });
  return _.isNil(val) ? '' : formatNumber.format(val);
};

export const formatCurrency = (val: number | null) => {
  let formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  });
  return _.isNil(val) ? '' : formatCurrency.format(val);
};

export const formatCurrencyCompact = (val: number) => {
  let formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    notation: 'compact'
  });
  return _.isNil(val) ? '' : formatCurrency.format(val);
};

export const formatCurrencyWholeNumber = (val: number | null | undefined) => {
  let formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });
  return _.isNil(val) ? '' : formatCurrency.format(val);
};

export function formatPercentage(percentage?: number | null | undefined): string {
  if (_.isNil(percentage)) {
    return '0%';
  }

  return `${Math.round(percentage * 100)}%`;
}

export function formatPercentageTwoDecimal(percentage?: number | null | undefined): string {
  if (_.isNil(percentage)) {
    return '0%';
  }

  let formatPercentage = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2
  });

  const amount = percentage * 100;
  return `${formatPercentage.format(amount)}%`;
}

export function calcCommission(filteredData: Deal[], commissionField: string) {
  return [_.sumBy(filteredData, commissionField), filteredData.length];
}

export const combineOpenAndClosed = (valuesOpen: number[], valuesClosed: number[]) => {
  return _.zipWith(valuesOpen, valuesClosed, (a, b) => a + b);
};

export function getChartDateRange(dateRange: any) {
  const MONTH_RANGE = 13;

  let startDate = dayjs().startOf('month').toDate();
  let monthRange = MONTH_RANGE;

  if (dateRange && dateRange.length) {
    startDate = dayjs(dateRange[0]).toDate();
    monthRange = dayjs(dateRange[1]).startOf('month').diff(dayjs(dateRange[0]).startOf('month'), 'month') + 1;
  }
  return { startDate, monthRange };
}

export const getLeaseExpirationColor = (date: Date, theme: Theme): string => {
  const now = dayjs();
  const queryDate = dayjs(date);

  if (_.isNil(date) || dayjs(queryDate).isAfter(now.add(18, 'month'))) {
    return '';
  }
  if (dayjs(queryDate).isAfter(now.add(12, 'month'))) {
    return theme.palette.primary.main;
  }
  if (dayjs(queryDate).isAfter(now.add(6, 'month'))) {
    return theme.palette.warning.dark;
  }
  return theme.palette.error.dark;
};

export const colorGunmetal = '#2D3343';
export const colorSeaGreen = '#2A8B53';
export const colorRaisinBlack = '#272727';
export const colorMaroon = '#800000';

export const colorImperialRed = '#E54A4A';
export const colorFandango = '#A33179';

export const colorRed = '#D2222D';
export const colorYellow = '#FFBF00';
export const colorGreen = '#238823';

export const radialBarBlue = '#E6F7FF';
export const radialBarBlueFilled = '#1890FF';
export const radialBarGreen = '#F6FFED';
export const radialBarGreenFilled = '#52C41A';
export const radialBarOrange = '#FFE7BA';
export const radialBarOrangeFilled = '#F56E22';

export const colorPaletteColorblind = [
  '#0173B2',
  '#DE8F05',
  '#029E73',
  '#D55E00',
  '#CC78BC',
  '#CA9161',
  '#FBAFE4',
  '#949494',
  '#ECE133',
  '#56B4E9'
];
export const colorPaletteDark = [
  '#001C7F',
  '#B1400D',
  '#12711C',
  '#8C0800',
  '#591E71',
  '#592F0D',
  '#A23582',
  '#3C3C3C',
  '#B8850A',
  '#006374'
];

export const colorPaletteDeep = [
  '#4C72B0',
  '#DD8452',
  '#55A868',
  '#C44E52',
  '#8172B3',
  '#937860',
  '#8C8C8C',
  '#64B5CD',
  '#4C72B0',
  '#DD8452',
  '#55A868',
  '#C44E52',
  '#8172B3',
  '#937860',
  '#8C8C8C',
  '#64B5CD'
];
export const colorPaletteDeep6 = ['#4C72B0', '#55A868', '#C44E52', '#8172B3', '#CCB974', '#64B5CD'];

export const getColorPaletteCategorical = (theme: Theme) => {
  return ['#298C54', '#388C8F', '#7878FF', '#B25E9C', '#F5872E', '#FFC107'];
};

export function formatDateString(value: any) {
  return value && new Date(value).toLocaleDateString('en-US');
}

export function formatDateTimeString(value: Date | null) {
  return value && dayjs(value).format('M/D/YYYY [at] h:mma');
}

export function displayRelativeDate(date: any) {
  const now = dayjs();
  const targetDate = dayjs(date);

  return targetDate.from(now);
}

export function getPercentage(val: number, total: number) {
  return total ? _.multiply(_.divide(val ?? 0, total), 100) : 0;
}

export function saveApexChart(exportChartId: string, title: string) {
  //@ts-ignore
  ApexCharts.exec(exportChartId, 'dataURI', { scale: 2 }).then(({ imgURI }) => {
    FileSaver.saveAs(imgURI, `CRE OneSource ${title} ${dayjs().format('YYYY-MM-DD')}.png`);
  });
}

export function getCommissionByMonth(commissionData: any[]) {
  return _.reduce(
    commissionData ?? [],
    (acc, value) => {
      _.set(acc, `${value['year']}-${`${value['month']}`.padStart(2, '0')}`, value['commission_amount']);
      return acc;
    },
    {}
  );
}
