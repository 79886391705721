import { Box, Dialog, DialogTitle } from '@mui/material';
import DealTable from 'components/table/DealTable';
import { useUsersDisplay } from 'contexts/UserDisplayContext';
import _ from 'lodash';
import { CloseButton } from 'pages/deal/components/CloseButton';
import { getBaseQueryOptionsDealService } from 'pages/deal/utils/api';
import { parseFiltersAsParamsObject } from 'pages/deal/utils/deal';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'store';
import { stopViewingDeals } from 'store/reducers/dealV2';
import { DealOverviewList, defaultDealsOverviewData } from 'types/dealReporting';

export const DealTableModal = () => {
  const { viewing, currentViewing, currentViewingColumns } = useSelector((state) => state.dealV2);

  // Get org members data
  const usersDisplay = useUsersDisplay();

  // Get deal data and stats
  // TODO: Move sort and sort order logic to backend
  const queryParams = useMemo(() => currentViewing && parseFiltersAsParamsObject(currentViewing), [currentViewing]);
  const queryOptions = useMemo(
    () => queryParams && getBaseQueryOptionsDealService<DealOverviewList>('/tables/overview/', queryParams),
    [queryParams]
  );

  const { data: dealsOverviewData = defaultDealsOverviewData } = useQuery<DealOverviewList>({
    ...queryOptions,
    enabled: !!queryOptions
  });

  const handleClose = () => {
    stopViewingDeals();
  };

  return (
    <Dialog maxWidth="xl" fullWidth onClose={handleClose} open={viewing} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
      <DialogTitle>
        <CloseButton onClose={handleClose} />
      </DialogTitle>
      <Box sx={{ pb: 4 }}>
        {viewing && (
          <DealTable
            data={_.get(dealsOverviewData, 'data')}
            stats={_.get(dealsOverviewData, 'stats')}
            defaultPageSize={5}
            showColumns={currentViewingColumns ?? []}
            onRowsChange={() => {}}
            showAggregate={true}
            usersDisplay={usersDisplay}
            skipPageReset={false}
            showAdd={true}
          />
        )}
      </Box>
    </Dialog>
  );
};
