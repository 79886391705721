import { InfoCircleFilled } from '@ant-design/icons';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Alert, Box, FormControl, FormHelperText, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import { CurrencyPercentInputV2 } from 'components/form/CurrencyPercentInputV2';
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import { FieldArray, FormikValues, useField, useFormikContext } from 'formik';
import _ from 'lodash';
import { UserLookupField } from 'pages/deal/components/LookupField';
import { brokerRoleDisplay } from 'pages/deal/constants/deal_enums';
import { isClosed } from 'pages/deal/utils/deal_form';
import { formatCurrency, formatPercentageTwoDecimal } from 'pages/deal/utils/reporting';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { DealStatus } from 'types/deal';
import { FieldComponentProps } from 'types/standardForm';
import { FieldLabel } from '../basic/creone_field';
import { DollarPercentToggle } from '../basic/DollarPercentToggle';
import { DealFormTooltip } from '../DealFormTooltip';
import { SelectOption } from '../SelectOption';

interface Row {
  broker_name: string;
  role: string;
  estimate_percent: number;
  actual_percent: number;
}

function getAmountValue(commission: number | undefined, value: number | undefined) {
  return _.isNumber(commission) && _.isNumber(value) ? commission * value : null;
}

const getFormattedValue = (showPercentage: boolean, commission: number, remainder: number) => {
  return showPercentage ? formatPercentageTwoDecimal(remainder) : formatCurrency(getAmountValue(commission, remainder) ?? 0);
};

interface SplitMessageProps {
  showPercentage: boolean;
  type: 'Estimated' | 'Actual';
  commission: number;
  percentSum: number;
  onClick: () => void;
}

const SplitMessage: React.FC<SplitMessageProps> = ({ showPercentage, type, commission, percentSum, onClick }) => {
  const isUnallocated = percentSum < 1;
  const formattedValue = getFormattedValue(showPercentage, commission, isUnallocated ? 1 - percentSum : percentSum - 1);
  const action = isUnallocated ? 'allocate this amount to' : 'subtract this amount from';

  return (
    <Alert color="error" icon={<InfoCircleFilled />}>
      {type} Commission is {formatCurrency(commission)}. The {type} Splits above add up to{' '}
      {formatCurrency(getAmountValue(commission, percentSum) ?? 0)}. {formattedValue} is{' '}
      <b>{isUnallocated ? 'unallocated' : 'over-allocated'}</b>.{' '}
      <Link onClick={onClick} sx={{ cursor: 'pointer' }}>
        Click here{'\u00A0'}
      </Link>
      to {action} the last person.
    </Alert>
  );
};

function CurrencyPercentInputPayment({
  fieldName,
  showPercentage,
  commission,
  disabled,
  readOnly = false
}: {
  fieldName: string;
  showPercentage: any;
  commission: any;
  disabled: boolean;
  readOnly?: boolean;
}) {
  const [field, , helpers] = useField(fieldName);
  const { value } = field;
  const { setValue } = helpers;
  const [localFieldValue, setLocalFieldValue] = useState(value);

  useEffect(() => {
    setLocalFieldValue(value);
  }, [value]);

  // Debounce field value setter
  const debouncedSetValue = useMemo(
    () =>
      _.debounce(
        (value: number | undefined | null) => {
          // Execute additional change handler if exists
          setValue(value);
        },
        400,
        { leading: false, trailing: true }
      ),
    [setValue]
  );

  return readOnly ? (
    <Stack direction="row" alignItems={'center'} sx={{ height: 1 }}>
      <Typography variant={'subtitle1'}>
        {showPercentage ? formatPercentageTwoDecimal(localFieldValue) : formatCurrency(getAmountValue(commission, localFieldValue))}
      </Typography>
    </Stack>
  ) : (
    <CurrencyPercentInputV2
      disabled={disabled}
      displayName=""
      allowNegative={true}
      fieldName={fieldName}
      percentValue={localFieldValue}
      amountValue={getAmountValue(commission, localFieldValue)}
      setPercentValue={(value) => {
        setLocalFieldValue(value);
        debouncedSetValue(value);
      }}
      setAmountValue={(value) => {
        const newValue = _.isNumber(commission) && _.isNumber(value) ? value / commission : null;
        setLocalFieldValue(newValue);
        debouncedSetValue(newValue);
      }}
      showPercentage={showPercentage}
    />
  );
}

export function BrokerPaymentCommissionInput({ estimateReadOnly = false }: { estimateReadOnly?: boolean } & FieldComponentProps) {
  const { values, submitCount, setFieldValue, validateField } = useFormikContext<FormikValues>();

  const [field, meta, helpers] = useField(DealMetadata.broker_payment_commission.fieldName);
  const { value } = field;
  const { error, touched } = meta;
  const showPercentage = _.get(values, DealMetadata.commission_split_percent_toggle.fieldName);
  const estimate_commission = _.get(values, DealMetadata.commission_est.fieldName);
  const actual_commission = _.get(values, DealMetadata.commission.fieldName);

  const role_options = useMemo(() => _.map(brokerRoleDisplay, (val, key) => ({ key, label: val })), []);

  const estimatePercentSum = useMemo(() => _.sumBy(value, 'estimate_percent'), [value]);
  const actualPercentSum = useMemo(() => _.sumBy(value, 'actual_percent'), [value]);

  const handleEvenSplitRemainder = (fieldName: 'estimate_percent' | 'actual_percent') => {
    const numBrokers = _.size(value);
    const remainder = 1 - _.sumBy(value, fieldName);

    if (numBrokers > 0) {
      const fullFieldName = `broker_payment_commission.${numBrokers - 1}.${fieldName}`;
      const currentValue = _.get(values, fullFieldName);
      setFieldValue(fullFieldName, currentValue + remainder).then(() => {
        validateField('broker_payment_commission');
      });
    }
  };

  return (
    <Box>
      <Stack spacing={5} sx={{ mb: 2 }}>
        <DollarPercentToggle fieldName={'commission_split_percent_toggle'} displayName={'Commission Split'} isVerticalLabel={true} />
      </Stack>
      <FieldArray name={'broker_payment_commission'}>
        {({ push, handleRemove }) => (
          <Grid container columnSpacing={2} rowSpacing={1} alignItems={'stretch'}>
            <Grid item xs={3}>
              <FieldLabel required={true} disabled={false} displayName={'Broker Name'} fieldName={'broker_name'} />
            </Grid>
            <Grid item xs={2}>
              <FieldLabel
                required={value.length > 0 && _.get(values, 'status') !== DealStatus.closed}
                disabled={value.length <= 1}
                displayName={'Estimated Split'}
                fieldName={'Estimated Split'}
              />
            </Grid>
            <Grid item xs={2}>
              <FieldLabel
                required={value.length > 0 && _.get(values, 'status') === DealStatus.closed}
                disabled={value.length <= 1}
                displayName={'Actual Split'}
                fieldName={'Actual Split'}
              />
            </Grid>
            <Grid item xs={3}>
              <FieldLabel required={false} disabled={false} displayName={'Role'} fieldName={'Role'} />
            </Grid>
            {value.length > 0 &&
              value.map((row: Row, index: number) => {
                return (
                  <Fragment key={index}>
                    <Grid item xs={3}>
                      <UserLookupField fieldName={`broker_payment_commission.${index}.broker_name`} showLabel={false} showError={false} />
                    </Grid>
                    <Grid item xs={2}>
                      <CurrencyPercentInputPayment
                        fieldName={`broker_payment_commission.${index}.estimate_percent`}
                        showPercentage={showPercentage}
                        commission={estimate_commission}
                        disabled={false}
                        readOnly={estimateReadOnly}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <DealFormTooltip active={!isClosed(values)} message={`Deal needs to have a "Closed" status.`}>
                        <FormControl>
                          <CurrencyPercentInputPayment
                            fieldName={`broker_payment_commission.${index}.actual_percent`}
                            showPercentage={showPercentage}
                            commission={actual_commission}
                            disabled={!isClosed(values)}
                          />
                        </FormControl>
                      </DealFormTooltip>
                    </Grid>
                    <Grid item xs={3}>
                      <SelectOption
                        options={role_options}
                        showLabel={false}
                        displayName={'Role'}
                        fieldName={`broker_payment_commission.${index}.role`}
                      />
                    </Grid>
                    <Grid item>
                      <Stack direction={'row'}>
                        {value.length > 1 && (
                          <IconButton onClick={handleRemove(index)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        )}
                        {index + 1 === value.length && (
                          <IconButton
                            onClick={() => {
                              push({
                                broker_name: null,
                                role: 'none',
                                estimate_percent: null,
                                actual_percent: null
                              });
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                  </Fragment>
                );
              })}
          </Grid>
        )}
      </FieldArray>
      {!!error &&
        touched &&
        submitCount > 0 &&
        (typeof error === 'string' ? (
          <Grid container sx={{ pt: 2 }}>
            {error === 'estimate-percent-total-100' && estimatePercentSum !== 1 && (
              <Grid item xs={10}>
                <SplitMessage
                  showPercentage={showPercentage}
                  type="Estimated"
                  commission={estimate_commission}
                  percentSum={estimatePercentSum}
                  onClick={() => handleEvenSplitRemainder('estimate_percent')}
                />
              </Grid>
            )}
            {error === 'actual-percent-total-100' && actualPercentSum !== 1 && (
              <Grid item xs={10}>
                <SplitMessage
                  showPercentage={showPercentage}
                  type="Actual"
                  commission={actual_commission}
                  percentSum={actualPercentSum}
                  onClick={() => handleEvenSplitRemainder('actual_percent')}
                />
              </Grid>
            )}
          </Grid>
        ) : (
          _.map(error, (error: any, index: number) => {
            if (_.isNil(error)) return null;

            return (
              <Stack>
                <FormHelperText error id={`record_${index}`}>
                  Error in Row {index + 1}
                </FormHelperText>
                {_.map(_.entries(error), ([key, value]) => {
                  return (
                    !!value &&
                    !!key && (
                      <FormHelperText key={key} error>
                        <>• {value}</>
                      </FormHelperText>
                    )
                  );
                })}
              </Stack>
            );
          })
        ))}
    </Box>
  );
}
