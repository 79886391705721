import _ from 'lodash';
import { AccessTokenRead } from 'types/api/user_management/access_token';

export const subscriptionExpired = (user: AccessTokenRead) => {
  const now = new Date();
  let expire = _.get(user, 'expire_date');
  if (expire) {
    let difference = expire - now.getTime() / 1000;
    return difference < 0;
  }
  return false;
};
