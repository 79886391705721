import { DialogContent, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import StandardField from 'components/form/standard/StandardField';
import { StandardForm } from 'components/form/standard/StandardForm';
import { StandardRecordFormTitle } from 'components/form/StandardRecordFormTitle';
import MainCard from 'components/MainCard';
import { TeamMetadata } from 'constants/objectMetadata/teamMetadata';
import React from 'react';
import { Hierarchy } from 'types/api/user_management/organization';
import { StandardFormProps } from 'types/record';
import { FormSubmitAction } from 'types/standardForm';
import { createTeamAsync, deleteTeamAsync, updateTeamAsync } from 'utils/team';
import * as Sentry from '@sentry/react';

export const TeamForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
  showAddAnother = true
}: StandardFormProps<Hierarchy>) => {
  const createFn = async (obj: Hierarchy, submitAction: FormSubmitAction = FormSubmitAction.save) => {
    try {
      const record = await createTeamAsync(obj);
      if (typeof successCallback === 'function') successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to create Team.');
    }
  };

  const updateFn = async (id: number | string, obj: Partial<Hierarchy>) => {
    try {
      const record = await updateTeamAsync(id, obj);
      if (typeof successCallback === 'function') successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to update Team.');
    }
  };

  const deleteFn = async (id: number | string) => {
    try {
      await deleteTeamAsync(id);
      if (typeof successCallback === 'function') successCallback();
    } catch (error) {
      Sentry.captureException('Unable to delete Team.');
    }
  };

  return (
    <StandardForm<Hierarchy>
      metadata={TeamMetadata}
      record={record}
      updateFn={updateFn}
      createFn={createFn}
      deleteFn={deleteFn}
      displayName={'Team'}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      showAddAnother={showAddAnother}
      open={open}
      maxWidth={'sm'}
      fullWidth
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <StandardRecordFormTitle recordName={'Team'} isEdit={!!record?.id} onClose={onCancel} />
          <DialogContent>
            <MainCard>
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12}>
                  <StandardField field={TeamMetadata.name} />
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={TeamMetadata.parent_team} />
                </Grid>
              </Grid>
            </MainCard>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};
