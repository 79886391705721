// material-ui
import { alpha, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - BREADCRUMBS ||============================== //

export default function Breadcrumbs(theme: Theme) {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: alpha(theme.palette.text.primary, 0.25),
          a: {
            color: theme.palette.text.primary,
            fontWeight: 600,
            textDecoration: 'none'
          }
        },
        separator: {
          marginLeft: 4,
          marginRight: 4
        }
      }
    }
  };
}
