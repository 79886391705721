import * as Sentry from '@sentry/react';
import { formIdentifierMap } from 'constants/form';
import { useCheckLicense } from 'hooks/useCheckLicense';
import _ from 'lodash';
import { getBaseQueryOptionsDealService, getBaseQueryOptionsUserService } from 'pages/deal/utils/api';

import { Fragment, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { dispatch } from 'store';
import { openErrorNotification } from 'store/reducers/common';
import { Deal } from 'types/deal';
import { DealViewRecord } from 'types/entity';
import { Product } from 'types/license';
import { FormCloseReason, FormIdentifier, RecordDetails } from 'types/record';

export const RecordEditModal = ({
  record,
  handleClose,
  isDialog = true
}: {
  record: RecordDetails;
  handleClose: (closeReason: FormCloseReason) => void;
  isDialog?: boolean;
}) => {
  const [recordPersistent, setRecordPersistent] = useState<DealViewRecord | null>(null);
  const { id, formIdentifier, incomingChanges, isQuickCreate, callbackFunction } = record;

  const isProspects = useCheckLicense([Product.prospects]);

  const formIdentifierGuarded = useMemo(() => {
    // NOTE: The below logic is what limits Deal-View-Only users to only be able to see a restricted view of Contacts
    if ([FormIdentifier.ContactForm, FormIdentifier.ContactQuickCreateForm].includes(formIdentifier) && !isProspects) {
      // Substitute restricted version of Contact form
      return FormIdentifier.ContactNameOnlyForm;
    } else {
      return formIdentifier;
    }
  }, [formIdentifier, isProspects]);

  const {
    component: RecordForm = Fragment,
    loadUrl,
    service = 'deal'
  } = useMemo(() => formIdentifierMap[formIdentifierGuarded], [formIdentifierGuarded]);

  const recordUrl = `${loadUrl}${id}`;

  const queryOptions = useMemo(
    () =>
      service === 'user'
        ? getBaseQueryOptionsUserService<DealViewRecord>(recordUrl)
        : getBaseQueryOptionsDealService<DealViewRecord>(recordUrl),
    [recordUrl, service]
  );
  const {
    data: recordRecord = null,
    isLoading,
    error
  } = useQuery<DealViewRecord>({
    ...queryOptions,
    enabled: !_.isNil(loadUrl) && !_.isNil(id)
  });

  if (error && !isLoading) {
    Sentry.captureException(error);
    dispatch(openErrorNotification('Record not found or missing permissions.'));
    // If there is an error state after all retries, pop this record from the editing stack to avoid it being a phantom member of the stack
    handleClose(FormCloseReason.Error);
  }

  useEffect(() => {
    // Persist the Records Overview Data in between loads. This is to counteract the "stale-while-revalidating" logic from react-query.
    if (!isLoading) {
      setRecordPersistent(recordRecord);
    }
  }, [recordRecord, isLoading]);

  const showModal = useMemo(
    () => !isLoading && (_.isObject(recordPersistent) || !_.isNumber(id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recordPersistent, isLoading]
  );

  const queryClient = useQueryClient();
  const successCallback = async (updatedRecord: object = {}) => {
    // A record was updated - pass the updated values to a callback function
    if (typeof callbackFunction === 'function') await callbackFunction(updatedRecord);
    // A record was updated - invalidate all cached queries
    queryClient.invalidateQueries();
    // Close the modal
    handleClose(FormCloseReason.Save);
  };

  return (
    <RecordForm
      record={recordPersistent as Deal}
      incomingChanges={incomingChanges}
      onCancel={() => handleClose(FormCloseReason.Cancel)}
      successCallback={successCallback}
      open={showModal}
      showAddAnother={isQuickCreate}
      isDialog={isDialog}
    />
  );
};
