// third-party
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

// project imports
import { axiosLenderNotificationsServices, axiosUserServices } from 'utils/axios';
import { dispatch } from '../index';
import { updateNotificationStatusOnCreate, updateNotificationStatusOnEdit } from 'store/reducers/lender';

// types
import { LenderNotificationsStateProps, LenderNotification } from 'types/lenderNotification';

// ----------------------------------------------------------------------

const initialState: LenderNotificationsStateProps = {
  error: null,
  count: 0,
  lenderNotifications: [],
  lenderNotificationRecipients: {
    org_members: [],
    current_user: {}
  }
};

const lenderNotification = createSlice({
  name: 'lenderNotification',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getLenderNotificationsSuccess(state, action) {
      state.lenderNotifications = action.payload;
      state.count = action.payload.count;
    },

    getLenderNotificationRecipients(state, action) {
      state.lenderNotificationRecipients = action.payload;
      state.count = action.payload.count;
    },

    addLenderNotificationSuccess(state, action) {
      state.lenderNotifications.push(action.payload);
      state.lenderNotifications = _.orderBy(state.lenderNotifications, ['end_date'], ['desc']);
    },

    deleteLenderNotificationSuccess(state, action) {
      const id = _.get(action.payload, 'id');
      state.lenderNotifications = _.remove(state.lenderNotifications, (notification) => notification.id != id);
    },

    updateLenderNotificationSuccess(state, action) {
      const id = _.get(action.payload, 'id');
      const idx = _.findIndex(state.lenderNotifications, { id: id });
      state.lenderNotifications[idx] = _.merge(state.lenderNotifications[idx], action.payload);
      state.lenderNotifications = _.orderBy(state.lenderNotifications, ['end_date'], ['desc']);
    }
  }
});

export default lenderNotification.reducer;

// ----------------------------------------------------------------------

export function getLenderNotifications(lenderId: number) {
  axiosLenderNotificationsServices
    .get(`/`, { params: { lender_id: lenderId } })
    .then((response) => {
      dispatch(lenderNotification.actions.getLenderNotificationsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderNotification.actions.hasError(error));
    });
}

export function getLenderNotificationRecipients() {
  axiosUserServices
    .get('/user/org_members')
    .then((response) => {
      dispatch(lenderNotification.actions.getLenderNotificationRecipients(response.data));
    })
    .catch((error) => {
      dispatch(lenderNotification.actions.hasError(error));
    });
}

export function createLenderNotification(newLenderNotification: LenderNotification) {
  axiosLenderNotificationsServices
    .post('/', newLenderNotification)
    .then((response) => {
      dispatch(lenderNotification.actions.addLenderNotificationSuccess(response.data));
      dispatch(updateNotificationStatusOnCreate(response.data));
    })
    .catch((error) => {
      dispatch(lenderNotification.actions.hasError(error));
    });
}

export function deleteLenderNotification(notificationId: number) {
  axiosLenderNotificationsServices
    .delete(`/${notificationId}`)
    .then((response) => {
      dispatch(lenderNotification.actions.deleteLenderNotificationSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderNotification.actions.hasError(error));
    });
}

export function updateLenderNotification(updateLenderNotification: any) {
  axiosLenderNotificationsServices
    .patch(`/${updateLenderNotification.id}`, updateLenderNotification)
    .then((response) => {
      dispatch(lenderNotification.actions.updateLenderNotificationSuccess(response.data));
      dispatch(updateNotificationStatusOnEdit(response.data));
    })
    .catch((error) => {
      dispatch(lenderNotification.actions.hasError(error));
    });
}
