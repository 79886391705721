import axios from 'axios';
import config from 'config';
import _ from 'lodash';

export const axiosServices = axios.create({
  baseURL: import.meta.env.BASE_URL
});

export const dealService = axios.create({
  baseURL: `${config.apiBaseUrl}/deal/api/v1`,
  withCredentials: true,
  paramsSerializer: { indexes: null }
});

export const axiosLenderServices = axios.create({
  baseURL: `${config.apiBaseUrl}/lender/api/v1/lenders`,
  withCredentials: true
});

export const axiosLenderDealServices = axios.create({
  baseURL: `${config.apiBaseUrl}/lender/api/v1/lender_deals`,
  withCredentials: true
});

export const axiosQuoteRequestServices = axios.create({
  baseURL: `${config.apiBaseUrl}/lender/api/v1/quote_requests`,
  withCredentials: true
});

export const axiosQuoteResponseServices = axios.create({
  baseURL: `${config.apiBaseUrl}/lender/api/v1/quote_responses`,
  withCredentials: true
});

export const axiosUserServices = axios.create({
  baseURL: `${config.apiBaseUrl}/user/api/v1`,
  withCredentials: true
});

export const axiosLenderContactsServices = axios.create({
  baseURL: `${config.apiBaseUrl}/lender/api/v1/contacts`,
  withCredentials: true
});

export const axiosLenderNotificationsServices = axios.create({
  baseURL: `${config.apiBaseUrl}/lender/api/v1/notifications`,
  withCredentials: true
});

export const axiosLenderMetricsServices = axios.create({
  baseURL: `${config.apiBaseUrl}/lender/api/v1/metrics`,
  withCredentials: true
});

export const handleAxiosError = (error: unknown): string => {
  return axios.isAxiosError(error) && error.response ? _.get(error, 'response.data.message', '') : _.toString(error);
};

export const getAxiosResponseStatus = (error: unknown): number | null => {
  return axios.isAxiosError(error) && error.response ? _.get(error, 'response.status', null) : null;
};
// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
