import _ from 'lodash';
import { getBaseQueryOptionsUserService } from 'pages/deal/utils/api';
import { createContext, ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';
import { SimpleOption } from 'types/api/deal/form';

// Create a context
const UserDisplayContext = createContext<Record<string, string>>({});

export const UserDisplayProvider = ({ children }: { children: ReactNode }) => {
  const queryOptions = getBaseQueryOptionsUserService<Array<SimpleOption>>('/organization/lookup/user');
  const { data = [] } = useQuery<Array<SimpleOption>>(queryOptions);

  // Transform the data into a dictionary
  const dataDictionary = _.mapValues(_.keyBy(data, 'key'), 'label');

  return <UserDisplayContext.Provider value={dataDictionary}>{children}</UserDisplayContext.Provider>;
};

export const useUsersDisplay = () => useContext(UserDisplayContext);
