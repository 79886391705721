// DealTypeEnum and display names
export enum DealTypeEnum {
  sale_both = 'sale_both',
  acquisition = 'acquisition',
  disposition = 'disposition',
  lease_both = 'lease_both',
  landlord_agency = 'landlord_agency',
  tenant_representation = 'tenant_representation',
  referral = 'referral',
  consulting = 'consulting',
  other = 'other'
}

export const DEAL_TYPE_DISPLAY_NAMES: { [key in DealTypeEnum]: string } = {
  [DealTypeEnum.sale_both]: 'Sale - Both',
  [DealTypeEnum.acquisition]: 'Acquisition',
  [DealTypeEnum.disposition]: 'Disposition',
  [DealTypeEnum.lease_both]: 'Lease - Both',
  [DealTypeEnum.landlord_agency]: 'Landlord Agency',
  [DealTypeEnum.tenant_representation]: 'Tenant Representation',
  [DealTypeEnum.referral]: 'Referral',
  [DealTypeEnum.consulting]: 'Consulting',
  [DealTypeEnum.other]: 'Other'
};

export const PROSPECT_TYPE_DISPLAY_NAMES: { [key in DealTypeEnum]?: string } = {
  [DealTypeEnum.acquisition]: 'Buyer',
  [DealTypeEnum.disposition]: 'Seller',
  [DealTypeEnum.landlord_agency]: 'Landlord',
  [DealTypeEnum.tenant_representation]: 'Tenant',
  [DealTypeEnum.other]: 'Other'
};

// DealStatusEnum and display names
export enum DealStatusEnum {
  open = 'open',
  lost = 'lost',
  closed = 'closed'
}

export const DEAL_STATUS_DISPLAY_NAMES: { [key in DealStatusEnum]: string } = {
  [DealStatusEnum.open]: 'Open',
  [DealStatusEnum.lost]: 'Lost',
  [DealStatusEnum.closed]: 'Closed'
};

// ProspectStatusEnum and display names
export enum ProspectStatusEnum {
  open = 'open',
  lost = 'lost',
  converted = 'converted'
}

export const PROSPECT_STATUS_DISPLAY_NAMES: { [key in ProspectStatusEnum]: string } = {
  [ProspectStatusEnum.open]: 'Open',
  [ProspectStatusEnum.lost]: 'Lost',
  [ProspectStatusEnum.converted]: 'Converted'
};

// SizeTypeEnum and display names
export enum SizeTypeEnum {
  square_feet = 'square_feet',
  acres = 'acres',
  units = 'units'
}

export const SIZE_TYPE_DISPLAY_NAMES: { [key in SizeTypeEnum]: string } = {
  [SizeTypeEnum.square_feet]: 'Sq. Ft.',
  [SizeTypeEnum.acres]: 'Acres',
  [SizeTypeEnum.units]: 'Units'
};

// OptionFieldEnum
export enum OptionFieldEnum {
  asset_type = 'asset_type',
  source_type = 'source_type',
  counterparty_broker_company = 'counterparty_broker_company',
  client_industry = 'client_industry',
  lost_reason = 'lost_reason',
  property_name = 'property_name',
  shared_broker_company = 'shared_broker_company',
  submarket = 'submarket',
  tenant_name = 'tenant_name',
  date_type = 'date_type',
  contact_type = 'contact_type',
  group = 'group'
}

// EntityEnum
export enum EntityEnum {
  activity = 'activity',
  address = 'address',
  company = 'company',
  contact = 'contact',
  submarket = 'submarket'
}

// Hire to close ranges
export const hire_to_close_ranges: { [key: string]: [number | null, number | null] } = {
  '0_90': [null, 90],
  '90_180': [90, 180],
  '180_1yr': [180, 365],
  '1_2_yrs': [365, 2 * 365],
  '2_yrs_plus': [2 * 365, null]
};

export enum ActivityTypeEnum {
  call = 'call',
  email = 'email',
  meeting = 'meeting',
  task = 'task'
}
