import { Grid, Stack } from '@mui/material';
import logoFace from '/CREOneSource_R_Logo_Full-Colour2.png';
import logoIcon from 'assets/images/onesource-logo-mark.png';
import { EditingProvider } from 'contexts/EditingContext';

interface PrintContactFormProps {
  children: React.ReactNode;
  header: React.ReactNode;
}

// Simplified, read-only Contact Form view for printing
const PrintContactForm = ({ children, header }: PrintContactFormProps) => {
  return (
    <EditingProvider>
      <Grid container spacing={1} px={3} mt={2} alignItems={'center'}>
        <Grid item xs={6}>
          {header}
        </Grid>
        <Grid item xs={6} justifyContent={'flex-end'} display={'flex'}>
          <Stack direction={'row'}>
            <img alt={'logo'} src={logoIcon} width={30} height={30} />
            <img alt={'logo'} src={logoFace} width={'auto'} height={30} />
          </Stack>
        </Grid>
      </Grid>
      <Stack
        direction={'row'}
        spacing={2}
        p={2}
        sx={{
          '*': {
            fontSize: 13,
            paddingY: 0,
            marginY: 0,
            lineHeight: 1.5
          },
          span: {
            paddingY: 1
          }
        }}
      >
        {children}
      </Stack>
    </EditingProvider>
  );
};
export default PrintContactForm;
