import { lazy } from 'react';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import PricingCard from '../pages/auth/sections/PricingCard';
import AuthCard from '../pages/auth/sections/AuthCard';
import AuthWrapper from '../pages/auth/sections/AuthWrapper';
import RegistrationFlow from 'pages/auth/sections/RegistrationFlow';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthExisting = Loadable(lazy(() => import('pages/auth/existing')));
const AuthNoAccount = Loadable(lazy(() => import('pages/auth/no-account')));
const AuthInviteLogin = Loadable(lazy(() => import('pages/auth/invite_register')));
const Pricing = Loadable(lazy(() => import('pages/auth/pricing')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/',
          element: (
            <AuthWrapper>
              <AuthCard>
                <AuthLogin />
              </AuthCard>
            </AuthWrapper>
          )
        },
        {
          path: 'login',
          element: (
            <AuthWrapper>
              <AuthCard>
                <AuthLogin />
              </AuthCard>
            </AuthWrapper>
          )
        },
        {
          path: 'existing_account',
          element: (
            <AuthWrapper>
              <AuthCard>
                <AuthExisting />
              </AuthCard>
            </AuthWrapper>
          )
        },
        {
          path: 'no_account',
          element: (
            <AuthWrapper>
              <AuthCard>
                <AuthNoAccount />
              </AuthCard>
            </AuthWrapper>
          )
        },
        {
          path: 'register/:product',
          element: <RegistrationFlow />
        },
        {
          path: 'register',
          element: <RegistrationFlow />
        },
        {
          path: 'invite_login',
          element: (
            <AuthWrapper>
              <AuthCard>
                <AuthInviteLogin />
              </AuthCard>
            </AuthWrapper>
          )
        },
        {
          path: 'pricing/:product',
          element: (
            <AuthWrapper>
              <PricingCard>
                <Pricing />
              </PricingCard>
            </AuthWrapper>
          )
        },
        {
          path: 'pricing',
          element: (
            <AuthWrapper>
              <PricingCard>
                <Pricing />
              </PricingCard>
            </AuthWrapper>
          )
        },
        {
          path: 'forgot-password',
          element: (
            <AuthWrapper>
              <AuthCard>
                <AuthForgotPassword />
              </AuthCard>
            </AuthWrapper>
          )
        },
        {
          path: 'check-mail',
          element: (
            <AuthWrapper>
              <AuthCard>
                <AuthCheckMail />
              </AuthCard>
            </AuthWrapper>
          )
        },
        {
          path: 'reset-password',
          element: (
            <AuthWrapper>
              <AuthCard>
                <AuthResetPassword />
              </AuthCard>
            </AuthWrapper>
          )
        },
        {
          path: 'code-verification',
          element: (
            <AuthWrapper>
              <AuthCard>
                <AuthCodeVerification />
              </AuthCard>
            </AuthWrapper>
          )
        }
      ]
    }
  ]
};

export default LoginRoutes;
