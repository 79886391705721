import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from '../index';

interface DealCommissionGoalState {
  editing: boolean;
  currentEditing: number | null;
}

const initialState: DealCommissionGoalState = {
  editing: false,
  currentEditing: null
};

const slice = createSlice({
  name: 'dealCommissionGoalV2',
  initialState,
  reducers: {
    // Start Editing
    startEditing(state: DealCommissionGoalState, action: PayloadAction<number | null>) {
      state.editing = true;
      state.currentEditing = action.payload;
    },
    // Stop Editing
    stopEditing(state: DealCommissionGoalState) {
      state.editing = false;
      state.currentEditing = null;
    }
  }
});

// Reducer
export default slice.reducer;

export function startEditingDealCommissionGoal(id: number | null) {
  dispatch(slice.actions.startEditing(id));
}

export function stopEditingDealCommissionGoal() {
  dispatch(slice.actions.stopEditing());
}
