import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NumberFormatTextField = styled(TextField)({
  '& input': {
    padding: '12px 4px'
  }
});

export const PercentTextField = styled(TextField)({
  '& input': {
    padding: '12px 4px 12px 12px'
  }
});
export const CurrencyTextField = styled(TextField)({
  '& input': {
    padding: '12px 12px 12px 4px'
  }
});
