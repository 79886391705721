import { TextFieldString } from 'components/form/TextFieldString';
import { ObjectMetadata } from 'types/standardForm';
import * as Yup from 'yup';

export const OptionMetadata: ObjectMetadata = {
  value: {
    fieldName: 'value',
    displayName: 'Value',
    initialValue: '',
    _schema: Yup.string().max(255, 'Value must be less than 256 characters.').required(`Value is required.`),
    component: TextFieldString
  },
  field: {
    fieldName: 'field',
    displayName: 'Field',
    initialValue: null,
    _schema: Yup.string().nullable().required().max(255, 'Field is required'),
    component: TextFieldString
  }
};
