// material-ui
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/logo';
import navLogo from 'assets/images/nav-logo.png';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

const LogoTextImg = styled('img')(({ theme }) => ({
  paddingLeft: '10px',
  height: '24px'
}));

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Logo isIcon sx={{ width: open ? 'auto' : 40, height: 40 }} /> {open && <LogoTextImg alt="OneSource" src={navLogo} />}
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
