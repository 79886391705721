import _ from 'lodash';
import { NavigateFunction, SetURLSearchParams } from 'react-router-dom';
import { clearEditingRecordStack } from 'store/reducers/record';
import { FormIdentifier, RecordIdentifier } from 'types/record';

export const handlePreview = (
  id: number | string,
  type: RecordIdentifier,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams
) => {
  searchParams.set('preview', id.toString());
  searchParams.set('type', type);
  setSearchParams(searchParams);
};

export const handleFormNavigate = (
  id: number | null,
  formIdentifier: FormIdentifier,
  navigate: NavigateFunction,
  incomingChanges: object = {}
) => {
  clearEditingRecordStack();
  navigate(_.isNil(id) ? '/deals' : `/deals/${id}`, { state: incomingChanges });
};
