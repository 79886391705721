import { Badge, Stack } from '@mui/material';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { isDealViewPro } from '../utils/deal_form';

export function ProMenuBadge(props: { textId: string }) {
  return (
    <Fragment>
      {isDealViewPro() ? (
        <FormattedMessage id={props.textId} />
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <FormattedMessage id={props.textId} />
          <Badge badgeContent={'PRO'} color="secondary" variant="light" sx={{ pl: 3, mr: 3 }} />
        </Stack>
      )}
    </Fragment>
  );
}
