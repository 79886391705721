// material-ui
import { InfoCircleFilled } from '@ant-design/icons';
// assets
import { Alert, Box, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
// material-ui
import StandardField from 'components/form/standard/StandardField';
import { StandardForm } from 'components/form/standard/StandardForm';
import DynamicVisibilitySection from 'components/form/standard/DynamicVisibilitySection';
import { ActivityFollowupFields } from 'components/form/forms/sections/ActivityFollowupFields';

// project import
import MainCard from 'components/MainCard';
import { ActivityFollowupMetadataSubset, isLostReasonRequired } from 'constants/objectMetadata/activityFollowupMetadata';
import { ActivityMetadata } from 'constants/objectMetadata/activityMetadata';
import { FormikValues } from 'formik';
import _ from 'lodash';
import { CloseButton } from 'pages/deal/components/CloseButton';

// project import
import { startEditingRecord } from 'store/reducers/record';
import { Activity, ActivityWithFollowup } from 'types/api/deal/activity';
import { ActivityTypeEnum } from 'types/api/deal/enum';
import { FormIdentifier, StandardFormProps } from 'types/record';
import { FormSubmitAction } from 'types/standardForm';
import { createActivityAsync, deleteActivityAsync, updateActivityAsync } from 'utils/activity';
import * as Sentry from '@sentry/react';

const FormMetadata = { ...ActivityFollowupMetadataSubset, ...ActivityMetadata };

const shouldRequireLostReason = ({ is_followup, should_require_lost_reason }: FormikValues) =>
  isLostReasonRequired(is_followup, should_require_lost_reason);

export function ActivityFollowupSection() {
  return (
    <DynamicVisibilitySection visibilityFunction={(values) => !_.isNil(_.get(values, 'is_followup'))}>
      <Grid item xs={12}>
        <MainCard>
          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xs={12}>
              <StandardField field={FormMetadata.is_followup} showLabel={false} />
            </Grid>
            <DynamicVisibilitySection visibilityFunction={(values) => _.get(values, 'is_followup', false)}>
              <ActivityFollowupFields />
            </DynamicVisibilitySection>
            <DynamicVisibilitySection visibilityFunction={shouldRequireLostReason}>
              <>
                <Grid item xs={12}>
                  <Alert color="info" icon={<InfoCircleFilled />}>
                    Since this is the last remaining Activity for this Prospect, choosing "No Follow Up" will update the Prospect Status to
                    Lost.
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <StandardField field={FormMetadata.lost_reason} />
                </Grid>
              </>
            </DynamicVisibilitySection>
          </Grid>
        </MainCard>
      </Grid>
    </DynamicVisibilitySection>
  );
}

export function ActivityFormSection() {
  return (
    <Grid container columnSpacing={2} rowSpacing={1}>
      <Grid item xs={12} sm={6}>
        <StandardField field={ActivityMetadata.contact} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StandardField field={ActivityMetadata.activity_type_v2} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StandardField field={ActivityMetadata.subject} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <StandardField field={ActivityMetadata.date} />
      </Grid>
      <Grid item xs={2}>
        <StandardField field={ActivityMetadata.is_complete} />
      </Grid>
      <Grid item xs={12}>
        <StandardField field={ActivityMetadata.notes_plaintext} />
      </Grid>
    </Grid>
  );
}

export const ActivityForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
  showAddAnother = true,
  isDialog = true
}: StandardFormProps<Activity>) => {
  const createFnAsync = async (activity: Activity, submitAction: FormSubmitAction = FormSubmitAction.save) => {
    try {
      const record = await createActivityAsync(activity);

      if (typeof successCallback === 'function' && _.isObject(record)) successCallback(record);

      if (submitAction === FormSubmitAction.saveAndAddAnother) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        startEditingRecord(null, FormIdentifier.ActivityForm, incomingChanges, true);
      }
    } catch (error) {
      Sentry.captureException('Unable to create an Activity.');
    }
  };

  const updateFnAsync = async (id: number | string, activity: Partial<ActivityWithFollowup>) => {
    try {
      const contactId = _.get(activity, 'contact_id');

      const activityForPut = {
        ...activity,
        followup: {
          owner: _.get(activity, 'owner'),
          contact_id: contactId,
          activity_type_v2: _.get(activity, 'followup_activity_type_v2') as ActivityTypeEnum,
          date: _.get(activity, 'followup_date'),
          subject: _.get(activity, 'followup_subject'),
          is_complete: false
        }
      };
      await updateActivityAsync(id, activityForPut);

      if (typeof successCallback === 'function') successCallback();
    } catch (error) {
      Sentry.captureException('Unable to update Activity.');
    }
  };

  const deleteFnAsync = async (id: number | string) => {
    try {
      await deleteActivityAsync(id);
      if (typeof successCallback === 'function') successCallback();
    } catch (error) {
      Sentry.captureException('Unable to delete an Activity.');
    }
  };

  const actionName = _.get(incomingChanges, 'is_followup', false) ? 'Complete' : !!record ? 'Edit' : 'Add';

  return (
    <StandardForm<Activity>
      metadata={FormMetadata}
      record={record}
      updateFn={updateFnAsync}
      createFn={createFnAsync}
      deleteFn={showAddAnother || !record?.id || _.get(record, '_pending') ? undefined : deleteFnAsync}
      displayName={'Activity'}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      showAddAnother={showAddAnother}
      open={open}
      isDialog={isDialog}
    >
      {({ StandardFormActionsInstance }) =>
        isDialog ? (
          <>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item>
                <DialogTitle>{actionName} Activity</DialogTitle>
              </Grid>
              <Grid item xs={true}></Grid>
              <Grid item>
                <StandardField field={ActivityMetadata.owner} showLabel={false} />
              </Grid>
              <Grid item sx={{ mr: 1.5 }}>
                <CloseButton onClose={onCancel} />
              </Grid>
            </Grid>
            <DialogContent>
              <Box>
                <Grid container spacing={2}>
                  <Grid container item xs={12} sm={12} spacing={3}>
                    <Grid item xs={12}>
                      <MainCard>
                        <ActivityFormSection />
                      </MainCard>
                    </Grid>
                    <ActivityFollowupSection />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>{StandardFormActionsInstance}</DialogActions>
          </>
        ) : (
          <>
            <Grid container spacing={2} sx={{ pb: 2 }}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12}>
                  <MainCard>
                    <Stack direction={'row'} justifyContent={'flex-end'} sx={{ my: -2 }}>
                      <Box>
                        <StandardField field={ActivityMetadata.owner} showLabel={false} />
                      </Box>
                    </Stack>
                    <ActivityFormSection />
                  </MainCard>
                </Grid>
                <ActivityFollowupSection />
              </Grid>
            </Grid>
            {StandardFormActionsInstance}
          </>
        )
      }
    </StandardForm>
  );
};
