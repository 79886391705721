import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography
} from '@mui/material';
import LoadingButton from 'components/@extended/LoadingButton';
import { FieldArray, Form, Formik } from 'formik';
import _ from 'lodash';
import { registrationSteps } from 'pages/auth/constants/registration';
import React, { useMemo } from 'react';
import { RootState, useSelector } from 'store';
import { setTeamMembersData } from 'store/reducers/registrationData';

import { RegistrationStepProps, RoleTypeV2, TeamMemberInviteData, TeamMembersData } from 'types/authRegister';
import { axiosUserServices } from 'utils/axios';
import * as Yup from 'yup';

export const emptyTeamMemberRow: TeamMemberInviteData = { first_name: '', last_name: '', username: '', role_type: '' };

export const TeamMembersForm = ({ handleNext, handleBack, setErrorIndex }: RegistrationStepProps) => {
  const { teamMembersData, sso, userDetailsData, ssoDetailsData } = useSelector((state: RootState) => state.registrationData);

  const usernameBeingRegistered = sso ? ssoDetailsData.username : userDetailsData.username;

  // Define form schema
  const validationSchema = useMemo(
    () =>
      Yup.object({
        teamMembers: Yup.array().of(
          Yup.object().shape(
            {
              first_name: Yup.string()
                .max(255)
                .when(['last_name', 'username'], {
                  is: (last_name: string, username: string) => last_name || username,
                  then: (schema) => schema.required('First Name is required.'),
                  otherwise: (schema) => schema
                }),
              last_name: Yup.string()
                .max(255)
                .when(['first_name', 'username'], {
                  is: (first_name: string, username: string) => first_name || username,
                  then: (schema) => schema.required('Last Name is required.'),
                  otherwise: (schema) => schema
                }),
              username: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .when(['first_name', 'last_name'], {
                  is: (first_name: string, last_name: string) => first_name || last_name,
                  then: (schema) =>
                    schema.required('Email is required.').notOneOf([usernameBeingRegistered], 'Username is already registered.'), // Prevent the user from adding the main account email address as a team member email address
                  otherwise: (schema) => schema
                }),
              role_type: Yup.string()
                .nullable()
                .oneOf([RoleTypeV2.super_admin, RoleTypeV2.team_member, RoleTypeV2.individual, null, ''], 'Unknown Role Type')
                .when(['first_name', 'last_name', 'username'], {
                  is: (first_name: string, last_name: string, username: string) => first_name || last_name || username,
                  then: (schema) => schema.required('Role is required.'),
                  otherwise: (schema) => schema
                })
            },
            [
              ['first_name', 'last_name'],
              ['first_name', 'username'],
              ['last_name', 'username'],
              ['username', 'role_type']
            ]
          )
        )
      }),
    [usernameBeingRegistered]
  );

  const saveProgressAndHandleBack = (values: { teamMembers: TeamMembersData }, isValid: boolean) => {
    // If form is currently valid, save progress when they click Back
    if (isValid) setTeamMembersData(values.teamMembers);
    // Navigate backwards
    handleBack(sso);
  };

  return (
    <>
      <Typography variant="h3">{registrationSteps[1].title}</Typography>
      <Typography variant="caption" sx={{ pb: 1, fontStyle: 'italic' }}>
        A paid license is added for each member below. They will be sent an invite to join your account.
      </Typography>
      <Formik
        initialValues={{ teamMembers: teamMembersData }}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          setSubmitting(true);
          const promises = _.map(values.teamMembers, (x, idx) => {
            if (x.username) {
              // If username is populated, check for existence
              return axiosUserServices.get(`/user/exists/`, { params: { username: x.username } }).then((response) => {
                if (response.data === true) setFieldError(`teamMembers.${idx}.username`, 'Username is already registered.');
                return response.data;
              });
            } else {
              // If empty row, return false
              return Promise.resolve(false);
            }
          });

          // Execute all promises asynchronously
          const results = await Promise.all(promises);

          // Check if all return values are false
          const allFalse = results.every((result) => result === false);

          if (allFalse) {
            setTeamMembersData(values.teamMembers);
            handleNext();
          }

          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ values, handleBlur, handleChange, touched, errors, isValid, isSubmitting }) => (
          <Form>
            <FieldArray name="teamMembers">
              {({ insert, push, remove }) => (
                <Grid container spacing={1}>
                  {values.teamMembers.length > 0 &&
                    values.teamMembers.map((_teamMember, index) => {
                      const row_first_name = `teamMembers.${index}.first_name`;
                      const row_last_name = `teamMembers.${index}.last_name`;
                      const row_username = `teamMembers.${index}.username`;
                      const row_role_type = `teamMembers.${index}.role_type`;

                      return (
                        <Grid container item alignItems="center" spacing={1} key={index} columns={24}>
                          <Grid item xs={4}>
                            <InputLabel htmlFor={row_first_name}>First Name</InputLabel>
                          </Grid>
                          <Grid item xs={4}>
                            <InputLabel htmlFor={row_last_name}>Last Name</InputLabel>
                          </Grid>
                          <Grid item xs={8}>
                            <InputLabel htmlFor={row_username}>Email</InputLabel>
                          </Grid>
                          <Grid item xs={8}>
                            <InputLabel htmlFor={row_role_type}>Role</InputLabel>
                          </Grid>
                          <Grid item xs={4}>
                            <OutlinedInput
                              id={row_first_name}
                              value={_.get(values, row_first_name)}
                              name={row_first_name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              error={Boolean(_.get(touched, row_first_name) && _.get(errors, row_first_name))}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <OutlinedInput
                              id={row_last_name}
                              value={_.get(values, row_last_name)}
                              name={row_last_name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              error={Boolean(_.get(touched, row_last_name) && _.get(errors, row_last_name))}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <OutlinedInput
                              id={row_username}
                              value={_.get(values, row_username)}
                              name={row_username}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              error={Boolean(_.get(touched, row_username) && _.get(errors, row_username))}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Select
                              id={row_role_type}
                              value={_.get(values, row_role_type)}
                              onChange={handleChange}
                              name={row_role_type}
                              error={Boolean(_.get(touched, row_role_type) && _.get(errors, row_role_type))}
                              fullWidth
                              renderValue={(value) => value}
                            >
                              <MenuItem value={RoleTypeV2.super_admin}>
                                <ListItemText primary={'Super Admin'} secondary={'Can see everything.'} />
                              </MenuItem>
                              <MenuItem value={RoleTypeV2.team_member}>
                                <ListItemText primary={'Team Member'} secondary={"Can see their team's records."} />
                              </MenuItem>
                              <MenuItem value={RoleTypeV2.individual}>
                                <ListItemText primary={'Individual'} secondary={'Can only see their own records.'} />
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={3}>
                            <Stack direction={'row'}>
                              {values.teamMembers.length > 1 && (
                                <IconButton onClick={() => remove(index)}>
                                  <RemoveCircleIcon color="primary" />
                                </IconButton>
                              )}
                              {index + 1 === values.teamMembers.length && (
                                <IconButton
                                  onClick={() => {
                                    push(emptyTeamMemberRow);
                                  }}
                                >
                                  <AddCircleIcon color="primary" />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>

                          <Grid item xs={4}>
                            {_.get(touched, row_first_name) && _.get(errors, row_first_name) && (
                              <FormHelperText error>{_.get(errors, row_first_name)}</FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            {_.get(touched, row_last_name) && _.get(errors, row_last_name) && (
                              <FormHelperText error>{_.get(errors, row_last_name)}</FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            {_.get(touched, row_username) && _.get(errors, row_username) && (
                              <FormHelperText error>{_.get(errors, row_username)}</FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            {_.get(touched, row_role_type) && _.get(errors, row_role_type) && (
                              <FormHelperText error>{_.get(errors, row_role_type)}</FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                  <Grid container item xs={12} alignItems={'center'}>
                    <Grid item xs={2}>
                      <LoadingButton
                        disableElevation
                        fullWidth
                        size="large"
                        variant="text"
                        color="primary"
                        onClick={() => saveProgressAndHandleBack(values, isValid)}
                      >
                        Back
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={true}></Grid>
                    <Grid item xs={3}>
                      <LoadingButton
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                      >
                        {_.some(values.teamMembers, (x) => !_.isEqual(x, emptyTeamMemberRow)) ? 'Continue' : 'Skip'}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </>
  );
};
