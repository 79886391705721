import { InfoCircleFilled } from '@ant-design/icons';
import { Apartment, Event, Handshake, Notes, Paid, People, Person } from '@mui/icons-material';
import { Alert, Box, Grid, Paper, Toolbar, useMediaQuery } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { DealFormSection, GridField, StandardGridField } from 'components/form/forms/deal/sections/DealFormSection';
import DealPreviewFromContext from 'components/form/forms/sections/DealPreviewFromContext';
import DynamicVisibilitySection from 'components/form/standard/DynamicVisibilitySection';
// material-ui
import StandardField from 'components/form/standard/StandardField';
import { StandardForm } from 'components/form/standard/StandardForm';

// project import
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import { isFeatureEnabledOrgPreferences, isFieldVisibleOrgPreferences, useDealOrgPreferences } from 'contexts/DealOrgPreferencesContext';
import { FormikValues, useFormikContext } from 'formik';
import { HeaderBar } from 'layout/MainLayout/Header';
import _ from 'lodash';
import { isAddressVisible } from 'pages/deal/utils/deal_form';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// project import
import { Deal, DealFeature, DealField, DealStatus } from 'types/deal';
import { StandardFormProps } from 'types/record';
import { RootStateProps } from 'types/root';
import { createDealAsync, deleteDealAsync, updateDealAsync } from 'utils/deal';

function SaveErrorMessage() {
  const { submitCount, errors } = useFormikContext();
  return submitCount > 0 && _.size(errors) ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: 10 }}>
      {_.map(errors, (value, key) => {
        let errorMessage = `${value}`;
        if (key === 'broker_payment_commission') errorMessage = 'Error in Commission Split section.';
        if (key === 'commission_payments') errorMessage = 'Error in Commission Payments section.';

        return (
          <Alert color="error" icon={<InfoCircleFilled />}>
            {errorMessage}
          </Alert>
        );
      })}
    </Box>
  ) : (
    <></>
  );
}

export const DealViewProForm = ({ record, incomingChanges, onCancel, successCallback, isDialog }: StandardFormProps<Deal>) => {
  const { data: dealOrgPreferences } = useDealOrgPreferences();
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down(1350));

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  const createFn = async (deal: Deal) => {
    try {
      const record = await createDealAsync(deal, navigate);
      if (typeof successCallback === 'function') {
        successCallback(record);
      }
    } catch (error) {
      Sentry.captureException('Unable to create a Deal.');
    }
  };

  const updateFn = async (id: number | string, deal: Partial<Deal>) => {
    try {
      const record = await updateDealAsync(id, deal, navigate);
      if (typeof successCallback === 'function') {
        successCallback(record);
      }
    } catch (error) {
      Sentry.captureException('Unable to update a Deal.');
    }
  };
  const deleteFn = async (id: number | string) => {
    try {
      await deleteDealAsync(id);
      if (typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      Sentry.captureException('Unable to delete a Deal.');
    }
  };

  const dealTypeVisibilityFunction = (values: FormikValues) => !_.isNil(_.get(values, 'deal_type'));

  return (
    <StandardForm<Deal>
      metadata={DealMetadata}
      record={record}
      updateFn={updateFn}
      createFn={createFn}
      deleteFn={deleteFn}
      displayName={'Deal'}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      open
      fullWidth={true}
      isDialog={isDialog}
      maxWidth={'lg'}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <DialogContent>
            <div style={{ display: 'flex', width: '100%', gap: 20 }}>
              <Paper variant="outlined" sx={{ p: 5, width: isDialog || matchDownMD ? 1 : '70%', height: 'fit-content' }}>
                <SaveErrorMessage />
                <DealFormSection
                  title="Deal information"
                  sectionIcon={Handshake}
                  sectionIconColor="#7BC0F9"
                  sx={{ mt: -5 }}
                  titleAction={
                    <Grid container rowSpacing={2} columnSpacing={2} sx={{ justifyContent: { md: 'flex-end' } }}>
                      <DynamicVisibilitySection visibilityFunction={dealTypeVisibilityFunction}>
                        <>
                          <Grid item xs={12} sm={4}>
                            <StandardField field={DealMetadata.stage} showLabel={false} />
                          </Grid>
                          <GridField item xs={12} sm={2} field={DealMetadata.probability_override}>
                            <StandardField field={DealMetadata.probability_override} showLabel={false} />
                          </GridField>
                          <Grid item xs={12} sm={true} sx={{ flexGrow: '0 !important' }}>
                            <StandardField field={DealMetadata.status} showLabel={false} />
                          </Grid>
                        </>
                      </DynamicVisibilitySection>
                    </Grid>
                  }
                >
                  <Grid container columnSpacing={5} rowSpacing={4}>
                    <Grid item xs={12} sm={6}>
                      <StandardField field={DealMetadata.deal_type} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StandardField field={DealMetadata.name} />
                    </Grid>
                  </Grid>
                </DealFormSection>

                <DynamicVisibilitySection visibilityFunction={dealTypeVisibilityFunction}>
                  <>
                    <DealFormSection title="Transaction information" sectionIcon={Paid} sectionIconColor="#39C27E">
                      <Grid container columnSpacing={5} rowSpacing={4}>
                        <Grid item xs={12} sm={3}>
                          <StandardField field={DealMetadata.close_date_est} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <StandardField field={DealMetadata.sale_price_est} />
                          <StandardField field={DealMetadata.total_consideration_est} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <StandardField field={DealMetadata.commission_est} />
                        </Grid>
                        <DynamicVisibilitySection visibilityFunction={(x) => _.get(x, 'status') === DealStatus.closed}>
                          <Grid item xs={12} sm={3}>
                            <StandardField field={DealMetadata.close_date} />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <StandardField field={DealMetadata.sale_price} />
                            <StandardField field={DealMetadata.total_consideration} />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <StandardField field={DealMetadata.commission} />
                          </Grid>
                          <GridField item xs={12} sm={6} field={DealMetadata.lease_expiration}>
                            <StandardField field={DealMetadata.lease_expiration} />
                          </GridField>
                        </DynamicVisibilitySection>
                      </Grid>
                    </DealFormSection>
                    <DealFormSection title="Property information" sectionIcon={Apartment} sectionIconColor="#F9B382">
                      <Grid container columnSpacing={5} rowSpacing={4}>
                        <Grid item xs={12} sm={6}>
                          <StandardField field={DealMetadata.asset_type} />
                        </Grid>
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.property_name} />
                        {isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE) && (
                          <>
                            <Grid item xs={6} sm={3}>
                              <StandardField field={DealMetadata.size_est} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <StandardField field={DealMetadata.size_type} />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} sm={6}>
                          <StandardField field={DealMetadata.submarket} />
                        </Grid>
                        {isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE) && (
                          <DynamicVisibilitySection visibilityFunction={(x) => _.get(x, 'status') === DealStatus.closed}>
                            <Grid item xs={6} sm={3}>
                              <StandardField field={DealMetadata.size} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <StandardField field={DealMetadata.size_type} />
                            </Grid>
                          </DynamicVisibilitySection>
                        )}

                        {isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS) && (
                          <Grid item xs={12}>
                            <Grid container columnSpacing={5} rowSpacing={4}>
                              <Grid item xs={12}>
                                <StandardField field={DealMetadata._address_search} />
                              </Grid>
                              <DynamicVisibilitySection visibilityFunction={isAddressVisible}>
                                <Grid item xs={12} sm={6}>
                                  <StandardField field={DealMetadata.address_line_1} displayName="Street address" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <StandardField field={DealMetadata.address_line_2} displayName="Apt / suite" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <StandardField field={DealMetadata.address_city} />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <StandardField field={DealMetadata.address_state} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <StandardField field={DealMetadata.address_postal_code} />
                                </Grid>
                              </DynamicVisibilitySection>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </DealFormSection>
                    <DealFormSection title="Client information" sectionIconColor="#CA94B8" sectionIcon={Person}>
                      <Grid container columnSpacing={5} rowSpacing={4}>
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.contact} />
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.tenant_name} />
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.client_industry} />
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.source_type} />
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.hire_date} />
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.move_in_date_est} />
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.relocation_date_est} />
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.move_in_date} />
                        <StandardGridField item xs={12} sm={6} field={DealMetadata.relocation_date} />
                        <StandardGridField item xs={12} field={DealMetadata.broker_payment_commission} />
                        <StandardGridField item xs={12} field={DealMetadata.commission_payments} />
                      </Grid>
                    </DealFormSection>
                    {(isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.OUTSIDE_BROKER) ||
                      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SHARED_BROKER)) && (
                      <DealFormSection title="Broker information" sectionIconColor="#FF8B7E" sectionIcon={People}>
                        <Grid container columnSpacing={5} rowSpacing={5}>
                          {isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.OUTSIDE_BROKER) && (
                            <Grid item xs={12}>
                              <Grid container rowSpacing={3} columnSpacing={5}>
                                <Grid item xs={12} sm={12}>
                                  <StandardField field={DealMetadata.is_dual_agency} />
                                </Grid>
                                <DynamicVisibilitySection visibilityFunction={(values) => _.get(values, 'is_dual_agency') === true}>
                                  <>
                                    <Grid item xs={12} sm={6}>
                                      <StandardField field={DealMetadata.counterparty_broker_company} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <StandardField field={DealMetadata.outside_broker} />
                                    </Grid>
                                  </>
                                </DynamicVisibilitySection>
                              </Grid>
                            </Grid>
                          )}
                          {isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SHARED_BROKER) && (
                            <Grid item xs={12}>
                              <Grid container rowSpacing={3} columnSpacing={5}>
                                <Grid item xs={12} sm={12}>
                                  <StandardField field={DealMetadata.shared_deal} />
                                </Grid>
                                <DynamicVisibilitySection visibilityFunction={(values) => _.get(values, 'shared_deal') === true}>
                                  <>
                                    <Grid item xs={12} sm={6}>
                                      <StandardField field={DealMetadata.shared_broker_company} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <StandardField field={DealMetadata.shared_broker} />
                                    </Grid>
                                  </>
                                </DynamicVisibilitySection>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </DealFormSection>
                    )}

                    {isFeatureEnabledOrgPreferences(dealOrgPreferences, DealFeature.KEY_DATES) && (
                      <DealFormSection title="Key dates" sectionIcon={Event} sectionIconColor="#FEC20A">
                        <Grid container>
                          <Grid item xs={12}>
                            <StandardField field={DealMetadata.key_dates} />
                          </Grid>
                        </Grid>
                      </DealFormSection>
                    )}
                    <DealFormSection title="Notes" sectionIcon={Notes} sectionIconColor="#A09FEF">
                      <Grid container columnSpacing={2} rowSpacing={1}>
                        <Grid item xs={12}>
                          <StandardField field={DealMetadata.notes_plaintext} showLabel={false} />
                        </Grid>
                      </Grid>
                    </DealFormSection>
                    <DynamicVisibilitySection visibilityFunction={(values) => _.get(values, 'status') === DealStatus.lost}>
                      <DealFormSection title="Lost reason">
                        <Grid item xs={12}>
                          <Grid container columnSpacing={2} rowSpacing={1}>
                            <Grid item xs={12} sm={6}>
                              <StandardField field={DealMetadata.lost_reason} touch={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <StandardField field={DealMetadata.lost_date} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </DealFormSection>
                    </DynamicVisibilitySection>

                    <DynamicVisibilitySection visibilityFunction={(values) => !!_.get(values, 'id')}>
                      <Grid item xs={12} sx={{ mt: 1.5 }}>
                        <Grid container columnSpacing={2} rowSpacing={1}>
                          <Grid item xs={12} sm={6}>
                            <StandardField field={DealMetadata.created_by} />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <StandardField field={DealMetadata.created_at} />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <StandardField field={DealMetadata.updated_by} />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <StandardField field={DealMetadata.updated_at} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </DynamicVisibilitySection>
                  </>
                </DynamicVisibilitySection>
              </Paper>
              {!isDialog && !matchDownMD && (
                <Paper variant="outlined" sx={{ p: 4, width: '30%' }}>
                  <DealPreviewFromContext />
                </Paper>
              )}
            </div>
          </DialogContent>
          {!isDialog && (
            <HeaderBar open={drawerOpen} isBottom>
              <Toolbar>{StandardFormActionsInstance}</Toolbar>
            </HeaderBar>
          )}

          {!!isDialog && <DialogActions>{StandardFormActionsInstance}</DialogActions>}
        </>
      )}
    </StandardForm>
  );
};
