import { lazy, useEffect, useState } from 'react';
import { subscriptionExpired } from 'utils/checkSubscriptionStatus';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import Loadable from 'components/Loadable';
const ExpiredPage = Loadable(lazy(() => import('pages/extra-pages/expiredSubScriptionPage')));

// ==============================|| GUEST GUARD ||============================== //

const SubscriptionGuard = ({ children }: GuardProps) => {
  const { user } = useAuth();
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    setExpired(subscriptionExpired(user));
  }, [user]);

  if (expired) {
    return <ExpiredPage />;
  } else {
    return children;
  }
};

export default SubscriptionGuard;
