// src/components/QuickAddWizardForm.tsx
import { EventNote, HandshakeOutlined, Person, Star } from '@mui/icons-material';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MainCard from 'components/MainCard';
import { isDealViewPro } from 'pages/deal/utils/deal_form';
import { newProspectIncomingChanges } from 'pages/prospects/constants/prospect';
import React from 'react';
import { useNavigate } from 'react-router';
import { modifyEditingRecordDetails } from 'store/reducers/record';
import { FormIdentifier, StandardFormProps } from 'types/record';

const useStyles = makeStyles((theme: Theme) => ({
  tile: {
    padding: theme.spacing(2),
    textAlign: 'center',
    cursor: 'pointer'
  },
  icon: {
    fontSize: '4rem'
  }
}));

type TileProps = {
  label: string;
  IconComponent: typeof Person;
  onClick: () => void;
};

const Tile: React.FC<TileProps> = ({ label, IconComponent, onClick }) => {
  const classes = useStyles();

  return (
    <MainCard className={classes.tile} onClick={onClick}>
      <IconComponent className={classes.icon} color={'primary'} />
      <Typography variant="h6">{label}</Typography>
    </MainCard>
  );
};

export const QuickAddWizardForm = ({ open, onCancel }: StandardFormProps<any>) => {
  const isDVPro = isDealViewPro();

  const navigate = useNavigate();

  const handleDealClick = () => {
    onCancel();
    navigate(`/deals`);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={true}>
            <Tile
              label="Contact"
              IconComponent={Person}
              onClick={() => modifyEditingRecordDetails(null, FormIdentifier.ContactQuickCreateForm, {}, true)}
            />
          </Grid>
          <Grid item xs={true}>
            <Tile
              label="Prospect"
              IconComponent={Star}
              onClick={() => modifyEditingRecordDetails(null, FormIdentifier.ContactForm, newProspectIncomingChanges)}
            />
          </Grid>
          <Grid item xs={true}>
            <Tile
              label="Activity"
              IconComponent={EventNote}
              onClick={() => modifyEditingRecordDetails(null, FormIdentifier.ActivityForm, {}, true)}
            />
          </Grid>
          {isDVPro && (
            <Grid item xs={true}>
              <Tile label="Deal" IconComponent={HandshakeOutlined} onClick={handleDealClick} />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
