// material-ui
import { useTheme } from '@mui/material/styles';

// platform
import logoIconFile from 'assets/images/onesource-logo-mark.png';
import logoIconDarkFile from 'assets/images/logo.svg';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return <img src={theme.palette.mode === 'dark' ? logoIconDarkFile : logoIconFile} alt="Mantis" width="40" />;
};

export default LogoIcon;
