import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import MainCard from 'components/MainCard';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { ContactAdditionalData } from 'types/api/deal/contactAdditionalData';

function sortAndFilterNonNull(obj: Record<string, string>): Array<{ key: string; value: string }> {
  // Filter out non-null values
  const nonNullValues = _.pickBy(obj, (value) => value !== null);

  // Get the keys of the filtered object and sort them
  const sortedKeys = _.keys(nonNullValues).sort();

  // Map over the sorted keys to create an array of { key, value } pairs
  return sortedKeys.map((key) => ({ key, value: nonNullValues[key] }));
}

export function ContactAdditionalDataSection(props: { additional_data?: ContactAdditionalData }) {
  const matchDownMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const jsonData: Array<{ key: string; value: string }> = useMemo(
    () => sortAndFilterNonNull(_.get(props.additional_data, 'json_data', {})),
    [props.additional_data]
  );

  return (
    <MainCard title="Imported Data">
      <Grid container spacing={matchDownMD ? 0.5 : 3}>
        {_.map(jsonData, ({ key, value }) =>
          !!key && !!value ? (
            <Grid item xs={12} key={`${key}-grid-item`}>
              <Stack spacing={0.5}>
                <Typography color="secondary">{key}</Typography>
                <Typography fontWeight={600}>{value || '--'}</Typography>
              </Stack>
            </Grid>
          ) : null
        )}
      </Grid>
    </MainCard>
  );
}
