import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

// render - login
const MSSSOSuccess = Loadable(lazy(() => import('pages/integration/ms_success_redirect')));
const GoogleSSOSuccess = Loadable(lazy(() => import('pages/integration/google_success_redirect')));
const StripeSuccess = Loadable(lazy(() => import('pages/integration/stripe_success_redirect')));

// ==============================|| AUTH ROUTING ||============================== //

const IntegrationRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      children: [
        {
          path: 'sso',
          children: [
            {
              path: 'microsoft',
              children: [{ path: 'success', element: <MSSSOSuccess /> }]
            },
            {
              path: 'google',
              children: [{ path: 'success', element: <GoogleSSOSuccess /> }]
            }
          ]
        },
        {
          path: 'stripe',
          children: [{ path: 'success', element: <StripeSuccess /> }]
        }
      ]
    }
  ]
};

export default IntegrationRoutes;
