import { Grid } from '@mui/material';
import StandardField from 'components/form/standard/StandardField';
import { ContactMetadata } from 'constants/objectMetadata/contactMetadata';
import { Fragment } from 'react';

export function ProspectDetailsSection(props: { printDisplay?: boolean }) {
  return (
    <Fragment>
      <Grid item xs={props.printDisplay ? 6 : 12} sm={6}>
        <StandardField field={ContactMetadata.prospect_types} />
      </Grid>
      <Grid item xs={props.printDisplay ? 6 : 12} sm={6}>
        <StandardField field={ContactMetadata.asset_types} />
      </Grid>
      <Grid item xs={props.printDisplay ? 6 : 8} sm={4}>
        <StandardField field={ContactMetadata.size_est} />
      </Grid>
      <Grid item xs={props.printDisplay ? 6 : 4} sm={2}>
        <StandardField field={ContactMetadata.size_type} displayName={props.printDisplay ? 'Size Type' : ''} />
      </Grid>
      <Grid item xs={props.printDisplay ? 6 : 12} sm={6}>
        <StandardField field={ContactMetadata.submarkets} />
      </Grid>
      <Grid item xs={props.printDisplay ? 6 : 12} sm={6}>
        <StandardField field={ContactMetadata.client_industry} />
      </Grid>
      <Grid item xs={props.printDisplay ? 6 : 12} sm={6}>
        <StandardField field={ContactMetadata.source_type} />
      </Grid>
      <Grid item xs={props.printDisplay ? 6 : 12} sm={6}>
        <StandardField field={ContactMetadata.property_names} />
      </Grid>
      <Grid item xs={props.printDisplay ? 6 : 12} sm={6}>
        <StandardField field={ContactMetadata.lease_expiration} />
      </Grid>
    </Fragment>
  );
}
