// material-ui
// assets
import { DialogContent, DialogTitle, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import * as Sentry from '@sentry/react';
// material-ui
import StandardField from 'components/form/standard/StandardField';
import { StandardForm } from 'components/form/standard/StandardForm';

// project import
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import { Deal } from 'types/deal';
import { StandardFormProps } from 'types/record';
import { updateDealAsync } from 'utils/deal';
// third party

const { lost_reason, lost_date, status } = DealMetadata;
const LostModalMetadata = { lost_reason, lost_date, status };

export const DealLostForm = ({ record, incomingChanges, onCancel, successCallback, open }: StandardFormProps<Deal>) => {
  const navigate = useNavigate();

  const updateFn = async (id: number | string, deal: Partial<Deal>) => {
    try {
      const record = await updateDealAsync(id, deal, navigate);
      if (typeof successCallback === 'function') successCallback(record);
    } catch (error) {
      Sentry.captureException('Unable to update a Deal lost reason.');
    }
  };

  return (
    <StandardForm<Partial<Deal>>
      metadata={LostModalMetadata}
      record={record}
      updateFn={updateFn}
      displayName={'Notes'}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <DialogTitle>Lost Deal</DialogTitle>
          <DialogContent dividers>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} sm={6}>
                <StandardField field={DealMetadata.lost_reason} touch={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StandardField field={DealMetadata.lost_date} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};
