// third-party
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

// project imports
import { axiosLenderDealServices, axiosQuoteRequestServices, axiosLenderServices } from 'utils/axios';
import { dispatch } from '../index';

// types
import { LenderDealsStateProps, LenderDeal, LenderDealRead, QuoteRequest } from 'types/lenderDeal';

// ----------------------------------------------------------------------

const initialState: LenderDealsStateProps = {
  error: null,
  count: 0,
  lenderDeals: [],
  quoteRequests: [],
  lenderContacts: [],
  currentLenderDeal: null,
  currentQuoteRequest: null,
  loadingCurrentQuoteRequest: false,
  lenderDealInfo: []
};

const lenderDeal = createSlice({
  name: 'lenderDeal',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getLenderDealsSuccess(state, action) {
      state.lenderDeals = action.payload.lender_deals;
      state.count = action.payload.count;
    },

    getLenderDealSuccess(state, action) {
      state.currentLenderDeal = action.payload;
      state.count = action.payload.count;
    },

    getQuoteRequestSuccess(state, action) {
      state.currentQuoteRequest = action.payload;
      state.count = action.payload.count;
    },

    getQuoteRequestsSuccess(state, action) {
      state.quoteRequests = action.payload;
      state.count = action.payload.count;
    },

    getLenderContactsSuccess(state, action) {
      interface LenderContact {
        id: number;
        lender_id: number;
        lender_name: string;
        contact_name: string;
        contact_email: string;
        state: string;
        asset_type: string;
        first_name: string;
        last_name: string;
      }
      const data = action.payload.quote_contacts;

      const formattedData: LenderContact[] = [];

      for (let i = 0; i < data.length; i++) {
        for (let index = 0; index < data[i].lender_contacts.length; index++) {
          formattedData.push({
            id: data[i].lender_contacts[index].id,
            lender_id: data[i].lender_id,
            lender_name: data[i].lender_name,
            contact_name: `${data[i].lender_contacts[index].first_name} ${data[i].lender_contacts[index].last_name}`,
            contact_email: data[i].lender_contacts[index].email_address,
            state: data[i].lender_state,
            asset_type: data[i].lender_type,
            first_name: data[i].lender_contacts[index].first_name,
            last_name: data[i].lender_contacts[index].last_name
          });
        }
      }
      state.lenderContacts = formattedData;
      state.count = action.payload.count;
    },

    addLenderDealSuccess(state, action) {
      state.lenderDeals.push({
        lender_deal: action.payload,
        count_quote_requests: 0
      });
    },

    addQuoteRequestSuccess(state, action) {
      state.quoteRequests.push(action.payload);
    },

    addQuoteRequestsSuccess(state, action) {
      let dealId;
      const quoteIds = _.map(action.payload, (quoteRequest) => {
        dealId = quoteRequest.quote_request.lender_deal_id;
        return quoteRequest.quote_request.id;
      });
      const idx = _.findIndex(state.lenderDeals, ['lender_deal.id', dealId]);
      // @ts-ignore
      state.lenderDeals[idx].count_quote_requests += quoteIds.length;
      _.forEach(action.payload, (quoteRequest) => {
        state.quoteRequests.push(quoteRequest);
      });
    },

    updateLenderDealSuccess(state, action) {
      const id = _.get(action.payload, 'id');
      // @ts-ignore
      const idx = _.findIndex(state.lenderDeals, ['lender_deal.id', id]);
      state.lenderDeals[idx].lender_deal = _.merge(state.lenderDeals[idx].lender_deal, action.payload);
    },

    updateQuoteRequestSuccess(state, action) {
      const id = _.get(action.payload, 'id');
      // @ts-ignore
      const idx = _.findIndex(state.quoteRequests, ['quote_request.id', id]);
      state.quoteRequests[idx].quote_request = _.merge(state.quoteRequests[idx].quote_request, action.payload);
    },

    getQuoteContactsSuccess(state) {
      state.loadingCurrentQuoteRequest = true;
    },

    getLenderDealInfoSuccess(state, action) {
      state.lenderDealInfo = action.payload;
    }
  }
});

export default lenderDeal.reducer;

// ----------------------------------------------------------------------

export function getLenderDeals(query_params: {}, pagination: {}) {
  axiosLenderDealServices
    .get(`/`, { params: { ...query_params, ...pagination } })
    .then((response) => {
      dispatch(lenderDeal.actions.getLenderDealsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function getLenderDeal(lenderDealId: number) {
  axiosLenderDealServices
    .get(`/${lenderDealId}`)
    .then((response) => {
      dispatch(lenderDeal.actions.getLenderDealSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function getQuoteRequest(quoteRequestId: number, recipients: boolean = false) {
  let url;
  if (recipients) {
    url = `/${quoteRequestId}?recipients=true`;
  } else {
    url = `/${quoteRequestId}`;
  }
  axiosQuoteRequestServices
    .get(url)
    .then((response) => {
      dispatch(lenderDeal.actions.getQuoteRequestSuccess(response.data));
      if (recipients) {
        dispatch(lenderDeal.actions.getQuoteContactsSuccess());
      }
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function getQuoteRequests(lenderDealId: number) {
  axiosQuoteRequestServices
    .get(`/?lender_deal_id=${lenderDealId}`)
    .then((response) => {
      dispatch(lenderDeal.actions.getQuoteRequestsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function getLenderContacts(params: object = {}, pagination: object = {}) {
  axiosLenderServices
    .get(`/`, { params: { ...params, ...pagination, quote_contacts: true } })
    .then((response) => {
      dispatch(lenderDeal.actions.getLenderContactsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function createLenderDeal(newLenderDeal: LenderDeal, files?: any[]) {
  axiosLenderDealServices
    .post('/', newLenderDeal)
    .then((response) => {
      dispatch(lenderDeal.actions.addLenderDealSuccess(response.data));
      createQuoteRequest(newLenderDeal, response.data, files);
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function updateLenderDeal(updatedLenderDeal: any) {
  axiosLenderDealServices
    .patch(`/${updatedLenderDeal.id}`, updatedLenderDeal)
    .then((response) => {
      dispatch(lenderDeal.actions.updateLenderDealSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function createQuoteRequest(
  newLenderDeal: any,
  lenderDealCreateResponse: LenderDealRead,
  files?: any[],
  partialSteps: boolean = false
) {
  let formattedObj;
  if (partialSteps) {
    formattedObj = {
      lender_deal_id: lenderDealCreateResponse.id,
      quote_request: newLenderDeal.quote_request,
      subject_line: newLenderDeal.subject,
      quote_request_recipients: newLenderDeal.quote_request_recipients
    };
  } else {
    formattedObj = {
      lender_deal_id: lenderDealCreateResponse.id,
      quote_request: newLenderDeal.quote_request,
      subject_line: newLenderDeal.subject,
      quote_request_recipients: newLenderDeal.quote_request_recipients,
      fixed_term: newLenderDeal.term,
      loan_amount: newLenderDeal.loan_request_amount,
      loan_product_type: newLenderDeal.loan_product,
      interest_rate: newLenderDeal.rate
    };
  }

  axiosQuoteRequestServices
    .post('/quote_requests', formattedObj)
    .then((response) => {
      dispatch(lenderDeal.actions.addQuoteRequestsSuccess(response.data));
      sendQuoteRequest(
        _.map(response.data, (quoteData) => {
          return quoteData.quote_request.id;
        }),
        files
      );
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function sendQuoteRequest(quoteRequestIds: number[], files?: any[] | undefined) {
  if (files) {
    const formData = new FormData();
    _.forEach(files, (file) => {
      formData.append('files', file, file.name);
    });
    axiosQuoteRequestServices
      .post(`/send_quote_request/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          quote_request_ids: quoteRequestIds
        }
      })
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        dispatch(lenderDeal.actions.hasError(error));
      });
  } else {
    axiosQuoteRequestServices
      .post(`/send_quote_request/`, {}, { params: { quote_request_ids: quoteRequestIds } })
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        dispatch(lenderDeal.actions.hasError(error));
      });
  }
}

export function resendQuoteRequest(quoteRequestId: number) {
  axiosQuoteRequestServices
    .post(`/resend_quote_request/?quote_request_id=${quoteRequestId}`, {}, {})
    .then((response) => {
      return response.status;
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function updateQuoteRequest(updateQuoteRequest: any) {
  axiosQuoteRequestServices
    .patch(`/${updateQuoteRequest.id}`, updateQuoteRequest)
    .then((response) => {
      dispatch(lenderDeal.actions.updateQuoteRequestSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function createQuote(newQuote: QuoteRequest, lenderQuoteDeal: any) {
  axiosQuoteRequestServices
    .post('/', _.assignIn(newQuote, { lender_deal_id: lenderQuoteDeal.id }))
    .then((response) => {
      dispatch(lenderDeal.actions.addQuoteRequestSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}

export function getLenderDealInfo() {
  axiosLenderDealServices
    .get('/lender_deal_info')
    .then((response) => {
      dispatch(lenderDeal.actions.getLenderDealInfoSuccess(response.data));
    })
    .catch((error) => {
      dispatch(lenderDeal.actions.hasError(error));
    });
}
