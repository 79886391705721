// types
import { DefaultConfigProps } from 'types/config';

export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

// ==============================|| THEME CONFIG  ||============================== //

const presetColor = 'onesourceNew';

const config: DefaultConfigProps = {
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  defaultPath: '/deals/overview',
  fontFamily: `'Inter', sans-serif`,
  i18n: 'en',
  dateFormat: 'YYYY-MM-DD',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: presetColor,
  themeDirection: 'ltr',
  theme: { tileSpacing: 3, themeDirection: 'ltr', presetColor: presetColor, mode: 'light' },
  google_adwords: {
    client_id: import.meta.env.VITE_GOOGLE_ADWORD_CLIENT_ID,
    tags: {
      lend_db: import.meta.env.VITE_GOOGLE_ADWORD_TAG_LEND_DB,
      deal_view: import.meta.env.VITE_GOOGLE_ADWORD_TAG_DEAL_VIEW
    }
  },
  stripe: {
    // @ts-ignore
    products: {
      lend_db: JSON.parse(import.meta.env.VITE_STRIPE_PRODUCT_LEND_DB),
      deal_view: JSON.parse(import.meta.env.VITE_STRIPE_PRODUCT_DEAL_VIEW),
      deal_view_pro: JSON.parse(import.meta.env.VITE_STRIPE_PRODUCT_DEAL_VIEW_PRO),
      prospects: JSON.parse(import.meta.env.VITE_STRIPE_PRODUCT_PROSPECTS)
    },
    stripe_key: import.meta.env.VITE_STRIPE_KEY,
    defaultTrialPrice: {
      deal_view: import.meta.env.VITE_STRIPE_DEAL_VIEW_TRIAL_PRICE
    }
  },
  lenderNotifications: import.meta.env.VITE_LENDER_NOTIFICATIONS === 'true',
  devLicenseToggle: import.meta.env.VITE_DEV_LICENSE_TOGGLE === 'true',
  devLicenseUserId: import.meta.env.VITE_DEV_LICENSE_USER_ID,
  google_maps: {
    api_key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
  },
  envName: import.meta.env.VITE_ENV_NAME,
  csvBoxConfig: {
    newDealImportLicenseKey: import.meta.env.VITE_NEW_DEAL_IMPORT_LICENSE_KEY,
    newContactImportLicenseKey: import.meta.env.VITE_NEW_CONTACT_IMPORT_LICENSE_KEY,
    newCompanyImportLicenseKey: import.meta.env.VITE_NEW_COMPANY_IMPORT_LICENSE_KEY,
    newActivityImportLicenseKey: import.meta.env.VITE_NEW_ACTIVITY_IMPORT_LICENSE_KEY
  }
};

export default config;
