import { feature_flag } from 'feature_flag';
import _ from 'lodash';
import { createContext } from 'react';

// types
import { FeatureFlagName, FeatureFlagProps } from 'types/feature_flag';

// initial state
const initialState: FeatureFlagProps = {
  getFlag: () => null
};

const FeatureFlagContext = createContext(initialState);

const FeatureFlagProvider = ({ children }: { children: any }) => {
  const getFlag = (flagName: FeatureFlagName) => _.get(feature_flag, flagName, { value: false });

  return <FeatureFlagContext.Provider value={{ getFlag }}>{children}</FeatureFlagContext.Provider>;
};

export { FeatureFlagProvider, FeatureFlagContext };
