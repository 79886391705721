import { SelectOption } from 'components/form/SelectOption';
import { TextFieldString } from 'components/form/TextFieldString';
import _ from 'lodash';
import GoogleMapsAutocomplete from 'pages/deal/components/GoogleMapsAutocomplete';
import { getUSStateOptions } from 'pages/deal/constants/address';
import { ObjectMetadata } from 'types/standardForm';
import * as Yup from 'yup';

export const CompanyMetadata: ObjectMetadata = {
  _address_search: {
    fieldName: '_address_search',
    displayName: 'Address',
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: GoogleMapsAutocomplete,
    virtual: true,
    additionalChangeHandler: (value, setFieldValue) => {
      if (_.get(value, 'city')) {
        setFieldValue('line_1', _.get(value, 'line_1'));
        setFieldValue('line_2', _.get(value, 'line_2'));
        setFieldValue('city', _.get(value, 'city'));
        setFieldValue('state', _.get(value, 'state'));
        setFieldValue('postal_code', _.get(value, 'postal_code'));
        setFieldValue('country', _.get(value, 'country'));
        setFieldValue('latitude', _.get(value, 'latitude'));
        setFieldValue('longitude', _.get(value, 'longitude'));
      }
    }
  },
  name: {
    fieldName: 'name',
    displayName: 'Company Name',
    initialValue: '',
    _schema: Yup.string().max(255, 'Company Name must be less than 256 characters.'),
    component: TextFieldString
  },
  city: {
    fieldName: 'city',
    displayName: 'City',
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString
  },
  country: {
    fieldName: 'country',
    displayName: 'Country',
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString
  },
  latitude: {
    fieldName: 'latitude',
    displayName: 'Latitude',
    visibility: () => false,
    initialValue: null,
    _schema: Yup.number().nullable(),
    component: TextFieldString
  },
  line_1: {
    fieldName: 'line_1',
    displayName: 'Line 1',
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString
  },
  line_2: {
    fieldName: 'line_2',
    displayName: 'Line 2',
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString
  },
  longitude: {
    fieldName: 'longitude',
    displayName: 'Longitude',
    visibility: () => false,
    initialValue: null,
    _schema: Yup.number().nullable(),
    component: TextFieldString
  },
  postal_code: {
    fieldName: 'postal_code',
    displayName: 'Zip',
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString
  },
  state: {
    fieldName: 'state',
    displayName: 'State',
    initialValue: null,
    _schema: Yup.string().max(2).nullable(),
    component: SelectOption,
    options: getUSStateOptions()
  }
};
