import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableIdentifier } from 'types/tableView';
import { dispatch } from '../index';

interface TableViewState {
  viewing: boolean;
  filter: object;
  tableIdentifier: TableIdentifier;
  columns: string[];
}

const initialState: TableViewState = {
  viewing: false,
  filter: {},
  tableIdentifier: TableIdentifier.ActivityCompletedTasks,
  columns: []
};

const slice = createSlice({
  name: 'tableView',
  initialState,
  reducers: {
    // Start Viewing
    startViewing: {
      reducer(state: TableViewState, action: PayloadAction<object, string, { tableIdentifier: TableIdentifier; columns: string[] }>) {
        state.viewing = true;
        state.filter = action.payload;
        state.tableIdentifier = action.meta.tableIdentifier;
        state.columns = action.meta.columns;
      },
      prepare: (filter: object, tableIdentifier: TableIdentifier, columns: string[]) => {
        return { payload: filter, meta: { tableIdentifier, columns } };
      }
    },
    // Stop Viewing
    stopViewing(state: TableViewState) {
      state.viewing = false;
      // state.filter = null;
      // state.tableIdentifier = null;
      // state.columns = null;
    }
  }
});

// Reducer
export default slice.reducer;

export function startViewingRecords(filter: object, tableIdentifier: TableIdentifier, columns?: string[]) {
  dispatch(slice.actions.startViewing(filter, tableIdentifier, columns ?? []));
}

export function stopViewingRecords() {
  dispatch(slice.actions.stopViewing());
}
