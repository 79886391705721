import { CloseOutlined } from '@ant-design/icons';
import { UpdateOutlined } from '@mui/icons-material';
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { CreoneField } from 'components/form/basic/creone_field';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { FieldComponentProps } from 'types/standardForm';

export function FollowupToggleButton(props: FieldComponentProps) {
  const { setFieldValue, values } = useFormikContext();

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: boolean) => {
    // Prevent the toggle group from having no value selected
    if (newAlignment !== null) {
      setFieldValue(props.fieldName, newAlignment);
    }
  };

  return (
    <CreoneField {...props}>
      <ToggleButtonGroup
        exclusive
        aria-label="text alignment"
        value={_.get(values, props.fieldName)} // Get the current value from Formik's state
        onChange={handleAlignment} // Use a custom handler for onChange
      >
        <ToggleButton value={true} aria-label="first">
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <UpdateOutlined />
            <Typography>Follow Up</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={false} aria-label="second">
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <CloseOutlined />
            <Typography>No Follow Up</Typography>
          </Stack>
        </ToggleButton>
      </ToggleButtonGroup>
    </CreoneField>
  );
}
