import { CommissionRead, DealExtended, DealStatus, DealType } from 'types/deal';

// A data model for returning a list of deals
export type DealOverview = Partial<DealExtended>;

// A data model getting deal table data, grouped by stage_id
export interface StageColumn {
  sum_commission_est: number;
  sum_commission_effective: number;
  items: Partial<DealExtended>[];
}

export type DealOverviewByStage = {
  [stage_id: number]: StageColumn;
};

interface StageBoardMetadata {
  deal_type: DealType;
}

export interface StageBoard {
  meta: StageBoardMetadata;
  stages: DealOverviewByStage;
}

export interface StageFunnelData {
  id: number;
  value: number;
}

export interface CommissionChartData {
  keys: [number, number][];
  closed_commission_unpaid: number[];
  closed_commission_paid: number[];
  open_commission: number[];
  effective_commission: number[];
  agg_commission: number[];
}

export interface CommissionComparisonChartData {
  current_year: number[];
  last_year: number[];
  best_year: number[];
  previous_3_years_average: number[];
  keys: string[];
}

export interface CommissionDueDateData {
  paid: any[];
  unpaid: any[];
  unpaid_before: any[];
}

export interface CommissionInsightsWidgetsData {
  // Sum of commission for closed deals
  closed_commission_unpaid: number;
  // Count of closed deals
  closed_count_unpaid: number;
  // Sum of commission for closed deals
  closed_commission_paid: number;
  // Count of closed deals
  closed_count_paid: number;
  // Sum of estimated commission for open deals
  open_commission: number;
  // Count of open deals
  open_count: number;
  // Sum of estimated and closed commission
  effective_commission: number;
  // Count of open and closed deals
  effective_count: number;
}

export interface PipelineOverviewWidgetsData {
  weighted_commission: number;
  weighted_transaction_value: number;
  total_count: number;
  total_commission: number;
  total_transaction_value: number;
}

// A data model for getting Deal Overview data for the Overview List table
export type DealStats = {
  avg_commission: number;
  sum_commission: number;

  avg_commission_split: number;
  sum_commission_split: number;

  avg_transaction_value: number;
  sum_transaction_value: number;

  size_dict: Record<string, number>;
};

export type DealOverviewList = {
  stats: DealStats;
  data: DealExtended[];
};
export const defaultDealsOverviewData = {
  stats: {
    avg_commission: 0,
    sum_commission: 0,
    avg_commission_split: 0,
    sum_commission_split: 0,
    avg_transaction_value: 0,
    sum_transaction_value: 0,
    size_dict: {},
    total_count: 0,
    page_count: 0
  },
  data: []
};

export interface OutstandingPaymentRow {
  deal_id: number | null;
  deal_name?: string | null;
  commissions?: CommissionRead[] | null;
  due_date?: Date | null;
  amount?: number | null;
  is_paid?: boolean;
}

export interface CloseDatePastRow {
  deal_id: number | null;
  deal_name?: string | null;
  commissions?: CommissionRead[] | null;
  close_date_est?: Date | null;
  status: DealStatus;
}

export interface CategoryAggregateData {
  key: number | string;
  value: string;
  count: number;
  commission: number;
  transaction_value: number;
}

export interface CategoryAggregateResponse {
  all_data: CategoryAggregateData[];
  remainder?: CategoryAggregateData | null;
}
