// project import
import prospects from 'menu-items/prospects';
import { NavItemType } from 'types/menu';
import deal_view from './deal_view';
import lend_db from './lend_db';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [prospects, deal_view, lend_db]
};

export default menuItems;
