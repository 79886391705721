// material-ui
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { FormHelperText, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { DEAL_TOOLTIP } from 'pages/deal/constants/tooltip';
import { isDealViewPro } from 'pages/deal/utils/deal_form';
import React from 'react';
import { AdditionalChangeHandlerType } from 'types/standardForm';
import { openDealViewProDialog } from '../utils/deal';
import { LightTooltip } from './InfoTooltip';

// ==============================|| TOGGLE BUTTON - VARIANT ||============================== //

export default function ToggleButtonHorizontal(props: {
  fieldName: string;
  options: Array<{ key: string | number; label: string | null; icon?: typeof LockOutlinedIcon; pro?: boolean }>;
  readOnly?: boolean;
  fullWidth?: boolean;
  additionalChangeHandler?: AdditionalChangeHandlerType;
}) {
  const { fieldName, options, readOnly, additionalChangeHandler, fullWidth } = props;
  const { touched, errors, setFieldValue, values } = useFormikContext();
  const value = _.get(values, fieldName);

  return (
    <>
      <ToggleButtonGroup
        fullWidth={fullWidth}
        value={value}
        color="primary"
        exclusive
        orientation="horizontal"
        onChange={(event, newAlignment: string | null) => {
          if (typeof newAlignment === 'string') {
            if (typeof additionalChangeHandler === 'function') {
              additionalChangeHandler(newAlignment, setFieldValue);
            }

            setFieldValue(fieldName, newAlignment);
          }
        }}
      >
        {_.map(options, ({ key, label, icon: Icon, pro }, index) => {
          const lockedFeature = pro && !isDealViewPro();
          const alignmentValue = lockedFeature ? {} : key;
          return (
            (!readOnly || key === value) && (
              <ToggleButton value={alignmentValue} aria-label={`${label}`} key={index} disabled={Boolean(readOnly && key !== value)}>
                {lockedFeature && (
                  <LightTooltip title={DEAL_TOOLTIP.dealViewProFeature} onClick={openDealViewProDialog}>
                    <Stack direction="row" width="100%" justifyContent="flex-start" sx={{ textAlign: 'center' }}>
                      <LockOutlinedIcon />
                      {'\u00A0'}
                      {label}
                    </Stack>
                  </LightTooltip>
                )}
                {!lockedFeature && (
                  <Stack direction="row" width="100%" justifyContent="center">
                    {Icon && <Icon />}
                    {'\u00A0'}
                    {label}
                  </Stack>
                )}
              </ToggleButton>
            )
          );
        })}
      </ToggleButtonGroup>
      {_.get(touched, fieldName) && _.get(errors, fieldName) && (
        <FormHelperText error id="standard-weight-helper-text" sx={{ pl: 1.75 }}>
          {_.get(errors, fieldName)}
        </FormHelperText>
      )}
    </>
  );
}
