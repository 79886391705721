import { Download, PriorityHigh, UploadFile } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Alert, AlertTitle, Box, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import Avatar from 'components/@extended/Avatar';
import { TextFieldString } from 'components/form/TextFieldString';
import LogoIcon from 'components/logo/LogoIcon';
import ScrollTop from 'components/ScrollTop';
import config from 'config';
import { ContactMetadata } from 'constants/objectMetadata/contactMetadata';
import { useUsersDisplay } from 'contexts/UserDisplayContext';
import { FieldArray, Form, Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import _ from 'lodash';
import { getNewContactImportColumns } from 'pages/data_import/constants/newContactColumns';
import { WrappedCSVBoxButton } from 'pages/data_import/UploadCSV';
import React, { useMemo, useState } from 'react';
import { dispatch, RootState, useSelector } from 'store';
import { openErrorNotification } from 'store/reducers/common';
import { setContactQuickAddData } from 'store/reducers/onboardingData';
import { SimpleTeamMember } from 'types/auth';
import { ContactQuickAddRecord, OnboardingStepProps } from 'types/onboarding';
import { dealService } from 'utils/axios';
import * as Yup from 'yup';
import { StepActions } from '../components/StepActions';

export const emptyContactQuickAddRow: ContactQuickAddRecord = {
  first_name: null,
  last_name: null,
  telephone1: null,
  is_prospect: true
};

export const ContactQuickAddMetadata = {
  first_name: ContactMetadata.first_name,
  last_name: ContactMetadata.last_name,
  telephone1: ContactMetadata.telephone1,
  is_prospect: ContactMetadata.is_prospect
};

export const ContactImportStep = ({ handleNext, handleBack, setErrorIndex }: OnboardingStepProps) => {
  const { contactQuickAddData } = useSelector((state: RootState) => state.onboardingData);

  // Define form schema
  const validationSchema = useMemo(
    () =>
      Yup.object({
        teamMembers: Yup.array().of(
          Yup.object().shape(
            {
              first_name: Yup.string()
                .max(255)
                .when(['last_name', 'username'], {
                  is: (last_name: string, username: string) => last_name || username,
                  then: (schema) => schema.required('First Name is required.'),
                  otherwise: (schema) => schema
                }),
              last_name: Yup.string()
                .max(255)
                .when(['first_name', 'username'], {
                  is: (first_name: string, username: string) => first_name || username,
                  then: (schema) => schema.required('Last Name is required.'),
                  otherwise: (schema) => schema
                }),
              telephone1: Yup.string()
                .max(255)
                .when(['first_name', 'last_name'], {
                  is: (first_name: string, last_name: string) => first_name || last_name,
                  then: (schema) => schema.required('Phone is required.'), // Prevent the user from adding the main account email address as a team member email address
                  otherwise: (schema) => schema
                })
            },
            [
              ['first_name', 'last_name'],
              ['first_name', 'telephone1'],
              ['last_name', 'telephone1']
            ]
          )
        )
      }),
    []
  );

  const saveProgressAndHandleBack = (values: { contactData: ContactQuickAddRecord[] }, isValid: boolean) => {
    // If form is currently valid, save progress when they click Back
    if (isValid) setContactQuickAddData(values.contactData);
    // Navigate backwards
    handleBack();
  };

  const { user: clientUser } = useAuth();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [importResult, setImportResult] = useState<string | null>(null);

  // Get org members data
  const usersDisplay = useUsersDisplay();
  const brokerValues = _.map(usersDisplay, (label, key) => ({ value: `${key}`, display_label: label }));
  const newContactImportColumns = useMemo(
    () => getNewContactImportColumns(brokerValues, clientUser as unknown as SimpleTeamMember),
    [brokerValues, clientUser]
  );

  const handleImport = (result: boolean, data: { row_success: string }) => {
    setImportResult(null);

    if (result) {
      dealService
        .post(`/data_management/import/contact/`, data)
        .then(() => {
          setImportResult('success');
        })
        .catch((error) => {
          if (error.response && error.response.status === 502) {
            setImportResult('timeout');
          } else {
            setImportResult('error');
          }
          Sentry.captureMessage(`${`An error occurred.`} Error: ${error}`);
        })
        .finally(() => setButtonLoading(false));
    } else {
      dispatch(openErrorNotification(`An error occurred.`));
    }
  };

  return (
    <ScrollTop>
      <LogoIcon />
      <Typography variant="h2">
        Add your{' '}
        <Typography variant="h2" component={'span'} color={'green'}>
          Prospects
        </Typography>
      </Typography>
      <Typography variant="caption" sx={{ pt: 1, opacity: 0.5, fontSize: '14px', fontWeight: 500 }}>
        Some brokers want to use OneSource as their source of truth for all Contacts. Others want to use it to simply stay on top of and
        follow up with their top Prospects.
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid item xs={12}>
          <Typography variant={'h5'} sx={{ fontWeight: 500, margin: '16px 0' }}>
            Enter contacts manually
          </Typography>
        </Grid>
        <Formik
          initialValues={{ contactData: contactQuickAddData }}
          onSubmit={async (values, { setFieldError, setSubmitting }) => {
            setSubmitting(true);
            setContactQuickAddData(values.contactData);
            setSubmitting(false);
            handleNext();
          }}
          validationSchema={validationSchema}
        >
          {({ values, handleBlur, handleChange, touched, errors, isValid, isSubmitting, handleSubmit }) => (
            <Form>
              <FieldArray name="contactData">
                {({ push, remove }) => (
                  <Grid container spacing={3}>
                    {values.contactData.length > 0 &&
                      values.contactData.map((contactQuickAddRecord, index) => {
                        return (
                          <Grid
                            container
                            item
                            alignItems="flex-start"
                            spacing={1}
                            key={index}
                            sx={{ mb: { xs: 3, sm: 0 }, pb: { xs: 7, sm: 0 }, borderBottom: { xs: '1px solid #e5e5e5', sm: 'none' } }}
                          >
                            <Grid item xs={11}>
                              <Grid container columnSpacing={4} rowSpacing={4}>
                                <Grid item xs={12} sm={4}>
                                  <TextFieldString
                                    fieldName={`contactData.${index}.first_name`}
                                    displayName={ContactMetadata.first_name.displayName}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <TextFieldString
                                    fieldName={`contactData.${index}.last_name`}
                                    displayName={ContactMetadata.last_name.displayName}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <TextFieldString
                                    fieldName={`contactData.${index}.telephone1`}
                                    displayName={ContactMetadata.telephone1.displayName}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <Stack direction={'row'} sx={{ mt: 3.4 }}>
                                {values.contactData.length > 1 && (
                                  <IconButton onClick={() => remove(index)}>
                                    <RemoveCircleIcon color="primary" />
                                  </IconButton>
                                )}
                                {index + 1 === values.contactData.length && (
                                  <IconButton
                                    onClick={() => {
                                      push(emptyContactQuickAddRow);
                                    }}
                                  >
                                    <AddCircleIcon color="primary" />
                                  </IconButton>
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        );
                      })}
                    <Grid item xs={12} sx={{ margin: '16px 0' }}>
                      <Divider>
                        <Typography variant="caption" sx={{ margin: '0 8px', opacity: 0.5, fontWeight: 500 }}>
                          OR
                        </Typography>{' '}
                      </Divider>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={'h5'} sx={{ fontWeight: 500 }}>
                        Upload contacts as a CSV
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} justifyContent={'stretch'} columnSpacing={4}>
                      <Grid item xs={12} sm={6}>
                        <Alert
                          id={'id'}
                          severity={'secondary'}
                          variant="border"
                          icon={
                            <Avatar color="info" type="filled" size="xs">
                              <PriorityHigh fontSize="small" />
                            </Avatar>
                          }
                          sx={{ width: 1, p: 4 }}
                        >
                          <AlertTitle sx={{ fontWeight: 600, pt: 0.6 }}>{'Preparing your import'}</AlertTitle>
                          <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
                            <li>Download the template below and match your data to the columns</li>
                            <li>
                              Maximum of 500 rows supported.{' '}
                              <Link href={`mailto:support@creonesource.com?subject=${'Help with Bulk Uploading Contacts'}`} target="_blank">
                                Contact support
                              </Link>{' '}
                              to get our help with importing more.
                            </li>
                          </ul>
                          <Link
                            sx={{
                              textDecoration: 'none',
                              '&:hover': { textDecoration: 'none' },
                              '&:active': { textDecoration: 'none' },
                              cursor: 'pointer'
                            }}
                            href={
                              'https://docs.google.com/spreadsheets/d/1_qCz95ihKLpmw_83z0iL1QKlk26BgBNx/edit?usp=sharing&ouid=106126181674351527678&rtpof=true&sd=true'
                            }
                            target="_blank"
                          >
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                              <Download />
                              <Typography>Download template</Typography>
                            </Stack>
                          </Link>
                        </Alert>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Alert
                          id={'id'}
                          severity={'secondary'}
                          variant="outlined"
                          sx={{
                            width: 1,
                            height: 1,
                            mt: { xs: 3, sm: 0 },
                            borderStyle: `dashed`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Stack spacing={1} alignItems={'center'}>
                            <UploadFile color="disabled" fontSize="large" />
                            <AlertTitle sx={{ fontWeight: 600, pt: 0.6, pb: 1.5 }}>{'Upload your CSV'}</AlertTitle>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <WrappedCSVBoxButton
                                key={`${clientUser.username}_contacts`}
                                licenseKey={config.csvBoxConfig.newContactImportLicenseKey}
                                username={clientUser.username}
                                clientUser={null}
                                onImport={handleImport}
                                dynamicColumns={newContactImportColumns}
                                buttonLoading={buttonLoading}
                                setButtonLoading={setButtonLoading}
                                importDisabled={false}
                                buttonText={'Upload'}
                              />
                            </Box>
                            <Box>
                              {importResult === 'success' && (
                                <Alert color={'primary'} severity={'success'}>{`Contacts imported successfully`}</Alert>
                              )}
                              {importResult === 'timeout' && (
                                <Alert color={'warning'} severity={'info'}>
                                  A timeout occurred.
                                </Alert>
                              )}
                              {importResult === 'error' && <Alert color={'error'} severity={'error'}>{`An error occurred.`}</Alert>}
                            </Box>
                          </Stack>
                        </Alert>
                      </Grid>
                    </Grid>
                    <StepActions
                      handleBack={() => saveProgressAndHandleBack(values, isValid)}
                      handleNext={handleSubmit}
                      loading={isSubmitting}
                    />
                  </Grid>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </LocalizationProvider>
    </ScrollTop>
  );
};
