import { useDealOrgPreferences } from 'contexts/DealOrgPreferencesContext';
import { useYupDescription } from 'contexts/YupDescriptionContext';
import { FormikValues, useFormikContext } from 'formik';
import { useCheckLicense } from 'hooks/useCheckLicense';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Product } from 'types/license';
import { FieldMetadata } from 'types/standardForm';

interface StandardFieldProps {
  field: FieldMetadata;
  showLabel?: boolean;
  displayName?: string;

  [key: string]: any; // This allows for any additional properties
}

const StandardField = ({ field, showLabel = true, displayName, ...rest }: StandardFieldProps) => {
  const {
    fieldName,
    displayName: metadataDisplayName,
    options,
    visibility = () => true,
    disabled = () => false,
    component: FieldComponent,
    componentProps,
    additionalChangeHandler,
    products = [Product.prospects, Product.deal_view_pro, Product.deal_view, Product.lend_db],
    formatForDisplay
  } = field;
  const { values } = useFormikContext();
  const { data: dealOrgPreferences } = useDealOrgPreferences();
  const yupDescription = useYupDescription();
  const isSubscribedToProduct = useCheckLicense(products);

  // Get live-calculated field requirement from hydrated Yup schema
  const required = useMemo(() => !_.get(yupDescription, ['fields', fieldName, 'optional'], true), [yupDescription, fieldName]);
  const isVisible = useMemo(() => visibility(values as FormikValues, dealOrgPreferences), [values, visibility, dealOrgPreferences]);
  const isDisabled = useMemo(() => disabled(values as FormikValues), [values, disabled]);

  if (!isVisible || !isSubscribedToProduct) {
    return null;
  }

  return (
    <FieldComponent
      fieldName={fieldName}
      displayName={displayName ?? metadataDisplayName}
      required={required}
      options={options}
      disabled={isDisabled}
      additionalChangeHandler={additionalChangeHandler}
      componentProps={componentProps}
      key={fieldName}
      showLabel={showLabel}
      formatForDisplay={formatForDisplay}
      {...rest}
    />
  );
};

export default StandardField;
