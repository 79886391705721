import Loadable from 'components/Loadable';
import { DealOrgPreferencesProvider } from 'contexts/DealOrgPreferencesContext';
import { DealUserPreferencesProvider } from 'contexts/DealUserPreferencesContext';
import { UserDisplayProvider } from 'contexts/UserDisplayContext';

// project import
import MainLayout from 'layout/MainLayout';
import { DealViewFormPage } from 'pages/deal/sections/DealFormPage';

import { lazy, ReactNode } from 'react';
import { Navigate } from 'react-router';
import { BaseRoute } from 'types/license';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SubscriptionGuard from 'utils/route-guard/SubscriptionGuard';

// render - sample page
const LenderPage = Loadable(lazy(() => import('pages/lender-pages/lender-page')));
const QuotesPage = Loadable(lazy(() => import('pages/quotes-pages/quotes-page')));
const LenderReportsPage = Loadable(lazy(() => import('pages/lender-pages/reports-page')));
const DealTabs = Loadable(lazy(() => import('pages/deal/DealTabs')));
const OnboardingFlow = Loadable(lazy(() => import('pages/onboarding/OnboardingFlow')));

const DealDashboardOverview = Loadable(lazy(() => import('pages/deal/tabs/Dashboard')));
const DealTabCommissionInsights = Loadable(lazy(() => import('pages/deal/tabs/CommissionInsights')));
const DealTabBrokerInsights = Loadable(lazy(() => import('pages/deal/tabs/BrokerInsights')));
const DealTabBusinessInsights = Loadable(lazy(() => import('pages/deal/tabs/BusinessInsights')));
const DealTabComparisons = Loadable(lazy(() => import('pages/deal/tabs/Comparisons')));

// Deal Overview
const DealOverviewTabs = Loadable(lazy(() => import('pages/deal/tabs/Overview')));
const DealOverviewList = Loadable(lazy(() => import('pages/deal/sections/OverviewList')));
const DealOverviewBoard = Loadable(lazy(() => import('pages/deal/sections/OverviewBoard')));

const UploadCSV = Loadable(lazy(() => import('pages/data_import/UploadCSV')));

// Prospects
const ProspectsTabs = Loadable(lazy(() => import('pages/prospects/ProspectsTabs')));
const ProspectsDashboard = Loadable(lazy(() => import('pages/prospects/ProspectsDashboard')));
const ContactsOverview = Loadable(lazy(() => import('pages/prospects/contacts/ContactsOverview')));
const ProspectsOverview = Loadable(lazy(() => import('pages/prospects/prospects/ProspectsOverview')));
const ProspectInsights = Loadable(lazy(() => import('pages/prospects/prospects/ProspectInsights')));

// render - account settings
const AccountSettings = Loadable(lazy(() => import('pages/settings/account/account')));
const AccountTabBilling = Loadable(lazy(() => import('pages/settings/account/sections/TabBilling')));
const AccountStripePricing = Loadable(lazy(() => import('pages/settings/account/sections/TabPricing')));
// render - team settings
const TeamSettings = Loadable(lazy(() => import('pages/settings/team/team')));
const TeamTabRole = Loadable(lazy(() => import('pages/settings/team/sections/TabRole')));
const TabTeamView = Loadable(lazy(() => import('pages/settings/team/sections/TabTeamsView')));
const TabStagesView = Loadable(lazy(() => import('pages/settings/team/sections/TabStagesView')));
const TabDataManagement = Loadable(lazy(() => import('pages/settings/team/sections/TabDataManagement')));
const TabFeatures = Loadable(lazy(() => import('pages/settings/team/sections/TabFeatures')));
const TabAppCustomization = Loadable(lazy(() => import('pages/settings/team/sections/TabAppCustomization')));
const TabDealFields = Loadable(lazy(() => import('pages/settings/team/sections/TabDealFields')));
const TeamTabUsers = Loadable(lazy(() => import('pages/settings/team/sections/TabUsers')));
// render - user settings
const UserSettings = Loadable(lazy(() => import('pages/settings/user/user')));
const UserTabProfile = Loadable(lazy(() => import('pages/settings/user/sections/TabProfile')));
const UserTabPassword = Loadable(lazy(() => import('pages/settings/user/sections/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('pages/settings/user/sections/TabSettings')));
const UserTabIntegrations = Loadable(lazy(() => import('pages/settings/user/sections/TabIntegrations')));

// ==============================|| MAIN ROUTING ||============================== //
const AuthGuardWithPreferences = ({ children }: { children: ReactNode }) => (
  <AuthGuard>
    <DealOrgPreferencesProvider>
      <DealUserPreferencesProvider>
        <UserDisplayProvider>{children}</UserDisplayProvider>
      </DealUserPreferencesProvider>
    </DealOrgPreferencesProvider>
  </AuthGuard>
);

const MainRoutes = {
  path: '/',
  children: [
    {
      path: 'setup',
      element: (
        <AuthGuardWithPreferences>
          <OnboardingFlow />
        </AuthGuardWithPreferences>
      )
    },
    {
      path: '/',
      element: (
        <AuthGuardWithPreferences>
          <MainLayout />
        </AuthGuardWithPreferences>
      ),
      children: [
        {
          path: 'dashboard',
          element: (
            <SubscriptionGuard>
              <DealDashboardOverview />
            </SubscriptionGuard>
          )
        },
        {
          path: 'upload_csv',
          element: <UploadCSV />
        },
        { path: 'deals/:id?', element: <DealViewFormPage /> },
        {
          path: BaseRoute.deals,
          element: (
            <SubscriptionGuard>
              <DealTabs />
            </SubscriptionGuard>
          ),
          children: [
            {
              path: 'overview',
              element: <DealOverviewTabs />,
              children: [
                {
                  path: 'list',
                  element: <DealOverviewList />
                },
                { path: 'board', element: <DealOverviewBoard /> }
              ]
            },
            {
              path: 'insights',
              children: [
                {
                  path: 'commission',
                  element: <DealTabCommissionInsights />
                },
                {
                  path: 'business',
                  element: <DealTabBusinessInsights />
                },
                {
                  path: 'broker',
                  element: <DealTabBrokerInsights />
                }
              ]
            },
            {
              path: 'comparisons',
              children: [
                {
                  path: 'goals',
                  element: <DealTabComparisons />
                },
                {
                  path: 'leaderboard',
                  element: <DealTabComparisons />
                }
              ]
            }
          ]
        },
        {
          path: BaseRoute.prospects,
          element: (
            <SubscriptionGuard>
              <ProspectsTabs />
            </SubscriptionGuard>
          ),
          children: [
            {
              path: 'dashboard',
              element: <ProspectsDashboard />
            },
            {
              path: 'contacts',
              element: <ContactsOverview />
            },
            {
              path: 'overview',
              element: <ProspectsOverview />
            },
            {
              path: 'insights',
              element: <ProspectInsights />
            }
          ]
        },
        {
          path: BaseRoute.lenders,
          children: [
            {
              path: 'overview',
              element: (
                <SubscriptionGuard>
                  <LenderPage />
                </SubscriptionGuard>
              )
            },
            {
              path: 'deals',
              element: (
                <SubscriptionGuard>
                  <QuotesPage />
                </SubscriptionGuard>
              )
            },
            {
              path: 'reports',
              element: (
                <SubscriptionGuard>
                  <LenderReportsPage />
                </SubscriptionGuard>
              )
            }
          ]
        },
        {
          path: 'settings',
          children: [
            {
              path: 'account',
              element: <AccountSettings />,
              children: [
                {
                  path: 'billing',
                  element: <AccountTabBilling />
                },

                {
                  path: 'pricing',
                  element: <AccountStripePricing />
                }
              ]
            },
            {
              path: 'team',
              element: <TeamSettings />,
              children: [
                {
                  path: 'role',
                  element: <TeamTabRole />
                },
                {
                  path: 'users',
                  element: <TeamTabUsers />
                },
                {
                  path: 'team',
                  element: <TabTeamView />
                },
                {
                  path: 'customize',
                  element: <TabAppCustomization />,
                  children: [
                    {
                      index: true, // This will match the /customize path
                      element: <Navigate to="deal-features" replace />
                    },
                    {
                      path: 'deal-features',
                      element: <TabFeatures />
                    },
                    {
                      path: 'deal-fields',
                      element: <TabDealFields />
                    },

                    {
                      path: 'deal-stages',
                      element: <TabStagesView />
                    }
                  ]
                },

                {
                  path: 'data',
                  element: <TabDataManagement />
                }
              ]
            },
            {
              path: 'user',
              element: <UserSettings />,
              children: [
                {
                  path: 'profile',
                  element: <UserTabProfile />
                },
                {
                  path: 'password',
                  element: <UserTabPassword />
                },
                {
                  path: 'settings',
                  element: <UserTabSettings />
                },
                {
                  path: 'integrations',
                  element: <UserTabIntegrations />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default MainRoutes;
