import { ActivityRead } from 'types/api/deal/activity';

export enum ActivityType {
  call = 'call',
  email = 'email',
  other = 'other',
  outlookEmail = 'outlookEmail'
}
//
// export interface Activity {
//   id: number | string | null;
//   name?: string | null;
//   subject?: string | null;
//   notes_plaintext?: string | null;
//   activity_type_v2?: ActivityTypeEnum;
//   date?: Date | null;
//   completed_date?: Date | null;
//   is_complete: boolean;
//   contact?: Contact | Partial<Contact> | null;
//   contact_id?: number;
//   owner: number;
// }
//
// export interface ActivityRead extends Activity {}
//
// // Corresponds to fields found in ActivityFollowupMetadataSubset
// export interface FollowupFields {
//   is_followup?: boolean | null;
//   followup_option?: string | null;
//   followup_date?: Date | null;
//   followup_activity_type_v2?: ActivityTypeEnum | null;
//   followup_subject?: string | null;
//   lost_reason?: DealOption | null;
// }
//
// export interface ActivityWithFollowup extends Activity, FollowupFields {}
//
// // You can add additional utility types or interfaces as needed
// export interface ActivityOverview {
//   id: number;
//   created_by: number;
//   owner: number;
//   activity_type: DealOption;
//   date: Date;
//   subject?: string;
//   notes_plaintext?: string;
//   is_complete: boolean;
//   // Contact Fields
//   contact_id: number;
//   contact_fullname: string;
//   contact_title?: string;
//   contact_company_name?: string;
//   is_prospect: boolean;
//   telephone1?: string;
//   telephone2?: string;
//   telephone1_ext?: string;
//   email_address?: string;
// }

// export interface DashboardActivityList {
//   call: ActivityOverview[];
//   email: ActivityOverview[];
//   other: ActivityOverview[];
// }

export const defaultDashboardActivityList = {
  call: [],
  email: [],
  other: []
};

export interface ActivityTimelineObject {
  upcoming: ActivityRead[];
  completed: ActivityRead[];
}
