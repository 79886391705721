// material-ui
import { Theme } from '@mui/material/styles';
import { useMediaQuery, Container, Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => {
  const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Stack
        direction={matchDownSM ? 'column' : 'row'}
        justifyContent={matchDownSM ? 'center' : 'space-between'}
        spacing={2}
        textAlign={matchDownSM ? 'center' : 'inherit'}
      >
        <Typography variant="subtitle2" color="secondary" component="span">
          <Typography component={Link} variant="subtitle2" href="https://creonesource.com/team/" target="_blank" underline="hover">
            About Us
          </Typography>
        </Typography>
        <Typography
          variant="subtitle2"
          color="secondary"
          component={Link}
          href="https://creonesource.com/privacy-policy"
          target="_blank"
          underline="hover"
        >
          Privacy
        </Typography>
        <Typography
          variant="subtitle2"
          color="secondary"
          component={Link}
          href="https://creonesource.com/terms-of-use"
          target="_blank"
          underline="hover"
        >
          Terms
        </Typography>
      </Stack>
    </Container>
  );
};

export default AuthFooter;
