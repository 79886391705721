// material-ui
import { PlusOutlined } from '@ant-design/icons';
import { Alert, AlertTitle, Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { LicenseToggleButton } from 'components/dev/LicenseToggleButton';
import { handleFormNavigate } from 'components/form/standard/utils/navigation';
import useAuth from 'hooks/useAuth';
import { useCheckLicense } from 'hooks/useCheckLicense';
import { getUserFullName, showDevLicenseToggle } from 'pages/deal/utils/deal';
import { isDealViewPro } from 'pages/deal/utils/deal_form';

// react
import { useNavigate } from 'react-router-dom';

// redux
import { startEditingRecord } from 'store/reducers/record';
import { Product } from 'types/license';
import { FormIdentifier } from 'types/record';

// types
import MobileSection from './MobileSection';
import Profile from './Profile';

// project import
import Search from './Search';

// ==============================|| HEADER - CONTENT ||============================== //

function AddRecordButton(props: {}) {
  const isProspects = useCheckLicense([Product.prospects]);
  const isDVPro = isDealViewPro();
  const navigate = useNavigate();

  if (isProspects) {
    return (
      <Button
        size={'medium'}
        variant="contained"
        startIcon={<PlusOutlined />}
        onClick={() => startEditingRecord(null, FormIdentifier.QuickAddWizardForm)}
      >
        Add
      </Button>
    );
  } else if (isDVPro) {
    return (
      <Button
        size={'medium'}
        variant="contained"
        startIcon={<PlusOutlined />}
        onClick={() => {
          handleFormNavigate(null, FormIdentifier.DealViewProForm, navigate);
        }}
      >
        Add Deal
      </Button>
    );
  } else {
    return <></>;
  }
}

const HeaderContent = () => {
  const navigate = useNavigate();
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { user } = useAuth();
  const isImpersonation = user?.impersonation;

  const daysLeftInTrial = () => {
    // get the current time
    const now = new Date();
    if (user.trial_end_date) {
      const timestamp = user.trial_end_date;

      // calculate the difference between the two timestamps
      const difference = timestamp - now.getTime() / 1000;

      // convert the difference to minutes and print the result
      return Math.floor(difference / (24 * 60 * 60));
    }
  };

  return (
    <>
      {!matchesXs && (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={10} sx={{ width: '100%' }}>
          {false ? <Search /> : <Box />}
          {showDevLicenseToggle(user?.user_id) && (
            <Box justifyContent="center" alignItems="center" sx={{ mx: 1 }}>
              <LicenseToggleButton />
            </Box>
          )}
          {!!isImpersonation && (
            <Typography color={'white'} variant={'h1'}>
              {getUserFullName(user)}
            </Typography>
          )}
          {user && user.trial_end_date && user.trial_end_date >= user.expire_date && (
            <Alert
              color="warning"
              variant="outlined"
              icon={false}
              action={
                <Button
                  variant="contained"
                  color={'primary'}
                  size="small"
                  onClick={() => navigate('/settings/account/pricing', { replace: true })}
                >
                  Subscribe Now
                </Button>
              }
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <AlertTitle>
                  <Typography variant="h5">Trial Product</Typography>
                </AlertTitle>
                <Typography variant="h6">Trial ends in {daysLeftInTrial()} days</Typography>
              </Stack>
            </Alert>
          )}
          <Stack direction={'row'} spacing={2} alignItems="center">
            <AddRecordButton />
            <Profile />
          </Stack>
        </Stack>
      )}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
