import { Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import StandardField from 'components/form/standard/StandardField';
import { DealMetadata } from 'constants/objectMetadata/dealMetadata';
import { isWeightedEnabledOrgPreferences, useDealOrgPreferences } from 'contexts/DealOrgPreferencesContext';
import { EditingProvider } from 'contexts/EditingContext';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { dealTypeDisplay } from 'pages/deal/constants/deal_enums';
import { formatPercentage, formatWholeNumber } from 'pages/deal/utils/reporting';
import React, { useMemo } from 'react';
import { Deal } from 'types/api/deal/deal';
import { DealStatusEnum } from 'types/api/deal/enum';

const Chip = styled('div')({
  backgroundColor: 'rgba(0,0,0,0.02)',
  padding: '6px 12px',
  fontSize: '12px',
  fontWeight: 700,
  color: 'rgba(13,13,13,0.50)',
  border: '1px solid #EBEDEE',
  borderRadius: '8px',
  lineHeight: '16px',
  width: 'fit-content',
  textTransform: 'capitalize'
});

const Title = styled('p')({
  fontSize: 24,
  margin: 0,
  fontWeight: 700
});

const DealPreviewFromContext = () => {
  const { values } = useFormikContext<Partial<Deal>>();

  const { data: dealOrgPreferences } = useDealOrgPreferences();
  const isWeightedEnabled = useMemo(() => isWeightedEnabledOrgPreferences(dealOrgPreferences), [dealOrgPreferences]);

  const isClosed = useMemo(() => values?.status === DealStatusEnum.closed, [values]);

  return (
    <EditingProvider>
      <Grid container rowSpacing={3} columnSpacing={4}>
        <Grid item xs={12}>
          <Chip>Deal Preview</Chip>
        </Grid>
        <Grid item xs={12}>
          <Title sx={{ opacity: values?.name ? 1 : 0.5 }}>{values?.name ? values?.name : 'Deal name'}</Title>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={1.5} rowSpacing={1.5}>
            {values?.deal_type && (
              <Grid item>
                <Chip sx={{ color: '#2495F3', borderColor: '#2495F3' }}>{dealTypeDisplay[values?.deal_type]}</Chip>
              </Grid>
            )}
            <Grid item>
              <Chip
                sx={
                  (values?.status === 'open' && { color: '#32AB66', borderColor: '#32AB66' }) ||
                  (values?.status === 'lost' && { color: '#F44336', borderColor: '#F44336' }) ||
                  undefined
                }
              >
                {values?.status}
              </Chip>
            </Grid>
            {values?.status === 'open' && values?.stage && (
              <Grid item>
                <Chip sx={{ color: '#000', borderColor: '#000' }}>
                  {values?.stage?.value}
                  {isWeightedEnabled &&
                    ` (${_.isNil(values?.probability_override) ? formatPercentage(values?.stage.probability) : `${formatWholeNumber(values?.probability_override)}%`})`}
                </Chip>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isClosed ? (
          <Grid item xs={12} md={6}>
            <StandardField field={DealMetadata.close_date} />
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <StandardField field={DealMetadata.close_date_est} />
          </Grid>
        )}

        {isClosed ? (
          <Grid item xs={12} md={6}>
            <StandardField field={DealMetadata.sale_price} />
            <StandardField field={DealMetadata.total_consideration} />
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <StandardField field={DealMetadata.sale_price_est} />
            <StandardField field={DealMetadata.total_consideration_est} />
          </Grid>
        )}
        {isClosed ? (
          <Grid item xs={12} md={6}>
            <StandardField field={DealMetadata.commission} />
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <StandardField field={DealMetadata.commission_est} />
          </Grid>
        )}
        {isClosed ? (
          <Grid item xs={12} md={6}>
            <Stack direction={'row'}>
              {' '}
              <StandardField field={DealMetadata.size} />
              <StandardField field={DealMetadata.size_type} displayName={''} />
            </Stack>
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <Stack direction={'row'}>
              {' '}
              <StandardField field={DealMetadata.size_est} />
              <StandardField field={DealMetadata.size_type} displayName={''} />
            </Stack>
          </Grid>
        )}
      </Grid>
    </EditingProvider>
  );
};

export default DealPreviewFromContext;
