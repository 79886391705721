import { Box, Container, Toolbar, useMediaQuery } from '@mui/material';

// material-ui
import { useTheme } from '@mui/material/styles';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import DealPreviewDrawer from 'components/form/forms/DealPreviewDrawer';
import { PendingTeamAlertBar } from 'components/PendingTeamAlertBar';
import { checkLicense } from 'hooks/useCheckLicense';
import useConfig from 'hooks/useConfig';
import _ from 'lodash';
import navigation from 'menu-items';
import { DealTableModal } from 'modals/DealTableModal';
import { RecordEditModalStack } from 'modals/RecordEditModalStack';
import { TableViewModal } from 'modals/TableViewModal';
import ExpiredSubscriptionAlert from 'pages/extra-pages/expiredSubscriptionAlert';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { openDrawer } from 'store/reducers/menu';
import { ROUTE_PRODUCTS } from 'types/license';

// types
import { RootStateProps } from 'types/root';
import { subscriptionExpired } from 'utils/checkSubscriptionStatus';
import useAuth from '../../hooks/useAuth';
import SamplePage from '../../pages/extra-pages/sample-page';

// project import
import DrawerNew from './DrawerNew';
import Footer from './Footer';
import Header from './Header';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const [active, setActive] = useState(false);
  const { user } = useAuth();

  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  //subscription status
  const [expired, setExpired] = useState(false);

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    const base_route = window.location.pathname.split('/')[1];
    // Set main layout active based on path and license
    if (ROUTE_PRODUCTS.hasOwnProperty(base_route)) {
      const routeProducts = _.get(ROUTE_PRODUCTS, base_route, []);
      setActive(checkLicense(user, routeProducts));
    } else {
      setActive(true);
    }
  }, [active, user]);

  useEffect(() => {
    setExpired(subscriptionExpired(user));
  }, [user]);

  const DrawerComponent = DrawerNew;

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <DrawerComponent open={open} handleDrawerToggle={handleDrawerToggle} />
      <DealTableModal />
      <TableViewModal />
      <RecordEditModalStack />
      <DealPreviewDrawer />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        <PendingTeamAlertBar />
        {expired && <ExpiredSubscriptionAlert />}
        {container && (
          <Container
            maxWidth="xl"
            sx={{
              px: { xs: 0, sm: 2 },
              position: 'relative',
              minHeight: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            {active ? <Outlet /> : <SamplePage />}
            <Footer />
          </Container>
        )}
        {!container && (
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            {active ? <Outlet /> : <SamplePage />}
            <Footer />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MainLayout;
