// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// project import
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import AuthRegisterNoProduct from 'pages/auth/sections/auth-forms/AuthRegisterNoProduct';

// ================================|| LOGIN ||================================ //

const Login = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="left" alignItems="center" spacing={0.5}>
          <ErrorOutlineRoundedIcon />
          <Typography variant="h3">Link Expired</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <AuthRegisterNoProduct />
      </Grid>
    </Grid>
  );
};

export default Login;
